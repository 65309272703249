import React, { useEffect, useRef, useState } from "react";
import Configs from "../../../config";
import axios from "axios";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Button as ButtonAnt, Checkbox, Image, Upload } from "antd";
import NumberFormat from "react-number-format";
import moment from "moment";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { Input, Radio, Space } from "antd";

const ACCOUNTING_STATUS = {
  draft: "3d04de87-1ded-4968-b533-e8b2dda0c4d8", // แบบร่าง
  waiting: "a86edd8a-5b08-445c-8377-9def93ceb8c0", // รอดำเนินการ
  approve_not_upload: "99c1692b-8baa-46b3-983a-c5839a48cf84", // อนุมัติ	// อนุมัติแบบไม่ upload
  approve_uploaded: "d4329102-39f6-420a-820f-7c00f98cbcb3", // อนุมัตื	// อนุมัติแบบ upload
  not_approve: "8ab5629d-754c-4eda-8b8e-bd2920fa6167", // ไม่อนุมัติ
};
function Organization_Expenses_AEV(mode) {
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const { id } = useParams();
  const [allEmployee, setAllEmployee] = useState({ data: [], log: [] });
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [fileList3, setFileList3] = useState([]);
  const [delIMG, setDelIMG] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [isClickAtCamera, setIsClickAtCamera] = useState(false);
  const uploadRef = useRef(null);
  const uploadRef3 = useRef(null);
  const [logList, setLogList] = useState({ data: [] });
  const [logListSelect, setLogListSelect] = useState({ label: "", value: "" });
  const [statusVLastest, setStatusVLastest] = useState({ value: "" });
  const [empData, setEmpData] = useState({
    cost_type: "",
    acc_cc_is_fc: false,
    acc_cc_is_cp: false,
    acc_cc_is_cos: false,
    project_id: "",
    project_name: "",
    cost_close: "",
    cost_item_id: "",
    cost_item_name: "",
    cost_date: "",
    amount: "",
    cus_id: "",
    cus_name: "",
    cost_topic_id: "",
    cost_topic_name: "",
    cost_topic_check: "",
    cost_detail: "",
    vat: "",
    store: "",
    invoice: "",
    bill: "",
    bill_path: "",
    bill_name: "",
    ref_id: "",
    status_id: "",
    cus_id_2: "",
    cus_name_2: "",
    reserve: false,
    cus_id_reserve: "",
    cus_name_reserve: "",
    reserve_img: "",
    reserve_path: "",
    reserve_name: "",
    org_reserve_remark: "",
    org_is_personal: "",
    org_is_tax: "",
    org_is_tax_amount: "",
  });

  const [filterDataTable, setFilterDataTable] = useState({ data: null });
  const [isLog, setIsLog] = useState({ bool: false });
  const [filterCostCenter, setFilterCostCenter] = useState({
    data: [],
    log: [],
  });
  const [filterProject, setFilterProject] = useState({ data: [], log: [] });
  const [filterExpenseTopic, setFilterExpenseTopic] = useState({
    data: [],
    log: [],
  });
  const [amountNotNull, setAmountNotNull] = useState(false);
  const [detailNotNull, setDetailNotNull] = useState(false);
  const [storeNotNull, setStoreNotNull] = useState(false);
  const [invoiceNotNull, setInvoiceNotNull] = useState(false);
  const [showModalStore, setShowModalStore] = useState(false);
  const [selectedOptionStore, setSelectedOptionStore] = useState();
  const [inputValue, setInputValue] = useState("");
  const [shopName, setShopName] = useState({
    org_exp_shop_name: "",
  });
  const toggleModal = () => {
    setShowModalStore(!showModalStore);
  };
  const [dataForSave, setDataForSave] = useState({
    id: "",
    remark_not_approve: "",
  });
  const [modalState, setModalState] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showWithholdingTax, setShowWithholdingTax] = useState(false);
  const [isCheck, setIsCheck] = useState({ bool: false });
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = (state) => {
    setModalState(state);
    setShowModal(true);
  };

  const [inputValueBill, setInputValueBill] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value.replace(
      e.target.value.replace(/[\u0E31\u0E34-\u0E3A\u0E47-\u0E4E]/g, "")
        .length <= 140
    );
    if (value.length > 140) {
      setError("ข้อความต้องไม่เกิน 140 ตัวอักษร");
    } else {
      setError(""); // ล้างข้อความข้อผิดพลาดเมื่อข้อมูลถูกต้อง
    }
    setInputValueBill(value);
  };

  function cancle_add_award_point(params) {
    // console.log('filterDataTable.data :>> ', filterDataTable.data);
    if (mode.mode != "view") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `บันทึก`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ยกเลิก`,
      }).then((result) => {
        if (result.isConfirmed) {
          if (mode.isAdmin) {
            if (filterDataTable.data) {
              sessionStorage.setItem(
                "state_filter_org_expenses",
                filterDataTable.data
              );
            }
            window.location.href = "/accounting/expenses_approve";
          } else {
            let path_str = '';
            if (filterDataTable.data) {
              sessionStorage.setItem(
                "state_filter_org_expenses",
                filterDataTable.data
              );
              path_str = JSON.parse(filterDataTable.data)?.tab == 1 ? '_fe' : '';
            }
            window.location.href = `/accounting/organization_expenses${path_str}`;
          }
        }
      });
    } else {
      if (mode.isAdmin) {
        if (filterDataTable.data) {
          sessionStorage.setItem(
            "state_filter_org_expenses",
            filterDataTable.data
          );
        }
        window.location.href = "/accounting/expenses_approve";
      } else {
        let path_str = '';
        if (filterDataTable.data) {
          sessionStorage.setItem(
            "state_filter_org_expenses",
            filterDataTable.data
          );
          path_str = JSON.parse(filterDataTable.data)?.tab == 1 ? '_fe' : '';
        }
        window.location.href = `/accounting/organization_expenses${path_str}`;
      }
    }
  }

  const handleIconClick = () => {
    if (uploadRef.current) {
      setIsClickAtCamera(true);
      uploadRef.current.click();
    }
  };

  useEffect(() => {
    async function getExpShopName() {
      try {
        // เรียก API เพื่อดึงข้อมูล
        const response = await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/orgzExpenses/getExpShopName",
          headers: {
            Authorization: `${getToken()}`,
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: {
            company_id: getUser().com,
            oem_id: getOem(),
          },
        });

        if (response.status === 200) {
          const orgExpShopNames = response.data.data.map((item) => ({
            value: item.org_exp_shop_name,
            label: item.org_exp_shop_name,
          }));
          setShopName(orgExpShopNames);
        }
      } catch (error) {
        console.error("เกิดข้อผิดพลาดในการดึงข้อมูล:", error);
      }
    }

    getExpShopName();
  }, []);

  const GetEmployee = async () => {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: "",
      emp_name_th: "",
      emp_type_id: "",
      status_User: true,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response) {
        if (response.data && Array.isArray(response.data.data)) {
          const data = response.data.data.filter(
            (el) =>
              el.emp_status_type_id !==
                "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" && // ลาออก
              el.is_active !== false
          );
          setAllEmployee({ ...allEmployee, data: data });
          allEmployee.data = data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function getProjectByID(emp) {
    if (mode.mode == "add" || mode.mode == "edit") {
      // var temp = {
      //     acc_cc_is_fc: emp.acc_cc_is_fc,
      //     acc_cc_is_cp: emp.acc_cc_is_cp,
      //     acc_cc_is_cos: emp.acc_cc_is_cos,
      // };
      await axios({
        method: "post",
        url: Configs.API_URL_hrMagenatement + "/api/Project/filterProject",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: {
          company_id: getUser().com,
          oem_id: getOem(),
        },
      })
        .then(function (response) {
          if (response.data.data && Array.isArray(response.data.data)) {
            setFilterProject({ ...filterProject, data: response.data.data });
            filterProject.data = response.data.data;
          } else {
            setFilterProject({ ...filterProject, data: [] });
            filterProject.data = [];
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    }
  }

  async function getLogListByID() {
    if (mode.mode == "view") {
      let temp = { exp_id: id, company_id: getUser().com, oem_id: getOem() };
      await axios({
        method: "post",
        url:
          Configs.API_URL_hrMagenatement + "/api/ExpenseLog/getAllLogExpenses",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: temp,
      })
        .then(function (response) {
          if (response.data.data && Array.isArray(response.data.data)) {
            const log_list = response.data.data;
            const log_list_dropdown = [
              { value: id, label: "Latest", is_not_log: true },
            ];
            setLogListSelect(log_list_dropdown[0]);

            for (let index = 0; index < log_list.length; index++) {
              if (
                index == 0 &&
                (statusVLastest.value == ACCOUNTING_STATUS.waiting ||
                  statusVLastest.value == ACCOUNTING_STATUS.approve_uploaded ||
                  statusVLastest.value == ACCOUNTING_STATUS.approve_not_upload)
              ) {
                continue;
              } else {
                log_list_dropdown.push({
                  value: log_list[index].emp_exp_log_id,
                  label: log_list[index].emp_exp_log_version,
                });
              }
            }
            /* for (let item of log_list) {
              log_list_dropdown.push({
                value: item.emp_exp_log_id,
                label: item.emp_exp_log_version
              });
            } */
            setLogList({
              ...logList,
              data: log_list_dropdown,
            });
            logList.data = log_list_dropdown;
          } else {
            setLogList({ ...logList, data: [] });
            logList.data = [];
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    }
  }

  async function getCostCenterByID(emp) {
    if (mode.mode == "add" || mode.mode == "edit") {
      let temp;
      if (emp.project_id != "") {
        temp = {
          project_id: emp.project_id,
          acc_cc_is_cp: emp.acc_cc_is_cp,
          company_id: getUser().com,
          oem_id: getOem(),
        };
      } else {
        temp = {
          acc_cc_is_fc: emp.acc_cc_is_fc,
          acc_cc_is_cp: emp.acc_cc_is_cp,
          acc_cc_is_cos: emp.acc_cc_is_cos,
          company_id: getUser().com,
          oem_id: getOem(),
        };
      }
      await axios({
        method: "post",
        url:
          Configs.API_URL_hrMagenatement + "/api/CostCenter/filterCostCenter",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: temp,
      })
        .then(function (response) {
          if (
            response.data.data &&
            Array.isArray(response.data.data) &&
            (empData.acc_cc_is_cp ||
              empData.acc_cc_is_fc ||
              empData.acc_cc_is_cos)
          ) {
            setFilterCostCenter({
              ...filterCostCenter,
              data: response.data.data,
            });
            filterCostCenter.data = response.data.data;
          } else {
            setFilterCostCenter({ ...filterCostCenter, data: [] });
            filterCostCenter.data = [];
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    }
  }

  async function getExpenseTopicByID(emp) {
    if (mode.mode == "add" || mode.mode == "edit") {
      // var temp = {
      //     acc_cc_is_fc: emp.acc_cc_is_fc,
      //     acc_cc_is_cp: emp.acc_cc_is_cp,
      //     acc_cc_is_cos: emp.acc_cc_is_cos,
      // };
      await axios({
        method: "post",
        url:
          Configs.API_URL_hrMagenatement +
          "/api/ExpenseTopic/filterExpenseTopic",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: {
          company_id: getUser().com,
          oem_id: getOem(),
        },
      })
        .then(function (response) {
          if (response.data.data && Array.isArray(response.data.data)) {
            setFilterExpenseTopic({
              ...filterExpenseTopic,
              data: response.data.data,
            });
            filterExpenseTopic.data = response.data.data;
          } else {
            setFilterExpenseTopic({ ...filterExpenseTopic, data: [] });
            filterExpenseTopic.data = [];
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    }
  }

  async function getDataByID(id) {
    if (mode.mode == "view" || mode.mode == "edit") {
      var temp = {
        id: id,
      };
      await axios({
        method: "post",
        url:
          Configs.API_URL_hrMagenatement +
          "/api/orgzExpenses/filterOrgzDataByID",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: temp,
      })
        .then(function (response) {
          if (response.data.data.project.length == 0) return;
          let checkVat;
          if (mode.mode == "edit") {
            if (response.data.data.project[0].emp_exp_is_cash) {
              checkVat = "bill";
            } else if (response.data.data.project[0].emp_exp_is_vat) {
              checkVat = "vat";
              setShowWithholdingTax(checkVat);
            } else {
              checkVat = "org_is_personal";
              setShowWithholdingTax(checkVat);
            }
          } else if (mode.mode == "view") {
            if (response.data.data.project[0].emp_exp_is_cash) {
              checkVat = "bill";
              setShowWithholdingTax(false);
            } else if (response.data.data.project[0].emp_exp_is_vat) {
              checkVat = "vat";
              setShowWithholdingTax(false);
            } else {
              checkVat = "org_is_personal";
              setShowWithholdingTax(false);
            }
          }

          const tempFile = response.data.data.image.map((image) => {
            const url = `${Configs.API_URL_IMG + image.emp_file_path}`;

            return {
              uid: image.emp_upload_id,
              name: image.emp_file_name,
              img_id: image.emp_exp_id,
              status: "done",
              url: url,
              thumbUrl: url,
            };
          });

          const tempFile3 = response.data.data.image_reserve.map((image) => {
            const url = `${Configs.API_URL_IMG + image.emp_file_path}`;

            return {
              uid: image.emp_upload_id,
              name: image.emp_file_name,
              img_id: image.emp_exp_id,
              status: "done",
              url: url,
              thumbUrl: url,
            };
          });
          setFileList(tempFile);
          setFileList3(tempFile3);
          const temp_state = {
            cost_type: response.data.data.project[0].emp_exp_type || "",
            acc_cc_is_fc: response.data.data.project[0].emp_exp_is_fc || "",
            acc_cc_is_cp: response.data.data.project[0].emp_exp_is_cp || "",
            acc_cc_is_cos: response.data.data.project[0].emp_exp_is_cos || "",
            project_id: response.data.data.project[0].acc_project_id || "",
            project_name: response.data.data.project[0].acc_project_name || "",
            cost_item_id: response.data.data.project[0].acc_cc_id || "",
            cost_item_name: response.data.data.project[0].acc_cc_name || "",
            cost_date: response.data.data.project[0].emp_exp_start_date
              ? new Date(response.data.data.project[0].emp_exp_start_date)
              : null,
            amount: response.data.data.project[0].emp_exp_amount || "",
            cus_id: response.data.data.project[0].id || "",
            cus_name: response.data.data.project[0].emp_name_th || "",
            cost_topic_id: response.data.data.project[0].acc_et_id || "",
            cost_topic_name: response.data.data.project[0].acc_et_name || "",
            cost_topic_check:
              response.data.data.project[0].acc_et_is_doc_travel || "",
            cost_detail: response.data.data.project[0].emp_exp_detail || "",
            vat: checkVat || "",
            store: response.data.data.project[0].emp_exp_shop_name || "",
            invoice: response.data.data.project[0].emp_exp_inv_no || "",
            ref_id: response.data.data.project[0].org_ref_id || "",
            status_id: response.data.data.project[0].org_exp_status || "",
            cus_id_2: response.data.data.project[0].id_2 || "",
            cus_name_2: response.data.data.project[0].emp_name_th_2 || "",
            reserve: response.data.data.project[0].org_is_reserve || false,
            org_reserve_remark:
              response.data.data.project[0].org_reserve_remark || null,
            org_is_personal:
              response.data.data.project[0].org_is_personal || null,
            org_is_tax: response.data.data.project[0].emp_org_tax || null,
            org_is_tax_amount:
              response.data.data.project[0].org_tax_amount || null,
          };
          if (response.data.data.project[0].emp_org_tax) {
            check_amount_vat(
              temp_state.amount,
              temp_state.vat,
              response.data.data.project[0].emp_org_tax,
              temp_state,
              setEmpData
            );
          } else {
            setEmpData(temp_state);
          }
          setInputValue(response.data.data.project[0].emp_exp_shop_name || "");

          if (
            response.data.data.project[0].org_exp_status ===
            ACCOUNTING_STATUS.approve_uploaded
          ) {
            // image_approve
            const temp_file2 = response.data.data.image_approve.map((image) => {
              const url = `${Configs.API_URL_IMG + image.emp_file_path}`;

              return {
                uid: image.emp_upload_id,
                name: image.emp_file_name,
                img_id: image.emp_exp_id,
                status: "done",
                url: url,
                thumbUrl: url,
              };
            });
            setFileList2(temp_file2);
          }

          setStatusVLastest({
            value: response.data.data.project[0].org_exp_status || "",
          });
          statusVLastest.value =
            response.data.data.project[0].org_exp_status || "";
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    }
  }

  function SaveDataFile(is_send, filePath, filePath3) {
    if (mode.mode === "add") {
      let vat = false;
      let bill = false;
      let org_is_personal = false;
      if (empData.vat === "vat") {
        vat = true;
        bill = false;
        org_is_personal = false;
      } else if (empData.vat === "bill") {
        vat = false;
        bill = true;
        org_is_personal = false;
      } else if (empData.org_is_personal === "org_is_personal") {
        vat = false;
        bill = false;
        org_is_personal = true;
      }
      const temp = {
        emp_exp_type: empData.cost_type,
        emp_exp_start_date: empData.cost_date,
        emp_exp_amount: Number(empData.amount || 0) || null,
        emp_exp_detail: empData.cost_detail.trim(),
        vat: vat,
        bill: bill,
        org_is_personal: org_is_personal,
        emp_exp_shop_name: inputValue.trim(),
        emp_exp_inv_no: empData.invoice.trim(),
        acc_cc_is_fc: empData.acc_cc_is_fc,
        acc_cc_is_cp: empData.acc_cc_is_cp,
        acc_cc_is_cos: empData.acc_cc_is_cos,
        cost_item_id: empData.cost_item_id,
        cus_id: empData.cus_id,
        file_path: filePath,
        user_id: getUser().fup,
        project_id: empData.project_id,
        cost_topic_id: empData.cost_topic_id,
        cus_id_2: empData.cus_id_2,
        reserve: empData.reserve,
        file_path3: filePath3 || null,
        ref_id: empData.ref_id || null,
        org_reserve_remark: empData.org_reserve_remark || null,
        org_is_tax: empData.vat === "bill" ? null : empData.org_is_tax || null,
        org_is_tax_amount:
          empData.vat === "bill" ? null : empData.org_is_tax_amount || null,
        company_id: getUser().com,
        oem_id: getOem(),
      };
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then(async (result) => {
        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/orgzExpenses/insertOrgzExpenses",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(async function (response) {
            if (response.data.data !== false) {
              if (is_send) {
                await sendApproveFromOrgzToAdmin(response.data.data);
              }
              Swal.fire({
                icon: "success",
                title: is_send ? "ส่งอนุมัติสำเร็จ" : "บันทึกข้อมูลสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                let path_str = '';
                if (filterDataTable.data) {
                  sessionStorage.setItem(
                    "state_filter_org_expenses",
                    filterDataTable.data
                  );
                  path_str = JSON.parse(filterDataTable.data)?.tab == 1 ? '_fe' : '';
                }
                window.location.href = `/accounting/organization_expenses${path_str}`;
              });
            } else {
              Swal.fire("Error", "ชื่อไฟล์นี้มีอยู่ในระบบแล้ว", "error");
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire(
              "Error",
              "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
              "error"
            );
          });
      });
    }
    if (mode.mode === "edit") {
      const deleteIMG = delIMG.map((el) => {
        return { uid: el.uid, status: el.status };
      });
      let vat = false;
      let bill = false;
      let org_is_personal = false;
      if (empData.vat === "vat") {
        vat = true;
        bill = false;
        org_is_personal = false;
        //checkvattt
      } else if (empData.vat === "bill") {
        vat = false;
        bill = true;
        org_is_personal = false;
      } else {
        vat = false;
        bill = false;
        org_is_personal = true;
      }
      const temp = {
        id,
        emp_exp_type: empData.cost_type,
        emp_exp_start_date: empData.cost_date,
        emp_exp_amount: Number(empData.amount || 0) || null,
        emp_exp_detail: empData.cost_detail.trim(),
        vat: vat,
        bill: bill,
        org_is_personal: org_is_personal,
        emp_exp_shop_name: inputValue.trim(),
        emp_exp_inv_no: empData.invoice.trim(),
        acc_cc_is_fc: empData.acc_cc_is_fc || false,
        acc_cc_is_cp: empData.acc_cc_is_cp || false,
        acc_cc_is_cos: empData.acc_cc_is_cos || false,
        cost_item_id: empData.cost_item_id,
        cus_id: empData.cus_id,
        file_path: filePath || null,
        user_id: getUser().fup,
        project_id: empData.project_id,
        cost_topic_id: empData.cost_topic_id,
        remove: deleteIMG || null,
        ref_id: empData.ref_id || null,
        status_id: empData.status_id || null,
        cus_id_2: empData.cus_id_2,
        reserve: empData.reserve,
        file_path3: filePath3 || null,
        ref_id: empData.ref_id || null,
        org_reserve_remark: empData.org_reserve_remark || null,
        org_is_tax: empData.vat === "bill" ? null : empData.org_is_tax || null,
        org_is_tax_amount:
          empData.vat === "bill" ? null : empData.org_is_tax_amount || null,
        company_id: getUser().com,
        oem_id: getOem(),
      };
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then(async (result) => {
        // return
        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/orgzExpenses/editOrgzExpenses",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(async function (response) {
            if (response.data.data !== false) {
              if (is_send) {
                await sendApproveFromOrgzToAdmin(id);
              }
              Swal.fire({
                icon: "success",
                title: is_send ? "ส่งอนุมัติสำเร็จ" : "บันทึกข้อมูลสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                let path_str = '';
                if (filterDataTable.data) {
                  sessionStorage.setItem(
                    "state_filter_org_expenses",
                    filterDataTable.data
                  );
                  path_str = JSON.parse(filterDataTable.data)?.tab == 1 ? '_fe' : '';
                }
                window.location.href = `/accounting/organization_expenses${path_str}`;
              });
            } else {
              Swal.fire("Error", "ชื่อไฟล์นี้มีอยู่ในระบบแล้ว", "error");
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire(
              "Error",
              "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
              "error"
            );
          });
      });
    }
  }

  async function getDataViewById(is_not_log, exp_id) {
    // console.log('is_not_log, exp_id :>> ', is_not_log, exp_id);
    var temp = {
      id: exp_id,
      type_log: "3",
    };
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 1000,
      onOpen: () => {
        Swal.showLoading();
      },
    }).then(async (result) => {
      await axios({
        method: "post",
        url:
          Configs.API_URL_hrMagenatement +
          (is_not_log
            ? "/api/orgzExpenses/filterOrgzDataByID"
            : "/api/ExpenseLog/getLogExpensesByID"),
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: temp,
      })
        .then(async function (response) {
          let checkVat;
          if (response.data.data.project?.[0]?.emp_exp_is_cash) {
            checkVat = "bill";
          } else if (response.data.data.project?.[0]?.emp_exp_is_vat) {
            checkVat = "vat";
          } else {
            checkVat = "org_is_personal";
          }

          const tempFile = response.data.data.image.map((image) => {
            const url = `${Configs.API_URL_IMG + image.emp_file_path}`;

            return {
              uid: image.emp_upload_id,
              name: image.emp_file_name,
              img_id: image.emp_exp_id,
              status: "done",
              url: url,
              thumbUrl: url,
            };
          });

          if (!is_not_log) {
            const dd_project = {
              value:
                response.data.data.project?.[0]?.emp_exp_log_project_id || "",
              label:
                response.data.data.project?.[0]?.emp_exp_log_project_id || "",
            };
            const dd_cost_center = {
              value: response.data.data.project?.[0]?.emp_exp_log_cc_id || "",
              label: response.data.data.project?.[0]?.emp_exp_log_cc_id || "",
            };
            const dd_paid_by = {
              value: response.data.data.project?.[0]?.emp_exp_log_paid_by || "",
              label: response.data.data.project?.[0]?.emp_exp_log_paid_by || "",
            };
            const dd_topic = {
              value: response.data.data.project?.[0]?.emp_exp_log_et_id || "",
              label: response.data.data.project?.[0]?.emp_exp_log_et_id || "",
            };

            setFilterProject({ ...filterProject, log: [dd_project] });
            setFilterCostCenter({ ...filterCostCenter, log: [dd_cost_center] });
            setAllEmployee({ ...allEmployee, log: [dd_paid_by] });
            setFilterExpenseTopic({ ...filterExpenseTopic, log: [dd_topic] });
            setIsLog({ bool: true });
            isLog.bool = true;
          } else {
            setIsLog({ bool: false });
            isLog.bool = false;
          }

          setFileList(tempFile);

          const obj_status = {};
          if (is_not_log) {
            obj_status.status_id =
              response.data.data.project?.[0]?.org_exp_status || "";

            if (
              response.data.data.project[0].org_exp_status ===
              ACCOUNTING_STATUS.approve_uploaded
            ) {
              // image_approve
              const temp_file2 = response.data.data.image_approve.map(
                (image) => {
                  const url = `${Configs.API_URL_IMG + image.emp_file_path}`;

                  return {
                    uid: image.emp_upload_id,
                    name: image.emp_file_name,
                    img_id: image.emp_exp_id,
                    status: "done",
                    url: url,
                    thumbUrl: url,
                  };
                }
              );
              setFileList2(temp_file2);
            }
          }
          setEmpData({
            ...obj_status,
            cost_type: response.data.data.project?.[0]?.emp_exp_type || "",
            acc_cc_is_fc: response.data.data.project?.[0]?.emp_exp_is_fc || "",
            acc_cc_is_cp: response.data.data.project?.[0]?.emp_exp_is_cp || "",
            acc_cc_is_cos:
              response.data.data.project?.[0]?.emp_exp_is_cos || "",

            project_id: is_not_log
              ? response.data.data.project?.[0]?.acc_project_id || ""
              : response.data.data.project?.[0]?.emp_exp_log_project_id || "", // dd
            project_name: is_not_log
              ? response.data.data.project?.[0]?.acc_project_name || ""
              : response.data.data.project?.[0]?.emp_exp_log_project_id || "", // dd

            cost_item_id: is_not_log
              ? response.data.data.project?.[0]?.acc_cc_id || ""
              : response.data.data.project?.[0]?.emp_exp_log_cc_id || "", // dd
            cost_item_name: is_not_log
              ? response.data.data.project?.[0]?.acc_cc_name || ""
              : response.data.data.project?.[0]?.emp_exp_log_cc_id || "", // dd

            cost_date: response.data.data.project?.[0]?.emp_exp_start_date
              ? new Date(response.data.data.project?.[0]?.emp_exp_start_date)
              : null,
            amount: response.data.data.project?.[0]?.emp_exp_amount || "",

            cus_id: is_not_log
              ? response.data.data.project?.[0]?.id || ""
              : response.data.data.project?.[0]?.emp_exp_log_paid_by || "", // dd
            cus_name: is_not_log
              ? response.data.data.project?.[0]?.emp_name_th || ""
              : response.data.data.project?.[0]?.emp_exp_log_paid_by || "", // dd

            cost_topic_id: is_not_log
              ? response.data.data.project?.[0]?.acc_et_id || ""
              : response.data.data.project?.[0]?.emp_exp_log_et_id || "", // dd
            cost_topic_name: is_not_log
              ? response.data.data.project?.[0]?.acc_et_name || ""
              : response.data.data.project?.[0]?.emp_exp_log_et_id || "", // dd

            cost_topic_check:
              response.data.data.project?.[0]?.acc_et_is_doc_travel || "",
            cost_detail: response.data.data.project?.[0]?.emp_exp_detail || "",
            vat: checkVat || "",
            store: inputValue,
            invoice: response.data.data.project?.[0]?.emp_exp_inv_no || "",
            ref_id: response.data.data.project?.[0]?.org_ref_id || "",

            cus_id_2: is_not_log
              ? response.data.data.project?.[0]?.id_2 || ""
              : response.data.data.project?.[0]?.emp_exp_log_paid_by_2 || "", // dd
            cus_name_2: is_not_log
              ? response.data.data.project?.[0]?.emp_name_th_2 || ""
              : response.data.data.project?.[0]?.emp_exp_log_paid_by_2 || "", // dd

            org_is_personal:
              response.data.data.project[0].emp_org_is_personal || null,
            org_is_tax: response.data.data.project[0].emp_org_tax || null,

            org_is_tax_amount:
              response.data.data.project[0].emp_org_tax_amount || "",
          });

          setInputValue(
            response.data.data.project?.[0]?.emp_exp_shop_name || ""
          );
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire(
            "Error",
            "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
            "error"
          );
        });
    });
  }

  function check_amount_vat(amount, vat, tax = 1, state, setState) {
    let value = 0;
    let amountVat = 0;

    if (amount.target) {
      const input = amount.target;
      value = Number(input.value.trim().replace(/,/g, ""));
    } else {
      value = amount;
    }

    if (vat === "vat") {
      amountVat = value / 1.07 /* (value * 7) / 100 */;
    } else {
      amountVat = value;
    }

    state.org_is_tax_amount = Number(((amountVat * tax) / 100).toFixed(2));
    setState(state);
  }

  function check_amount(e) {
    const input = e.target;
    const value = input.value.trim().replace(/,/g, "");
    const decimalRegex = /^\d*\.?\d{0,2}$/;
    const allZerosRegex = /^0+(\.0{0,2})?$/;

    setEmpData({
      ...empData,
      amount: value,
      // org_is_tax_amount: empData.vat == "vat" ? (value * 7) / 100 : "",
    });
    empData.amount = value;
    /* if (value === "" || value === "0") {
      setAmountNotNull(true);
    } else if (decimalRegex.test(value) && !allZerosRegex.test(value)) {
      setEmpData({
        ...empData,
        amount: value,
      });
      setAmountNotNull(false);
    } else {
      input.value = empData.amount;
    }
    console .log('empData.amount :>> ', empData.amount); */
  }

  const handleSave = () => {
    if (selectedOptionStore) {
      setInputValue(selectedOptionStore.label);
      toggleModal();
    } else {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: "กรุณาเลือกจาก Dropdown ก่อนบันทึก!",
        willClose: () => {
          setTimeout(() => {
            console.log("โปรดลองอีกครั้ง");
          }, 1500);
        },
      });
      setShowModalStore(true);
    }
  };

  const handleCancel = () => {
    setInputValue(inputValue);
    setSelectedOptionStore(null);
    setShowModalStore(false);
  };

  async function check_detail(e) {
    if (e.target.value.trim() === "") {
      setEmpData({
        ...empData,
        cost_detail: e.target.value,
      });
      setDetailNotNull(true);
    } else if (e.target.value.trim().length >= 200) {
      setDetailNotNull(false);
    } else {
      setEmpData({
        ...empData,
        cost_detail: e.target.value,
      });
      setDetailNotNull(false);
    }
  }

  async function check_store(e) {
    if (e.target.value.trim() === "") {
      setEmpData({
        ...empData,
        store: e.target.value,
      });
      setStoreNotNull(true);
    } else if (e.target.value.trim().length >= 200) {
      setStoreNotNull(false);
    } else {
      setEmpData({
        ...empData,
        store: e.target.value,
      });
      setStoreNotNull(false);
    }
  }

  async function check_invoice(e) {
    if (e.target.value.trim() === "") {
      setEmpData({
        ...empData,
        invoice: e.target.value,
      });
      setInvoiceNotNull(true);
    } else if (e.target.value.trim().length >= 200) {
      setInvoiceNotNull(false);
    } else {
      setEmpData({
        ...empData,
        invoice: e.target.value,
      });
      setInvoiceNotNull(false);
    }
  }

  useEffect(async () => {
    if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
    if (mode.mode !== "Add") {
      const data2 = sessionStorage.getItem("state_filter_org_expenses");
      if (data2) {
        setFilterDataTable({ data: data2 });
        filterDataTable.data = data2;
        sessionStorage.removeItem("state_filter_org_expenses");
      }
    }
    await getDataByID(id);

    if (mode.mode === "view") {
      setdisable(true);
      setPageMode("View");
      await getLogListByID();
    }
  }, []);

  useEffect(() => {
    GetEmployee();
    getCostCenterByID(empData);
    getProjectByID(empData);
    getExpenseTopicByID(empData);
  }, [empData.cost_type, empData.project_id]);

  useEffect(() => {
    if (isCheck.bool) {
      setInputValue(empData.store);
    } else {
      setInputValue("สด");
    }
    // empData.store = inputValue;
  }, [isCheck.bool, empData.store]);

  async function uploadFile(is_send) {
    const data = new FormData();
    if (fileList) {
      for (let item of fileList) {
        if (item.status != "done") {
          data.append("Profile", item.originFileObj);
          data.append("fileSource", "fileList");
        }
      }
    }

    if (fileList3) {
      for (let item of fileList3) {
        if (item.status != "done") {
          data.append("Profile", item.originFileObj);
          data.append("fileSource", "fileList3");
        }
      }
    }

    data.append("typeFile", "exp_upload_file");
    Swal.fire({
      title: `คุณต้องการที่จะ${is_send ? "ส่งอนุมัติ" : "บันทึก"}หรือไม่`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: is_send ? "ใช่" : `บันทึก`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: is_send ? "ไม่" : `ยกเลิก`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const error_list = [];
        if (empData.cost_type == "" || empData.cost_type == null) {
          let temp_err = {
            message: "กรุณาเลือกประเภทค่าใช้จ่าย",
          };
          error_list.push(temp_err);
        }
        if (
          empData.cost_type == "2" &&
          (empData.project_id == "" || empData.project_id == null)
        ) {
          let temp_err = {
            message: "กรุณาเลือกโครงการ",
          };
          error_list.push(temp_err);
        }
        if (empData.cost_item_name == "" || empData.cost_item_name == null) {
          let temp_err = {
            message: "กรุณาเลือกรายการค่าใช้จ่าย",
          };
          error_list.push(temp_err);
        }
        if (empData.cost_date == "" || empData.cost_date == null) {
          let temp_err = {
            message: "กรุณากรอกวันที่เกิดค่าใช้จ่าย",
          };
          error_list.push(temp_err);
        }
        if (
          empData.amount == "" ||
          empData.amount == null ||
          empData.amount == "0"
        ) {
          let temp_err = {
            message: "กรุณากรอกยอดเงิน",
          };
          error_list.push(temp_err);
        }
        if (empData.cus_id == "" || empData.cus_id == null) {
          let temp_err = {
            message: "กรุณาเลือกผู้จ่าย",
          };
          error_list.push(temp_err);
        }
        if (
          (empData.cus_id_2 == "" || empData.cus_id_2 == null) &&
          empData.reserve != false
        ) {
          let temp_err = {
            message: "กรุณาเลือกผู้สำรองจ่าย",
          };
          error_list.push(temp_err);
        }
        if (empData.cost_topic_name == "" || empData.cost_topic_name == null) {
          let temp_err = {
            message: "กรุณาเลือกหัวข้อค่าใช้จ่าย",
          };
          error_list.push(temp_err);
        }
        if (empData.cost_detail == "" || empData.cost_detail == null) {
          let temp_err = {
            message: "กรุณากรอกรายละเอียดค่าใช้จ่าย",
          };
          error_list.push(temp_err);
        }
        if (empData.vat == "" || empData.vat == null) {
          let temp_err = {
            message: "กรุณาเลือก Vat",
          };
          error_list.push(temp_err);
        }

        if (inputValue == "" || inputValue == null) {
          let temp_err = {
            message: "กรุณากรอกชื่อร้านค้า",
          };
          error_list.push(temp_err);
        }

        if (empData.vat !== "bill") {
          if (empData.invoice == "" || empData.invoice == null) {
            let temp_err = {
              message: "กรุณากรอก Invoice",
            };
            error_list.push(temp_err);
          }
        }

        if (
          fileList.length == 0 ||
          (fileList3.length == 0 && empData.reserve != false)
        ) {
          let temp_err = {
            message: "กรุณาอัปโหลดรูปภาพ",
          };
          error_list.push(temp_err);
        }
        // if (
        //   (empData.org_reserve_remark == "" ||
        //     empData.org_reserve_remark == null) &&
        //   empData.reserve === true
        // ) {
        //   let temp_err = {
        //     message: "กรุณากรอกหมายเหตุ",
        //   };
        //   error_list.push(temp_err);
        // }
        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }
          Swal.fire("Error", err_message, "error");
        } else {
          var config_uploadProfile = {
            method: "post",
            url: Configs.API_URL + "/upload/document",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: data,
          };
          await axios(config_uploadProfile)
            .then(function (response) {
              if (response.data.status) {
                const uploadedFiles = response.data.data;
                const fileSources = data.getAll("fileSource");

                const file_list = uploadedFiles
                  .filter((_, index) => fileSources[index] === "fileList")
                  .map((el) => {
                    return { name: el.orgin_name, path: el.path };
                  });

                const file_list3 = uploadedFiles
                  .filter((_, index) => fileSources[index] === "fileList3")
                  .map((el) => {
                    return { name: el.orgin_name, path: el.path };
                  });
                // return
                setEmpData({
                  ...empData,
                  bill_path: file_list,
                  reserve_path: file_list3,
                });
                SaveDataFile(is_send || false, file_list, file_list3);
              } else if (!response.data.status && mode.mode === "edit") {
                if (filterDataTable.data) {
                  sessionStorage.setItem(
                    "state_filter_org_expenses",
                    filterDataTable.data
                  );
                }
                // window.location.href =
                //   "/accounting/organization_expenses";
                SaveDataFile(is_send);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    });
  }

  async function sendApproveFromOrgzToAdmin(org_id) {
    const data = {
      org_id_list: [org_id],
      status_id: ACCOUNTING_STATUS.waiting,
      status_id2: ACCOUNTING_STATUS.approve_uploaded,
      user_id: getUser().fup,
      remark: "",
      org_reserve_remark: empData.org_reserve_remark,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/sendApproveFromOrgzToAdmin",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(async function (response) {
        if (response.data.data !== false) {
          return true;
        } else {
          Swal.fire("Error", "ส่งอนุมัติไม่สำเร็จ", "error");
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire(
          "Error",
          "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
          "error"
        );
      });
  }

  async function SendStatusExpensesByAdmin(state) {
    const data = {
      emp_exp_id: dataForSave.id,
      status_id: "",
      remark: dataForSave.remark_not_approve.trim(),
      user_id: getUser().fup,
    };
    if (state === "not_aprrove") {
      data.status_id = ACCOUNTING_STATUS.not_approve;
      if (!data.remark) {
        Swal.fire("Error", `กรุณากรอก Remark`, "error");
        return;
      }
    } else if (state === "aprrove") {
      data.status_id = ACCOUNTING_STATUS.approve_not_upload;
      data.remark = null;
      data.is_approved = true;
    }
    handleCloseModal();
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/SendStatusExpensesByAdmin",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.data) {
          Swal.fire({
            icon: "success",
            title: "ทำรายการสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            if (filterDataTable.data) {
              sessionStorage.setItem(
                "state_filter_org_expenses",
                filterDataTable.data
              );
            }
            window.location.href = "/accounting/expenses_approve";
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  function GetBodyModal(modalState) {
    if (modalState === "not_approve") {
      return (
        <div style={{ padding: "15px" }}>
          <textarea
            type="text"
            className="form-control"
            required
            rows={4}
            id="award_point_name"
            value={dataForSave.remark_not_approve}
            onChange={(e) => {
              // check_rf_name(e);
              setDataForSave({
                ...dataForSave,
                remark_not_approve: e.target.value,
              });
              dataForSave.remark_not_approve = e.target.value;
            }}
          />
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              display: "flex",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <button
              type="button"
              style={{ minWidth: "130px" }}
              onClick={() => {
                SendStatusExpensesByAdmin("not_aprrove");
              }}
              className="btn btn-success"
              id="btn-save-SendStatusExpensesByAdmin"
              key="btn-save-SendStatusExpensesByAdmin"
            >
              Save
            </button>
            <button
              type="button"
              style={{ minWidth: "130px" }}
              onClick={() => {
                handleCloseModal();
              }}
              className="btn btn-danger"
              id="btn-cancle-handleCloseModal"
              key="btn-cancle-handleCloseModal"
            >
              Cancel
            </button>
          </div>
        </div>
      );
    }
  }

  useEffect(() => {
    GetEmployee();
    getCostCenterByID(empData);
    getProjectByID(empData);
    getExpenseTopicByID(empData);
  }, [empData.cost_type, empData.project_id]);

  const handleUploadSuccess = (file) => {
    // console.log('isClickAtCamera :>> ', isClickAtCamera);
    // console.log('file.file.originFileObj :>> ', file);
    if (isClickAtCamera && file.file) {
      handleFileUpload(file.file);
      setIsClickAtCamera(false);
    }
    setFileList(file.fileList);
  };

  const handleUploadSuccess3 = (file) => {
    // console.log('isClickAtCamera :>> ', isClickAtCamera);
    // console.log('file.file.originFileObj :>> ', file);
    setFileList3(file.fileList);
  };

  const handleFileUpload = async (a) => {
    const data = new FormData();
    console.log("a", a);
    data.append("file", a);

    Swal.fire({
      title: "กำลังประมวลผลภาพ",
      text: "Loading...",
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await axios.post(
        Configs.API_URL_AI_OCR + "/upload/",
        data,
        {
          headers: {
            "X-API-KEY": "TTT-OCR-SECRET-KEY",
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response.data);  // ดูข้อมูลตอบกลับจาก API
      if (response.data) {
        const ocrResult = response.data.ocr_result;

        const isValidDate = moment(
          ocrResult.sale_date,
          "YYYY-MM-DD",
          true
        ).isValid();
        const formattedDate = isValidDate
          ? moment(ocrResult.sale_date, "YYYY-MM-DD").toDate()
          : null;

        setEmpData((prevEmpData) => ({
          ...prevEmpData,
          // store: ocrResult.company_name?.trim(),
          invoice: ocrResult.invoice_number,
          cost_date: formattedDate,
          amount: ocrResult.total_value,
          vat: ocrResult.vat_class,
        }));
        setShowWithholdingTax(ocrResult.vat_class === "vat");

        setInputValue(ocrResult.company_name?.trim() || "");

        Swal.close();
      }
    } catch (error) {
      console.error("File upload error:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "มีปัญหาในการประมวลผลไฟล์ โปรดลองอีกครั้ง",
      });
    }
  };

  // try {
  //   const response = await axios.post("http://150.95.25.8:8000/upload/", data, {
  //       headers: {
  //           "X-API-KEY": "TTT-OCR",
  //           Authorization: getToken(),
  //           "X-TTT": Configs.API_TTT,
  //           "Content-Type": "multipart/form-data",
  //       },
  //   });

  const handleRemove = (file) => {
    const newFileList = fileList.filter(
      (item) => item.uid !== (file.uid || file.originFileObj.uid)
    );
    if (!file.lastModified) {
      setDelIMG([...delIMG, file]);
    }
    setFileList(newFileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleRemove3 = (file) => {
    const newFileList = fileList3.filter(
      (item) => item.uid !== (file.uid || file.originFileObj.uid)
    );
    if (!file.lastModified) {
      setDelIMG([...delIMG, file]);
    }
    setFileList3(newFileList);
  };

  const handlePreview3 = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const [heightOfContent, setHeightOfContent] = useState({
    data: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const sidebar = document.querySelector(".main-sidebar");
      if (sidebar) {
        const height = sidebar.offsetHeight - 50;
        setHeightOfContent({ data: height });
        heightOfContent.data = height;
      }
    }, 1000);
  }, []);

  return (
    <div className="wrapper">
      <div
        className="content-wrapper"
        style={
          heightOfContent.data ? { minHeight: `${heightOfContent.data}px` } : {}
        }
      >
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{pageMode} {mode.isAdmin ? "Expenses Approve" : "Organization expenses"}</h1>
              </div>
              <div className="col">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">
                    {mode.isAdmin ? "Expenses Approve" : "Organization expenses"}
                  </li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "view" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={cancle_add_award_point}
                    className={`btn btn-block btn-${
                      mode.isAdmin ? "primary" : "danger"
                    } `}
                    style={{ marginTop: "10px" }}
                    id="btn-cancle"
                  >
                    {mode.isAdmin ? "ย้อนกลับ" : "ยกเลิก"}
                  </button>
                </div>
                {window.location.pathname.includes("expenses_approve") ? (
                  <>
                    <div
                      className="col-6 col-md-4 col-xl-2"
                      hidden={empData.status_id !== ACCOUNTING_STATUS.waiting}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          // alert('test')
                          Swal.fire({
                            title: "คุณต้องการที่จะอนุมัติหรือไม่",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: `บันทึก`,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            cancelButtonText: `ยกเลิก`,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setDataForSave({
                                ...dataForSave,
                                id: id,
                              });
                              dataForSave.id = id;
                              SendStatusExpensesByAdmin("aprrove");
                            }
                          });
                        }}
                        className="btn btn-block btn-success"
                        style={{ marginTop: "10px" }}
                        id="btn-cancle"
                      >
                        อนุมัติ
                      </button>
                    </div>
                    <div
                      className="col-6 col-md-4 col-xl-2"
                      hidden={empData.status_id !== ACCOUNTING_STATUS.waiting}
                    >
                      <button
                        type="button"
                        className="btn btn-block btn-danger"
                        onClick={() => {
                          Swal.fire({
                            title: "คุณต้องการที่จะไม่อนุมัติหรือไม่",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: `บันทึก`,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            cancelButtonText: `ยกเลิก`,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setDataForSave({
                                ...dataForSave,
                                id: id,
                                remark_not_approve: "",
                              });
                              dataForSave.id = id;
                              dataForSave.remark_not_approve = "";
                              handleOpenModal("not_approve");
                            }
                          });
                        }}
                        style={{ marginTop: "10px" }}
                        id="btn-cancle"
                      >
                        ไม่อนุมัติ
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="col-6 col-md-3 col-xl-2">
                    <div className="form-group" /* style={{ zIndex: "99" }} */>
                      <Select
                        filterOption={(option, searchText) => {
                          if (
                            option.data.label
                              ?.toString()
                              .toLowerCase()
                              .includes(searchText?.toLowerCase())
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                        isDisabled={false}
                        options={[
                          ...logList.data.map((el) => ({
                            value: el.value,
                            label: el.label,
                            is_not_log: el.is_not_log || false,
                          })),
                        ]}
                        value={logListSelect}
                        onChange={(selectedOptions) => {
                          setLogListSelect({
                            ...selectedOptions,
                          });
                          getDataViewById(
                            selectedOptions?.is_not_log || false,
                            selectedOptions.value
                          );
                        }}
                      />
                      <label htmlFor="" style={{ marginTop: "-4px" }}>
                        Version
                      </label>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={() => {
                      uploadFile();
                    }}
                    className="btn btn-block btn-success"
                    style={{
                      display: `${mode.mode === "view" ? "none" : ""}`,
                      marginBottom: "10px",
                    }}
                    id="btn-save"
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={cancle_add_award_point}
                    style={{ marginBottom: "10px" }}
                    className="btn btn-block btn-danger"
                    id="btn-cancle"
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="col-12 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={() => {
                      uploadFile(true);
                    }}
                    className="btn btn-block btn-primary"
                    style={{
                      display: `${mode.mode === "view" ? "none" : ""}`,
                      marginBottom: "10px",
                    }}
                    id="btn-save"
                  >
                    ส่งอนุมัติ
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{pageMode} Organization expenses</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="row mb-2">
                    <div className="col-12 col-md-12 col-xl-11">
                      <h5
                        style={{
                          borderBottom: "2px solid rgba(53, 121, 246, 1)",
                          width: "100%",
                          fontWeight: "bold",
                        }}
                      >
                        กำหนดประเภทค่าใช้จ่าย
                      </h5>
                      <div
                        className="col-12 col-md-12 col-xl-12"
                        style={{ padding: 0 }}
                      >
                        <label htmlFor="status" style={{ margin: 0 }}>
                          ประเภทค่าใช้จ่าย
                          <span style={{ color: "red" }}>
                            {!disable ? " *" : ""}
                          </span>
                        </label>
                      </div>
                      <Radio.Group
                        onChange={(e) => {
                          const value = e.target.value;
                          setEmpData({
                            ...empData,
                            cost_type: value.toString(),
                            acc_cc_is_fc: value === 1,
                            acc_cc_is_cp: value === 2,
                            acc_cc_is_cos: value === 3,
                            cost_item_id: "",
                            cost_item_name: "",
                            cost_close: "",
                            project_id: "",
                            project_name: "",
                          });
                        }}
                        value={parseInt(empData.cost_type)}
                        disabled={disable}
                      >
                        <Space direction="vertical">
                          <Radio value={1}>Fix Cost (ต้นทุนคงที่)</Radio>
                          <Radio value={2}>Cost Project (ต้นทุนโครงการ)</Radio>
                          <Radio value={3}>
                            Cost out sourcing
                            (ต้นทุนจัดจ้างที่ไม่เกี่ยวกับโครงการ)
                          </Radio>
                        </Space>
                      </Radio.Group>

                      {/* <div
                        className="col-12 col-md-3 col-xl-2"
                        style={{ padding: 0 }}
                      >
                        <div className="form-group" style={{ margin: 0 }}>
                          <div id="status" className="status-options d-flex">
                            <label
                              htmlFor="status-fix-cost"
                              style={{
                                marginTop: "-1.5mm",
                                fontSize: "15pt",
                                paddingLeft: "10pt",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Fix Cost (ต้นทุนคงที่)
                            </label>
                            <div>
                              <input
                                type="radio"
                                id="status-fix-cost"
                                value="cost_type"
                                disabled={disable}
                                checked={empData.cost_type === "1"}
                                onChange={(e) => {
                                  setEmpData({
                                    ...empData,
                                    cost_type: e.target.checked ? "1" : "",
                                    acc_cc_is_fc: true,
                                    acc_cc_is_cp: false,
                                    acc_cc_is_cos: false,
                                    cost_item_id: "",
                                    cost_item_name: "",
                                    cost_close: "",
                                    project_id: "",
                                    project_name: "",
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-12 col-md-3 col-xl-2"
                        style={{ padding: 0 }}
                      >
                        <div className="form-group" style={{ margin: 0 }}>
                          <div id="status" className="status-options d-flex">
                            <label
                              htmlFor="status-pending"
                              style={{
                                marginTop: "-1.5mm",
                                fontSize: "15pt",
                                paddingLeft: "10pt",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Cost Project (ต้นทุนโครงการ)
                            </label>
                            <div className="">
                              <input
                                type="radio"
                                id="status-pending"
                                value="cost_type"
                                disabled={disable}
                                checked={empData.cost_type === "2"}
                                onChange={(e) => {
                                  setEmpData({
                                    ...empData,
                                    cost_type: e.target.checked ? "2" : "",
                                    acc_cc_is_fc: false,
                                    acc_cc_is_cp: true,
                                    acc_cc_is_cos: false,
                                    cost_item_id: "",
                                    cost_item_name: "",
                                    cost_close: "",
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-12 col-md-3 col-xl-4"
                        style={{ padding: 0 }}
                      >
                        <div className="form-group" style={{ margin: 0 }}>
                          <div id="status" className="status-options d-flex">
                            <label
                              htmlFor="status-pending"
                              style={{
                                marginTop: "-1.5mm",
                                fontSize: "15pt",
                                paddingLeft: "10pt",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Cost out sourcing
                              (ต้นทุนจัดจ้างที่ไม่เกี่ยวกับโครงการ)
                            </label>
                            <div className="">
                              <input
                                type="radio"
                                id="status-pending"
                                value="cost_type"
                                disabled={disable}
                                checked={empData.cost_type === "3"}
                                onChange={(e) => {
                                  setEmpData({
                                    ...empData,
                                    cost_type: e.target.checked ? "3" : "",
                                    acc_cc_is_fc: false,
                                    acc_cc_is_cp: false,
                                    acc_cc_is_cos: true,
                                    cost_item_id: "",
                                    cost_item_name: "",
                                    cost_close: "",
                                    project_id: "",
                                    project_name: "",
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  {empData.cost_type === "2" && (
                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-11">
                        <div className="form-group">
                          <Select
                            filterOption={(option, searchText) => {
                              if (
                                option.data.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                                // || option.data.value
                                //   .toLowerCase()
                                //   .includes(searchText.toLowerCase())
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            }}
                            isDisabled={disable}
                            options={[
                              ...filterProject[isLog.bool ? "log" : "data"].map(
                                (el) => ({
                                  value: el.acc_project_id,
                                  label: el.acc_project_name,
                                  // isDisabled: el.emp_id === dataHrlog.rl_usr_id_filed,
                                })
                              ),
                            ]}
                            value={
                              empData.project_id != null
                                ? {
                                    value: empData.project_id,
                                    label: empData.project_name,
                                  }
                                : null
                            }
                            onChange={(selectedOptions) => {
                              setEmpData({
                                ...empData,
                                project_id: selectedOptions.value || "",
                                project_name: selectedOptions.label || "",
                              });
                            }}
                          />
                          <label htmlFor="" style={{ marginTop: "-4px" }}>
                            โครงการ
                            <span style={{ color: "red" }}>
                              {!disable ? " *" : ""}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div
                      className="col-12 col-md-12 col-xl-11"
                      // style={{ "z-index": "99" }}
                    >
                      <div
                        className="form-group" /*  style={{ zIndex: "99" }} */
                      >
                        <Select
                          filterOption={(option, searchText) => {
                            if (
                              option.data.label
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                              // || option.data.value
                              //   .toLowerCase()
                              //   .includes(searchText.toLowerCase())
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                          isDisabled={disable}
                          options={[
                            ...filterCostCenter[
                              isLog.bool ? "log" : "data"
                            ].map((el) => ({
                              value: el.acc_cc_id,
                              label: el.acc_cc_name,
                            })),
                          ]}
                          value={
                            empData.cost_item_id != null
                              ? {
                                  value: empData.cost_item_id,
                                  label: empData.cost_item_name,
                                }
                              : null
                          }
                          onChange={(selectedOptions) => {
                            setEmpData({
                              ...empData,
                              cost_item_id: selectedOptions.value || "",
                              cost_item_name: selectedOptions.label || "",
                            });
                          }}
                        />
                        <label htmlFor="" style={{ marginTop: "-4px" }}>
                          รายการค่าใช้จ่าย (Cost Center)
                          <span style={{ color: "red" }}>
                            {!disable ? " *" : ""}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-12 col-md-12 col-xl-11"
                      // style={{ "z-index": "99" }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h5
                          style={{
                            borderBottom: "2px solid rgba(53, 121, 246, 1)",
                            width: "100%",
                            fontWeight: "bold",
                            marginRight: "10px",
                          }}
                        >
                          รายละเอียดการใช้จ่าย
                        </h5>
                        <i
                          className="fas fa-camera"
                          style={{ fontSize: "24px", cursor: "pointer" }}
                          onClick={handleIconClick}
                        ></i>
                      </div>

                      <div
                        className="form-group input-group" /* style={{ zIndex: "98" }} */
                      >
                        <DatePicker
                          id="inputDate"
                          className="form-control"
                          wrapperClassName="form-control"
                          dateFormat="dd/MM/yyyy"
                          disabled={disable}
                          selected={empData.cost_date}
                          onChange={async (date) => {
                            setEmpData({
                              ...empData,
                              cost_date: date,
                            });
                          }}
                          // placeholderText="วันที่เกิดค่าใช้จ่าย"
                          // selected={searchInput.booking_date}
                          // onChange={(date) => {
                          //     setSearchInput({
                          //         ...searchInput,
                          //         booking_date: date,
                          //     });
                          // }}
                        />
                        <label style={{ marginTop: "-4px" }}>
                          วันที่เกิดค่าใช้จ่าย{" "}
                          <span style={{ color: "red" }}>
                            {!disable ? " *" : ""}
                          </span>
                          <span style={{ color: "rgba(29, 194, 86, 1)" }}>
                            (Auto)
                          </span>
                        </label>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-calendar-alt" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-11">
                      <div className="form-group">
                        {/* <input
                                            type="number"
                                            className="form-control"
                                            required
                                            id="award_point_name"
                                            value={empData.amount}
                                            // placeholder="กรอกยอดเงิน"
                                            disabled={disable}
                                            min={1}
                                            step={0.1}
                                            onChange={(e) => {
                                                check_amount(e);
                                            }}
                                        /> */}
                        <NumberFormat
                          type="text"
                          className="form-control"
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          allowNegative={false}
                          decimalScale={2}
                          maxLength={15}
                          id="award_point_name"
                          required="false"
                          disabled={disable}
                          value={empData.amount}
                          onChange={(e) => {
                            // check_amount(e);
                            const input = e.target;
                            const value = input.value.trim().replace(/,/g, "");
                            const temp = {
                              ...empData,
                              amount: value,
                            };
                            check_amount_vat(
                              e,
                              empData.vat,
                              empData.org_is_tax,
                              temp,
                              setEmpData
                            );
                          }}
                        />
                        <label>
                          ยอดเงิน{" "}
                          <span style={{ color: "red" }}>
                            {!disable ? " *" : ""}
                          </span>
                          <span style={{ color: "rgba(29, 194, 86, 1)" }}>
                            (Auto)
                          </span>
                        </label>
                        {/* {amountNotNull ? (
                                            <span style={{ color: "red" }}>
                                                *กรุณากรอกจำนวนเงิน
                                            </span>
                                        ) : (
                                            ""
                                        )} */}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-12 col-md-12 col-xl-11"
                      // style={{ "z-index": "99" }}
                    >
                      <div
                        className="form-group" /*  style={{ zIndex: "99" }} */
                      >
                        <Select
                          filterOption={(option, searchText) => {
                            if (
                              option.data.label
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                              // || option.data.value
                              //   .toLowerCase()
                              //   .includes(searchText.toLowerCase())
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                          isDisabled={
                            disable || (empData.ref_id != "" ? true : false)
                          }
                          options={[
                            ...allEmployee[isLog.bool ? "log" : "data"].map(
                              (el) => ({
                                value: el.emp_id,
                                label: el.emp_name_th,
                              })
                            ),
                          ]}
                          value={
                            empData.cus_id != null
                              ? {
                                  value: empData.cus_id,
                                  label: empData.cus_name,
                                }
                              : null
                          }
                          onChange={(selectedOptions) => {
                            if (empData.reserve === false) {
                              setEmpData({
                                ...empData,
                                cus_id: selectedOptions.value || "",
                                cus_name: selectedOptions.label || "",
                                cus_id_2: selectedOptions.value || "",
                                cus_name_2: selectedOptions.label || "",
                                store: selectedOptions.label || "",
                              });
                            } else if (empData.reserve === true) {
                              setEmpData({
                                ...empData,
                                cus_id: selectedOptions.value || "",
                                cus_name: selectedOptions.label || "",
                              });
                            }
                            setInputValue(selectedOptions.label);
                          }}
                        />
                        <label style={{ marginTop: "-4px" }}>
                          ผู้จ่าย{" "}
                          <span style={{ color: "red" }}>
                            {!disable ? " *" : ""}
                          </span>
                          <span style={{ color: "rgba(255, 0, 36, 1)" }}>
                            (Manual)
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div
                      className="col-12 col-md-12 col-xl-11"
                    >
                      <div className="form-group">
                        <Select
                          filterOption={(option, searchText) => {
                            if (
                              option.data.label
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                          isDisabled={disable}
                          options={[
                            ...allEmployee[isLog.bool ? "log" : "data"].map(
                              (el) => ({
                                value: el.emp_id,
                                label: el.emp_name_th,
                              })
                            ),
                          ]}
                          value={
                            empData.cus_id_2 != null
                              ? {
                                value: empData.cus_id_2,
                                label: empData.cus_name_2,
                              }
                              : null
                          }
                          onChange={(selectedOptions) => {
                            setEmpData({
                              ...empData,
                              cus_id_2: selectedOptions.value || "",
                              cus_name_2: selectedOptions.label || "",
                            });
                          }}
                        />
                        <label style={{ marginTop: "-4px" }}>
                          ผู้เตรียม{" "}
                          <span style={{ color: "red" }}>{!disable ? " *" : ""}</span>
                          <span style={{ color: "rgba(255, 0, 36, 1)" }}>
                            (Manual)
                          </span>
                        </label>
                      </div>
                    </div>
                  </div> */}

                  <div className="row">
                    <div
                      className="col-12 col-md-12 col-xl-11"
                      // style={{ "z-index": "99" }}
                    >
                      <h5
                        style={{
                          borderBottom: "2px solid rgba(53, 121, 246, 1)",
                          width: "100%",
                          fontWeight: "bold",
                        }}
                      >
                        กำหนดประเภทค่าใช้จ่าย
                      </h5>
                      <div
                        className="form-group" /*  style={{ zIndex: "99" }} */
                      >
                        <Select
                          filterOption={(option, searchText) => {
                            if (
                              option.data.label
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                              // || option.data.value
                              //   .toLowerCase()
                              //   .includes(searchText.toLowerCase())
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                          isDisabled={disable}
                          options={[
                            ...filterExpenseTopic[
                              isLog.bool ? "log" : "data"
                            ].map((el) => ({
                              value: el.acc_et_id,
                              label: el.acc_et_name,
                              check: el.acc_et_is_doc_travel,
                            })),
                          ]}
                          value={
                            empData.cost_topic_id != null
                              ? {
                                  value: empData.cost_topic_id,
                                  label: empData.cost_topic_name,
                                }
                              : null
                          }
                          onChange={(selectedOptions) => {
                            if (selectedOptions.check === true) {
                              setEmpData((inState) => ({
                                ...inState,
                                cost_topic_id: selectedOptions.value || "",
                                cost_topic_name: selectedOptions.label || "",
                                cost_topic_check: true,
                                store: inState.cus_name,
                                invoice: "-",
                                vat: "bill",
                                org_is_tax: "",
                                org_is_tax_amount: "",
                              }));

                              setIsCheck({
                                bool: true,
                              });
                              isCheck.bool = true;
                            } else {
                              setIsCheck({
                                bool: false,
                              });
                              isCheck.bool = false;
                              const temp = {
                                ...empData,
                                cost_topic_id: selectedOptions.value || "",
                                cost_topic_name: selectedOptions.label || "",
                                cost_topic_check: false,
                              };
                              if (
                                empData.store == inputValue &&
                                empData.invoice == empData.invoice &&
                                empData.vat == "bill"
                              ) {
                                temp.store = "";
                                temp.invoice = "";
                                temp.vat = "";
                              }

                              setEmpData(temp);
                            }
                            //topic_check

                            // if(empData.cost_topic_check || disable){
                            //   setEmpData({
                            //     ...empData,
                            //     vat: empData.cus_name
                            //     // store: selectedVat === "bill" ? "สด" : "",
                            //     // invoice: selectedVat === "bill" ? "-" : "",
                            //     // cost_close: "",
                            //   });
                            //   console.log("Vat "+empData.vat)
                            //   console.log("cus_name "+empData.cus_name)
                            // }
                          }}
                        />
                        <label>
                          หัวข้อค่าใช้จ่าย{" "}
                          <span style={{ color: "red" }}>
                            {!disable ? " *" : ""}
                          </span>
                          <span style={{ color: "rgba(255, 0, 36, 1)" }}>
                            (Manual)
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-11">
                      <p
                        className="m-0"
                        style={{ textAlign: "end", lineHeight: "0" }}
                      >
                        {
                          empData.cost_detail.replace(
                            /[\u0E31\u0E34-\u0E3A\u0E47-\u0E4E]/g,
                            ""
                          ).length
                        }
                        /140
                      </p>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="award_point_name"
                          value={empData.cost_detail}
                          // placeholder="กรอกยอดเงิน"
                          disabled={disable}
                          onChange={(e) => {
                            if (
                              e.target.value.replace(
                                /[\u0E31\u0E34-\u0E3A\u0E47-\u0E4E]/g,
                                ""
                              ).length <= 140
                            ) {
                              check_detail(e);
                            }
                          }}
                        />
                        <label>
                          รายละเอียดค่าใช้จ่าย{" "}
                          <span style={{ color: "red" }}>
                            {!disable ? " *" : ""}
                          </span>
                          <span style={{ color: "rgba(255, 0, 36, 1)" }}>
                            (Manual)
                          </span>
                        </label>
                        {/* {detailNotNull ? (
                                            <span style={{ color: "red" }}>
                                                *กรุณากรอกรายละเอียดค่าใช้จ่าย
                                            </span>
                                        ) : (
                                            ""
                                        )} */}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-11">
                      <div
                        className="col-12 col-md-12 col-xl-12"
                        style={{ padding: 0 }}
                      >
                        <label>
                          Vat{" "}
                          <span style={{ color: "red" }}>
                            {!disable ? " *" : ""}
                          </span>
                          <span style={{ color: "rgba(29, 194, 86, 1)" }}>
                            (Auto)
                          </span>
                        </label>
                      </div>
                      <Radio.Group
                        onChange={(e) => {
                          const selectedVat = e.target.value;
                          const temp = {
                            ...empData,
                            vat: selectedVat,
                            store: selectedVat === "bill" ? empData.store : "",
                            invoice:
                              selectedVat === "bill" ? "-" : empData.invoice,
                            org_is_personal: selectedVat,
                            cost_close: "",
                            org_is_tax: "",
                            org_is_tax_amount: "",
                          };
                          if (selectedVat != "bill" && selectedVat != "vat") {
                            check_amount_vat(
                              temp.amount,
                              selectedVat,
                              temp.org_is_tax,
                              temp,
                              setEmpData
                            );
                          } else {
                            setEmpData(temp);
                          }
                          if (selectedVat === "vat") {
                            setShowWithholdingTax(selectedVat === "vat");
                          } else if (selectedVat === "org_is_personal") {
                            setShowWithholdingTax(
                              selectedVat === "org_is_personal"
                            );
                          } else if (selectedVat === "bill") {
                            setShowWithholdingTax(false);
                          }
                        }}
                        value={empData.vat}
                        disabled={empData.cost_topic_check || disable}
                      >
                        <Space direction="vertical">
                          <Radio value="vat">Include Vat(7%)</Radio>

                          <Radio value="bill">บิลเงินสด</Radio>
                          <Radio value="org_is_personal">
                            กรณีที่หักส่วนบุคคล
                          </Radio>
                        </Space>
                      </Radio.Group>

                      {empData.vat != "bill" && (
                        <div style={{ marginTop: "10px" }}>
                          <div style={{ color: "red", fontSize: "14" }}>
                            *หากมีการหักภาษี ณ ที่จ่าย สามารถเลือกยอดหักภาษี ณ
                            ที่จ่ายได้ที่ด้านล่าง*
                          </div>
                          <Checkbox
                            checked={empData.org_is_tax === "1"}
                            onChange={(e) => {
                              const temp = {
                                ...empData,
                                org_is_tax: e.target.checked ? "1" : "",
                              };
                              if (e.target.checked) {
                                check_amount_vat(
                                  empData.amount,
                                  empData.vat,
                                  "1",
                                  temp,
                                  setEmpData
                                );
                              } else {
                                temp.org_is_tax_amount = null;
                                setEmpData(temp);
                              }
                            }}
                            disabled={
                              empData.org_is_tax === "5" ||
                              empData.org_is_tax === "3" ||
                              disable
                            }
                          >
                            หักภาษี ณ ที่จ่าย 1%
                          </Checkbox>
                          <Checkbox
                            checked={empData.org_is_tax === "3"}
                            onChange={(e) => {
                              const temp = {
                                ...empData,
                                org_is_tax: e.target.checked ? "3" : "",
                              };
                              if (e.target.checked) {
                                check_amount_vat(
                                  empData.amount,
                                  empData.vat,
                                  "3",
                                  temp,
                                  setEmpData
                                );
                              } else {
                                temp.org_is_tax_amount = null;
                                setEmpData(temp);
                              }
                            }}
                            disabled={
                              empData.org_is_tax === "5" ||
                              empData.org_is_tax === "1" ||
                              disable
                            }
                          >
                            หักภาษี ณ ที่จ่าย 3%
                          </Checkbox>
                          <Checkbox
                            checked={empData.org_is_tax === "5"}
                            onChange={(e) => {
                              const temp = {
                                ...empData,
                                org_is_tax: e.target.checked ? "5" : "",
                              };
                              if (e.target.checked) {
                                check_amount_vat(
                                  empData.amount,
                                  empData.vat,
                                  "5",
                                  temp,
                                  setEmpData
                                );
                              } else {
                                temp.org_is_tax_amount = null;
                                setEmpData(temp);
                              }
                            }}
                            disabled={
                              empData.org_is_tax === "3" ||
                              empData.org_is_tax === "1" ||
                              disable
                            }
                          >
                            หักภาษี ณ ที่จ่าย 5%
                          </Checkbox>
                        </div>
                      )}
                    </div>
                  </div>

                  {empData.org_is_tax ? (
                    <>
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-11">
                          <div
                            className="form-group"
                            style={{
                              marginTop: "15px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <NumberFormat
                              type="text"
                              className="form-control"
                              thousandsGroupStyle="thousand"
                              thousandSeparator=","
                              allowNegative={false}
                              decimalScale={2}
                              // onChange={(e) => setInputValue(e.target.value)}
                              value={empData.org_is_tax_amount}
                              disabled={empData.org_is_tax ? true : false}
                              style={{ flex: 1 }}
                            />
                            <label style={{ fontSize: "16px" }}>
                              หักภาษี ณ ที่จ่าย
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-11">
                          <div
                            className="form-group"
                            style={{
                              // marginTop: "15px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <NumberFormat
                              type="text"
                              className="form-control"
                              thousandsGroupStyle="thousand"
                              thousandSeparator=","
                              allowNegative={false}
                              decimalScale={2}
                              // onChange={(e) => setInputValue(e.target.value)}
                              value={(
                                empData.amount - empData.org_is_tax_amount
                              ).toFixed(2)}
                              disabled={empData.org_is_tax ? true : false}
                              style={{ flex: 1 }}
                            />
                            <label style={{ fontSize: "16px" }}>
                              ยอดรวม
                              {/* <span style={{ color: "rgba(29, 194, 86, 1)" }}>
                            (Auto)
                          </span> */}
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-11">
                      <div
                        className="form-group"
                        style={{
                          // marginTop: "15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* <input
                          type="text"
                          className="form-control"
                          required
                          id="award_point_name"
                          disabled={empData.vat === "bill" || disable}
                          value={
                            selectedOptions.check 
                              ? empData.cus_name 
                              : empData.store
                          }
                          onChange={(e) => {
                            check_store(e);
                          }}
                        /> */}
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="award_point_name"
                          onChange={(e) => {
                            setInputValue(e.target.value);
                            // check_store(e);
                          }}
                          value={inputValue}
                          disabled={
                            isCheck.bool ||
                            empData.vat === false ||
                            showWithholdingTax === false
                          }
                          style={{ flex: 1 }}
                        />
                        <i
                          className="fa fa-search"
                          onClick={toggleModal}
                          style={{
                            marginLeft: "8px",
                            backgroundColor: "rgba(53, 121, 246, 0.15)",
                            padding: "9px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            display:
                              !isCheck.bool &&
                              empData.vat !== false &&
                              showWithholdingTax !== false
                                ? "inline-block"
                                : "none",
                          }}
                        ></i>

                        <Modal
                          centered
                          isOpen={showModalStore}
                          toggle={showModalStore}
                        >
                          <ModalHeader
                            style={{
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <label style={{ fontSize: "24px" }}>
                              ชื่อผู้จ่าย (สำหรับออก Vat
                              ได้):หรือประเภทการเดินทาง{" "}
                              <span style={{ color: "red" }}>
                                {!disable ? " *" : ""}
                              </span>
                              {/* <span style={{ color: "rgba(29, 194, 86, 1)" }}>
                                      (Auto)
                                    </span> */}
                            </label>
                          </ModalHeader>
                          <ModalBody style={{ padding: "0" }}>
                            <div>
                              <hr style={{ margin: "0px" }} />
                              <div style={{ padding: "15px" }}>
                                <div className="row justify-content-center">
                                  <div className="col-12 col-md-8 col-xl-6">
                                    <div className="form-group">
                                      <Select
                                        isDisabled={disable}
                                        options={shopName}
                                        value={selectedOptionStore}
                                        onChange={(option) =>
                                          setSelectedOptionStore(option)
                                        }
                                      />
                                      <label
                                        style={{
                                          fontSize: "16px",
                                          marginTop: "-5px",
                                        }}
                                      >
                                        ชื่อบริษัทฯ และชื่อบุคคล{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <div className="row w-100">
                              <div className="col-6">
                                <Button
                                  color="success"
                                  className="btn-block"
                                  onClick={handleSave}
                                  id="btn-save"
                                >
                                  บันทึก
                                </Button>
                              </div>
                              <div className="col-6">
                                <Button
                                  color="danger"
                                  className="btn-block"
                                  onClick={handleCancel}
                                  id="btn-cancel"
                                >
                                  ยกเลิก
                                </Button>
                              </div>
                            </div>
                          </ModalFooter>
                        </Modal>

                        <label style={{ fontSize: "16px" }}>
                          ชื่อผู้จ่าย (สำหรับออก Vat ได้):หรือประเภทการเดินทาง{" "}
                          <span style={{ color: "red" }}>
                            {!disable ? " *" : ""}
                          </span>
                          <span style={{ color: "rgba(29, 194, 86, 1)" }}>
                            (Auto)
                          </span>
                        </label>
                        {/* {storeNotNull ? (
                                            <span style={{ color: "red" }}>
                                                *กรุณากรอกชื่อร้านค้า
                                            </span>
                                        ) : (
                                            ""
                                        )} */}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-11">
                      <div
                        className="form-group"
                        style={{
                          marginTop: "15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="award_point_name"
                          value={isCheck.bool ? "-" : empData.invoice}
                          disabled={
                            isCheck.bool ||
                            empData.vat == false ||
                            showWithholdingTax == false
                          }
                          onChange={(e) => {
                            check_invoice(e);
                            // if (isCheck.bool) {

                            // }
                          }}
                          style={{ flex: 1 }}
                        />

                        <label>
                          Invoice No.{" "}
                          <span style={{ color: "rgba(29, 194, 86, 1)" }}>
                            (Auto)
                          </span>
                        </label>
                        {/* {invoiceNotNull ? (
                                            <span style={{ color: "red" }}>
                                                *กรุณากรอกเลขกำกับภาษี
                                            </span>
                                        ) : (
                                            ""
                                        )} */}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-12 col-md-12 col-xl-11"
                      style={{ marginBottom: "10px" }}
                    >
                      <label htmlFor="">
                        ใบเสร็จ
                        <span style={{ color: "red" }}>
                          {!disable ? " *" : ""}
                        </span>
                      </label>
                      <div
                        style={{
                          marginTop: `${mode.mode === "view" ? "-35px" : ""}`,
                        }}
                      >
                        <Upload
                          beforeUpload={() => false}
                          className="custom-upload"
                          listType="picture"
                          fileList={fileList}
                          onRemove={handleRemove}
                          onPreview={handlePreview}
                          onChange={handleUploadSuccess}
                          accept=".jpg,.png,.jpeg"
                          multiple
                          disabled={disable}
                          style={{ width: "100%" }}
                        >
                          {mode.mode != "view" ? (
                            <ButtonAnt
                              type="primary"
                              ref={uploadRef}
                              style={{
                                backgroundColor: "#3579F626",
                                color: "#3579F6",
                                width: "100%",
                              }}
                              disabled={disable}
                            >
                              Choose files
                            </ButtonAnt>
                          ) : (
                            <></>
                          )}
                        </Upload>
                      </div>
                    </div>
                  </div>
                  {empData.status_id === ACCOUNTING_STATUS.approve_uploaded && (
                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-11">
                        <label htmlFor="">
                          ใบเสร็จเบิกจ่าย
                          <span style={{ color: "red" }}>
                            {!disable ? " *" : ""}
                          </span>
                        </label>
                        <div
                          style={{
                            marginTop: `${mode.mode === "view" ? "-35px" : ""}`,
                          }}
                        >
                          <Upload
                            beforeUpload={() => false}
                            className="custom-upload"
                            listType="picture"
                            fileList={fileList2}
                            onRemove={handleRemove}
                            onPreview={handlePreview}
                            onChange={handleUploadSuccess}
                            accept=".jpg,.png,.jpeg"
                            multiple
                            disabled={disable}
                            style={{ width: "100%" }}
                          >
                            <></>
                          </Upload>
                        </div>
                      </div>
                    </div>
                  )}

                  <Modal
                    centered
                    isOpen={showModal}
                    toggle={handleCloseModal}
                    // style={{ width: "50%" }}
                  >
                    <ModalHeader
                      style={{
                        /* border: "none" */ paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                      toggle={
                        modalState === "view_remark_not_approve" ||
                        modalState === "view_slips"
                          ? handleCloseModal
                          : undefined
                      }
                    >
                      <span style={{ fontSize: "24px", fontWeight: "800" }}>
                        {modalState === "upload_slips"
                          ? "แนบหลักฐานการชำระเงิน"
                          : modalState === "view_remark_not_approve"
                          ? "รายละเอียดการไม่อนุมัติ"
                          : modalState === "view_slips"
                          ? "รายละเอียดการอนุมัติ"
                          : "Remark"}
                      </span>
                    </ModalHeader>
                    <ModalBody style={{ padding: "0" }}>
                      {GetBodyModal(modalState)}
                    </ModalBody>
                  </Modal>
                  {previewImage && (
                    <div
                      className="expenses-image-popup"
                      id="expenses-image-popup"
                    >
                      <Image
                        className="sdfsdafsadfsdfsdaf"
                        wrapperStyle={{ display: "none", zIndex: 1051 }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <div className="row mb-2">
                    <div className="col-12 col-md-12 col-xl-11">
                      <h5
                        style={{
                          borderBottom: "2px solid rgba(53, 121, 246, 1)",
                          width: "100%",
                          fontWeight: "bold",
                        }}
                      >
                        สำรองจ่าย
                      </h5>
                      <div className="col-12" style={{ padding: 0 }}>
                        <label htmlFor="status" style={{ margin: 0 }}>
                          ประเภทค่าใช้จ่าย
                          <span style={{ color: "red" }}>
                            {!disable ? " *" : ""}
                          </span>
                        </label>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "170px",
                          marginBottom: "10px",
                        }}
                      >
                        <Radio.Group
                          onChange={(e) => {
                            const isReserve = e.target.value === true;

                            // If selecting "ไม่ได้สำรองจ่าย" and there are files to remove
                            if (
                              !isReserve &&
                              fileList3.length > 0 &&
                              fileList3.some((file) => file.status === "done")
                            ) {
                              const doneFiles = fileList3.filter(
                                (file) => file.status === "done"
                              );
                              setDelIMG([...delIMG, ...doneFiles]);
                              setFileList3([]);
                            }

                            setEmpData({
                              ...empData,
                              reserve: isReserve,
                              cus_id_2: isReserve ? empData.cus_id : "",
                              cus_name_2: isReserve ? empData.cus_name : "",
                              org_reserve_remark: isReserve
                                ? empData.org_reserve_remark
                                : "",
                            });

                            if (!isReserve) setFileList3([]);
                          }}
                          value={empData.reserve}
                          disabled={disable}
                        >
                          <Space>
                            <Radio value={false}>ไม่ได้สำรองจ่าย</Radio>
                            <Radio value={true}>สำรองจ่าย</Radio>
                          </Space>
                        </Radio.Group>

                        {/* <div>
                          <div className="form-group">
                            <div id="status" className="status-options d-flex">
                              <label
                                htmlFor="status-pending"
                                style={{
                                  marginTop: "-1.5mm",
                                  fontSize: "15pt",
                                  paddingLeft: "10pt",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                ไม่ได้สำรองจ่าย
                              </label>
                              <div className="">
                                <input
                                  type="radio"
                                  id="status-pending"
                                  value="rl_is_conside"
                                  disabled={disable}
                                  checked={empData.reserve === false}
                                  onRemove={handleRemove3}
                                  onChange={(e) => {
                                    if (
                                      fileList3.length > 0 &&
                                      fileList3.some(
                                        (file) => file.status === "done"
                                      )
                                    ) {
                                      console.log("เข้าไหม");

                                      const doneFiles = fileList3.filter(
                                        (file) => file.status === "done"
                                      );
                                      setDelIMG([...delIMG, ...doneFiles]);
                                      setFileList3([]);
                                    }
                                    setEmpData({
                                      ...empData,
                                      reserve: false,
                                      cus_id_2: empData.cus_id,
                                      cus_name_2: empData.cus_name,
                                      org_reserve_remark: "",
                                    });
                                    setFileList3([]);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="form-group">
                            <div id="status" className="status-options d-flex">
                              <label
                                htmlFor="stutus-point"
                                style={{
                                  marginTop: "-1.5mm",
                                  fontSize: "15pt",
                                  paddingLeft: "10pt",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                สำรองจ่าย
                              </label>
                              <div className="">
                                <input
                                  type="radio"
                                  id="stutus-point"
                                  value="rl_is_giving"
                                  disabled={disable}
                                  checked={empData.reserve === true}
                                  onChange={(e) => {
                                    setEmpData({
                                      ...empData,
                                      reserve: true,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      {empData.reserve === true && (
                        <div>
                          <div className="row">
                            <div className="col-12 col-md-12 col-xl-11">
                              <div
                                className="form-group"
                                style={{ paddingTop: 0, marginBottom: 0 }}
                              >
                                <Select
                                  filterOption={(option, searchText) => {
                                    if (
                                      option.data.label
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase())
                                    ) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  }}
                                  isDisabled={disable}
                                  options={[
                                    ...allEmployee[isLog.bool ? "log" : "data"]
                                      .filter(
                                        (el) =>
                                          ![empData.cus_id].includes(el.emp_id)
                                      )
                                      .map((el) => ({
                                        value: el.emp_id,
                                        label: el.emp_name_th,
                                      })),
                                  ]}
                                  value={
                                    empData.cus_id_2 != null &&
                                    empData.cus_id != empData.cus_id_2
                                      ? {
                                          value: empData.cus_id_2,
                                          label: empData.cus_name_2,
                                        }
                                      : null
                                  }
                                  onChange={(selectedOptions) => {
                                    setEmpData({
                                      ...empData,
                                      cus_id_2: selectedOptions.value || "",
                                      cus_name_2: selectedOptions.label || "",
                                    });
                                  }}
                                />
                                <label style={{ marginTop: "-15px" }}>
                                  ผู้สำรองจ่าย{" "}
                                  <span style={{ color: "red" }}>
                                    {!disable ? " *" : ""}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div>
                            <label>Remark </label>
                            <div
                              className="col-12 col-md-12 col-xl-11"
                              style={{ padding: 0 }}
                            >
                              <textarea
                                type="text"
                                className="form-control"
                                required
                                rows={4}
                                disabled={disable}
                                id="org_reserve_remark"
                                value={empData.org_reserve_remark}
                                onChange={(e) => {
                                  // check_rf_name(e);
                                  setEmpData({
                                    ...empData,
                                    org_reserve_remark: e.target.value,
                                  });
                                  empData.org_reserve_remark = e.target.value;
                                }}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="col-12 col-md-12 col-xl-11"
                              style={{ marginBottom: "10px" }}
                            >
                              <label htmlFor="">
                                ใบเสร็จ
                                <span style={{ color: "red" }}>
                                  {!disable ? " *" : ""}
                                </span>
                              </label>
                              <div
                                style={{
                                  marginTop: `${
                                    mode.mode === "view" ? "-35px" : ""
                                  }`,
                                }}
                              >
                                <Upload
                                  beforeUpload={() => false}
                                  className="custom-upload"
                                  listType="picture"
                                  fileList={fileList3}
                                  onRemove={handleRemove3}
                                  onPreview={handlePreview3}
                                  onChange={handleUploadSuccess3}
                                  accept=".jpg,.png,.jpeg"
                                  maxCount={1}
                                  disabled={disable}
                                  style={{ width: "100%" }}
                                >
                                  {mode.mode != "view" ? (
                                    <ButtonAnt
                                      type="primary"
                                      ref={uploadRef3}
                                      style={{
                                        backgroundColor: "#3579F626",
                                        color: "#3579F6",
                                        width: "100%",
                                      }}
                                      disabled={disable}
                                    >
                                      Choose files
                                    </ButtonAnt>
                                  ) : (
                                    <></>
                                  )}
                                </Upload>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* card-body */}
            </div>
            {/* card */}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Organization_Expenses_AEV;
