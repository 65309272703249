import React, { Component, useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

import axios from "axios";
import DatePicker, { registerLocale } from "react-datepicker";
import Configs from "../../../../config";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../../../Utils/Common";
import { useParams } from "react-router";
import Resizer from "react-image-file-resizer";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import NumberFormat from "react-number-format";
import moment from "moment";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function ManageProductAdd(mode) {
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [select_category, setselect_category] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [supplier_option, setSupplier_option] = useState([]);
  const [supplier_select_data, setSupplier_select_data] = useState([]);
  const { id } = useParams();
  const select_supplier = useRef();
  const [product_data, setproduct_data] = useState({
    product_no: "",
    product_name: "",
    product_detail: "",
    product_deaciplies: "",
    product_category_id: "",
    product_brand: "",
    product_image: "",
    product_image_name: "",
    product_image_path: "",
    product_point: "",
  });

  const [flash_sale_data, setflash_sale_data] = useState({
    product_start_date: null,
    product_end_date: null,
    product_point: "",
    flash_sale: "",
    product_discount: "",
  });

  const [supplier_select, setsupplier_select] = useState({
    id: "",
    supplier: "",
    buy_price: "",
    minimum_order: "",
    lead_time: "",
    mat_cycle_time: "",
    remark: "",
    mode: "",
    index: "",
  });

  function ChangeReactQuill(e, mode) {
    if (mode === "description") {
      product_data.product_deaciplies = e;
    }
  }

  function Add_Suppier() {
    var tempData = supplier_select_data;
    //console.log(tempData)
    setSupplier_select_data([]);
    let temp = {
      supplier_id: supplier_select.id,
      buy_price: supplier_select.buy_price || 0,
      minimum_order: supplier_select.minimum_order || 0,
      lead_time: supplier_select.lead_time || 0,
      mat_cycle_time: supplier_select.mat_cycle_time || 0,
      remark: supplier_select.remark.trim(),
      sort: tempData.length + 1,
      is_active: supplier_select_data.length === 0 ? true : false,
      status: "add",
      create_date: moment(new Date()).format(),
    };
    console.log(temp);

    /*  let check = tempData.filter((e)=>{
           return e.supplier_id === temp.supplier_id && e.buy_price === temp.buy_price;
         });
         if(check.length > 0){
           Swal.fire("Error", "This supplier and buy price has aready!!!", "error");
         }else{ */
    tempData.push(temp);
    tempData.sort((a, b) => b.sort - a.sort);
    //}
    tempData.sort((a, b) => b.is_active - a.is_active);

    setSupplier_select_data(tempData);
    setsupplier_select({
      ...supplier_select,
      id: "",
      buy_price: "",
      minimum_order: "",
      lead_time: "",
      mat_cycle_time: "",
      remark: "",
    });
    supplier_select.id = "";
    supplier_select.buy_price = "";
    supplier_select.minimum_order = "";
    supplier_select.lead_time = "";
    supplier_select.mat_cycle_time = "";
    supplier_select.remark = "";

    select_supplier.current.select.clearValue();
    console.log(tempData);
  }

  function Edit_Suppier() {
    var tempData = supplier_select_data;
    setSupplier_select_data([]);

    //console.log(tempData[supplier_select.index]);
    tempData[supplier_select.index].supplier_id = supplier_select.id;
    tempData[supplier_select.index].buy_price = supplier_select.buy_price || 0;
    tempData[supplier_select.index].minimum_order =
      supplier_select.minimum_order || 0;
    tempData[supplier_select.index].lead_time = supplier_select.lead_time || 0;
    tempData[supplier_select.index].mat_cycle_time =
      supplier_select.mat_cycle_time || 0;
    tempData[supplier_select.index].remark = supplier_select.remark.trim();
    tempData[supplier_select.index].status = "update";
    //console.log(tempData);
    setSupplier_select_data(tempData);
    setsupplier_select({
      ...supplier_select,
      id: "",
      supplier: "",
      buy_price: "",
      mode: "",
      index: "",
      minimum_order: "",
      lead_time: "",
      mat_cycle_time: "",
      remark: "",
    });
    supplier_select.id = "";
    supplier_select.buy_price = "";
    supplier_select.minimum_order = "";
    supplier_select.lead_time = "";
    supplier_select.mat_cycle_time = "";
    supplier_select.remark = "";
    select_supplier.current.select.clearValue();
  }

  function deldata(index) {
    console.log("index", index);
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะลบข้อมูล Flash sale นี้ ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        const temp = flashSaleTable.data;
        setFlashSaleTable({ data: [] });

        temp.splice(index, 1);
        setFlashSaleTable({ data: temp });
        flashSaleTable.data = temp;
      }
    });
  }
  const [modeModelFlashSale, setModeModelFlashSale] = useState({
    mode: "add",
    index: null,
  });
  function editFlashSale(index) {
    console.log("index", index);
    console.log("flashSaleTable.data[index]", flashSaleTable.data[index]);
    setModeModelFlashSale({
      mode: "edit",
      index: index,
    });
    modeModelFlashSale.mode = "edit";
    modeModelFlashSale.index = index;
    setflash_sale_data({
      product_start_date: new Date(
        flashSaleTable.data[index].product_start_date
      ),
      product_end_date: new Date(flashSaleTable.data[index].product_end_date),
      product_point: flashSaleTable.data[index].product_point,
      flash_sale: flashSaleTable.data[index].flash_sale || "",
      product_discount: flashSaleTable.data[index].product_discount,
    });
    flash_sale_data.product_start_date = new Date(
      flashSaleTable.data[index].product_start_date
    );
    flash_sale_data.product_end_date = new Date(
      flashSaleTable.data[index].product_end_date
    );
    flash_sale_data.product_point = flashSaleTable.data[index].product_point;
    flash_sale_data.flash_sale = flashSaleTable.data[index].flash_sale || "";
    flash_sale_data.product_discount =
      flashSaleTable.data[index].product_discount;
  }

  const [product_start_date_null, setproduct_start_date_null] = useState(false);
  const [product_end_date_null, setproduct_end_date_null] = useState(false);

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>

        {/*  <label>test</label> */}
      </div>
    </div>
  );

  const GetManageProductSupplier = async () => {
    var get_category = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_incentive +
        "/api/manageProduct/getManageProductSupplier",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_category,
    })
      .then(function (response) {
        console.log(response.data, "ds");
        setSupplier(response.data.supplier_list);
        const options = [{ value: "", label: "Select All" }];
        response.data.supplier_list.forEach((el) => {
          var element = {};
          element["value"] = el.sup_id;
          element["label"] = el.sup_abbr_name;
          options.push(element);
        });
        setSupplier_option(options);
        // setSupplierList(response.data.supplier_list);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetCategoryData = async () => {
    var get_category = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/manageProduct/productCategory",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_category,
    })
      .then(function (response) {
        console.log(response.data, "ds");
        setselect_category(response.data.category_list);
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(select_category);
  };

  const GetProduct = async () => {
    console.log(id);
    if (mode.mode == "read" || mode.mode == "edit") {
      axios({
        method: "post",
        url: Configs.API_URL_incentive + "/api/manageProduct/getproduct/" + id,
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          console.log(response.data.image_list);
          setproduct_data({
            product_id: response.data.product_list[0].product_id,
            product_no: response.data.product_list[0].product_no,
            product_brand: response.data.product_list[0].product_brand,
            product_deaciplies:
              response.data.product_list[0].product_description,
            product_name: response.data.product_list[0].product_name,
            product_detail: response.data.product_list[0].product_detail,
            product_image_name: response.data.product_list[0].product_image,
            product_image_path:
              response.data.product_list[0].product_image_path,
            product_point: response.data.product_list[0].product_point,
            product_category_id:
              response.data.product_list[0].product_category_id,
          });

          const arrImage = [];
          for (let item of response.data.image_list) {
            // console.log('item', item);
            const tempDataImg = {
              pi_id: item.pi_id,
              sbd_document_name: item.pi_img_name,
              sbd_path_document: item.pi_img_path,
              file_isOld: false,
              is_checked: item.pi_is_profile,
            };
            arrImage.push(tempDataImg);
          }
          setdocumentFile(arrImage);

          const arrModel = [];
          for (let item of response.data.product_model_list) {
            // console.log('item', item);
            const tempDataImg = {
              pm_id: item.pm_id,
              fullName: item.pm_model_name,
            };
            arrModel.push(tempDataImg);
          }
          setInputFields(arrModel);

          const arrFlashSale = [];
          for (let item of response.data.product_flashsale_list) {
            // console.log('item', item);
            const tempDataImg = {
              fs_id: item.fs_id,
              product_start_date: moment(/* .utc */ item.fs_start_date),
              product_end_date: moment(/* .utc */ item.fs_end_date),
              product_point: item.fs_product_point || null,
              flash_sale: item.fs_point, //item.flash_sale,
              product_discount: item.fs_discount,
            };
            arrFlashSale.push(tempDataImg);
          }
          setFlashSaleTable({ data: arrFlashSale });

          const arrSupplier = [];
          for (let item of response.data.product_supplier_list) {
            // console.log('item', item);
            const tempDataImg = {
              srp_id: item.srp_id,
              supplier_id: item.srp_supplier,
              buy_price: item.srp_buy_price || 0,
              minimum_order: item.srp_min_order || 0,
              lead_time: item.srp_lead_time || 0,
              mat_cycle_time: null,
              remark: item.srp_remark || null,
              sort: arrSupplier.length + 1,
              is_active: item.srp_is_active,
              status: "edit",
              create_date: moment(item.srp_created_by).format() || null,
            };
            arrSupplier.push(tempDataImg);
          }
          arrSupplier.sort((a, b) => b.sort - a.sort);
          arrSupplier.sort((a, b) => b.is_active - a.is_active);
          setSupplier_select_data(arrSupplier);

          setTest({ ...test, test: "" });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  useEffect(() => {
    GetProduct();
    GetCategoryData();
    GetManageProductSupplier();
  }, []);

  function alert_null() {
    var error_list = [];
    if (product_data.product_no == "") {
      let temp_err = {
        message: "กรุณากรอก Product No",
      };
      error_list.push(temp_err);
    }

    if (product_data.product_name == "") {
      let temp_err = {
        message: "กรุณากรอก Product Name",
      };
      error_list.push(temp_err);
    }
    if (
      product_data.product_name.match(/[1234567890]/g) != null &&
      product_data.product_name != ""
    ) {
      let temp_err = {
        message: "กรุณากรอก Product Name เป็นตัวอักษรเท่านั้น",
      };
      error_list.push(temp_err);
    }
    if (product_data.product_detail == "") {
      let temp_err = {
        message: "กรุณากรอก Product Detail",
      };
      error_list.push(temp_err);
    }
    if (product_data.product_category_id == "") {
      let temp_err = {
        message: "กรุณาเลือก Product Category",
      };
      error_list.push(temp_err);
    }
    if (product_data.product_point == "") {
      let temp_err = {
        message: "กรุณากรอก Product Point",
      };
      error_list.push(temp_err);
    }
    if (
      product_data.product_point.match(/[1234567890]/g) == null &&
      product_data.product_point != ""
    ) {
      let temp_err = {
        message: "กรุณากรอก Product Point เป็นตัวเลขเท่านั้น",
      };
      error_list.push(temp_err);
    }
    if (product_data.product_point < 0 && product_data.product_point != "") {
      let temp_err = {
        message: "กรุณากรอก Product Point มากกว่า 0",
      };
      error_list.push(temp_err);
    }
    if (product_data.product_image_path == "") {
      let temp_err = {
        message: "กรุณาใส่รูปภาพ",
      };
      error_list.push(temp_err);
    }
    const tempCheck = inputFields.filter((el) => el.fullName.trim() !== "");
    if (tempCheck.length == 0) {
      let temp_err = {
        message: "กรุณาเพิ่ม Model Product อย่างน้อย 1 ตัว",
      };
      error_list.push(temp_err);
    }
    const tempCheckSupplie = supplier_select_data.filter(
      (el) => el.is_active === true
    );
    const tempCheckSupplieDelete = supplier_select_data.filter(
      (el) => el.status === "delete"
    );
    // console.log('supplier_select_data.length', supplier_select_data.length)
    if (
      supplier_select_data.length == 0 ||
      tempCheckSupplieDelete.length === supplier_select_data.length
    ) {
      let temp_err = {
        message: "กรุณาเพิ่ม Supplier อย่างน้อย 1 Supplier",
      };
      error_list.push(temp_err);
    } else {
      if (tempCheckSupplie.length === 0) {
        let temp_err = {
          message: "สถานะ Supplier ต้องเป็น Active อย่างน้อย 1 Supplier",
        };
        error_list.push(temp_err);
      }
    }
    return error_list;
  }

  function save_product_data() {
    const error_list = alert_null();
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      Swal.fire({
        title:
          mode.mode === "add"
            ? "ยืนยันการบันทึกข้อมูล Product นี้ ใช่หรือไม่"
            : "ยืนยันการแก้ไขข้อมูล Product นี้ ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          if (mode.mode === "add") {
            const temp = {
              oem_id: getOem(),
              company_id: getUser().com,
              fup_id: getUser().emp_id,
              product_no: product_data.product_no
                ? product_data.product_no.trim()
                : "",
              product_name: product_data.product_name
                ? product_data.product_name.trim()
                : "",
              product_brand: product_data.product_brand
                ? product_data.product_brand.trim()
                : "",
              product_point: parseInt(
                product_data.product_point.replace(",", "")
              ),
              product_detail: product_data.product_detail
                ? product_data.product_detail.trim()
                : "",
              product_deaciplies: product_data.product_deaciplies
                ? product_data.product_deaciplies.trim()
                : "",
              product_category_id: product_data.product_category_id,
              product_image_name: product_data.product_image_name || null,
              product_image_path: product_data.product_image_path || null,
              product_flash_sale: flashSaleTable.data,
              product_supplier: supplier_select_data,
              product_images: documentFile,
              product_model_detail:
                inputFields.length > 0
                  ? inputFields.filter((el) => el.fullName.trim() !== "")
                  : [],
            };
            // console.log("add", temp);
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1000,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then((result) => {
              axios({
                method: "post",
                url:
                  Configs.API_URL_incentive + "/api/manageProduct/addproduct",
                headers: {
                  Authorization: "Bearer " + getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              })
                .then(function (response) {
                  if (response.data) {
                    Swal.fire({
                      icon: "success",
                      title: "Save",
                      showConfirmButton: false,
                      timer: 1500,
                    }).then((result) => {
                      window.location.href =
                        "/Human_Resource/intensive_TTT/manage_product_system";
                    });
                  }
                })
                .catch(function (error) {
                  // console.log(error.error.errorDis);
                  Swal.fire({
                    icon: "warning",
                    title: "กรุณากรอกข้อมูลใหม่",
                    text: error.response.data.error.errorDis,
                  });
                });
            });
          }
          if (mode.mode === "edit") {
            console.log("supplier_select_data", supplier_select_data);

            const temp = {
              product_id: id,
              oem_id: getOem(),
              company_id: getUser().com,
              fup_id: getUser().emp_id,
              product_no: product_data.product_no
                ? product_data.product_no.trim()
                : "",
              product_name: product_data.product_name
                ? product_data.product_name.trim()
                : "",
              product_brand: product_data.product_brand
                ? product_data.product_brand.trim()
                : "",
              product_point: parseInt(
                product_data.product_point.replace(",", "")
              ),
              product_detail: product_data.product_detail
                ? product_data.product_detail.trim()
                : "",
              product_deaciplies: product_data.product_deaciplies
                ? product_data.product_deaciplies.trim()
                : "",
              product_category_id: product_data.product_category_id,
              product_image_name: product_data.product_image_name || null,
              product_image_path: product_data.product_image_path || null,
              product_flash_sale: flashSaleTable.data,
              product_supplier: supplier_select_data,
              product_images: documentFile,
              product_model_detail:
                inputFields.length > 0
                  ? inputFields.filter((el) => el.fullName.trim() !== "")
                  : [],
            };
            console.log("edit", temp);
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1000,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then((result) => {
              axios({
                method: "post",
                url:
                  Configs.API_URL_incentive + "/api/manageProduct/editproduct",
                headers: {
                  Authorization: "Bearer " + getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              })
                .then(function (response) {
                  if (response.data) {
                    Swal.fire({
                      icon: "success",
                      title: "Save",
                      showConfirmButton: false,
                      timer: 1500,
                    }).then((result) => {
                      window.location.href =
                        "/Human_Resource/intensive_TTT/manage_product_system";
                    });
                  }
                })
                .catch(function (error) {
                  // console.log(error.response.data.error);
                  Swal.fire({
                    icon: "warning",
                    title: "เกิดข้อผิดพลาด",
                    text: error.response.data.error.errorDis,
                  });
                });
            });
          }
        }
      });
    }
  }

  function cancle_add_manage_product(params) {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
          "/Human_Resource/intensive_TTT/manage_product_system";
      }
    });
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        300,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        }
        // "base64"
      );
    });

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  function isFileImage(file) {
    const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];

    return file && acceptedImageTypes.includes(file["type"]);
  }

  async function setproductLogo(e) {
    const file = e.target.files[0];
    const regex = /([\u0E00-\u0E7F]+)/gmu;
    const str = file.name;
    let m = regex.exec(str);
    //console.log("image is :",isFileImage(file));

    if (m !== null || isFileImage(file) === false) {
      //console.log("ชื่อไฟล์ไม่ถูกต้อง");
      Swal.fire("Error", "File name or type are not correct!!!", "error");
    } else {
      const image = await resizeFile(file);

      var file_image = dataURLtoFile(image, file.name);
      var data = new FormData();
      data.append("Profile", file_image);
      data.append("typeFile", product_data.product_name);
      data.append("uniqueKey", getOem());

      var config_uploadProfile = {
        method: "post",
        url: Configs.API_URL_incentive + "/api/upload/profile",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config_uploadProfile)
        .then(function (response) {
          if (response.data.status) {
            console.log("test photo", response.data.data.path);
            setproduct_data({
              ...product_data,
              product_image_name: response.data.data.orgin_name,
              product_image_path: response.data.data.path,
              product_image: response.data.data,
            });
            console.log(product_data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    console.log(product_data);
  }

  async function check_start_date(e) {
    if (e === "") {
      setflash_sale_data({
        ...flash_sale_data,
        product_start_date: e,
      });
      setproduct_start_date_null(true);
    } else if (
      product_data.product_end_date != "" &&
      e > product_data.product_end_date
    ) {
      setproduct_data({
        ...product_data,
        product_end_date: "",
      });
      product_data.product_end_date = "";
      setproduct_data({
        ...product_data,
        product_start_date: e,
      });
      setproduct_start_date_null(false);
    } else {
      setproduct_data({
        ...product_data,
        product_start_date: e,
      });
      setproduct_start_date_null(false);
    }
  }

  async function check_end_date(e) {
    if (e === "") {
      setproduct_data({
        ...product_data,
        product_end_date: e,
      });
      setproduct_end_date_null(true);
    } else {
      setproduct_data({
        ...product_data,
        product_end_date: e,
      });
      setproduct_end_date_null(false);
    }
  }
  const [flashSaleTable, setFlashSaleTable] = useState({
    data: [],
  });

  function isBetweenDates(dateToCheck, dateToCheckE, startDate, endDate) {
    dateToCheck = new Date(dateToCheck);
    const temp = flashSaleTable.data.find((el, index) => {
      const dataStart =
        dateToCheck >= new Date(el.product_start_date) &&
        dateToCheck <= new Date(el.product_end_date);
      const dataEnd =
        dateToCheckE >= new Date(el.product_start_date) &&
        dateToCheckE <= new Date(el.product_end_date);
      if (modeModelFlashSale.mode === "edit") {
        if (modeModelFlashSale.index !== index) {
          if (dataStart) {
            return el;
          }
          if (dataEnd) {
            return el;
          }
        }
      } else {
        if (dataStart) {
          return el;
        }
        if (dataEnd) {
          return el;
        }
      }
    });
    if (temp) {
      return true;
    } else {
      return false;
    }
  }

  function checkErrorFlashSale() {
    const error_list = [];
    if (flash_sale_data.product_start_date === null) {
      // flash_sale_data.
      let temp_err = {
        message: "กรุณาเลือกวันเริ่มต้น Flash sale",
      };
      error_list.push(temp_err);
    }

    if (flash_sale_data.product_end_date === null) {
      // flash_sale_data.
      let temp_err = {
        message: "กรุณาเลือกวันสิ้นสุด Flash sale",
      };
      error_list.push(temp_err);
    }

    if (flash_sale_data.flash_sale === "") {
      // flash_sale_data.
      let temp_err = {
        message: "กรุณากรอกราคา Flash sale",
      };
      error_list.push(temp_err);
    }

    if (flash_sale_data.product_discount === "") {
      // flash_sale_data.
      let temp_err = {
        message: "กรุณากรอก ราคาส่วนลด",
      };
      error_list.push(temp_err);
    }

    if (
      isBetweenDates(
        flash_sale_data.product_start_date,
        flash_sale_data.product_end_date
      )
    ) {
      let temp_err = {
        message: "Product นี้ มี Flash sale ในช่วงเวลานี้ อยู่แล้ว",
      };
      error_list.push(temp_err);
    }
    /* const productPoint = product_data.product_point ? parseInt(product_data.product_point.replace(",", "")) : 0;
        const flashPoint = flash_sale_data.flash_sale ? parseInt(flash_sale_data.flash_sale) : 0;
        const productDis = flash_sale_data.product_discount ? parseInt(flash_sale_data.product_discount.replace(",", "")) : 0;
        console.log('productPoint, flashPoint', productPoint, flashPoint, flash_sale_data.product_discount);
        if (productDis > 100) {
            // flash_sale_data.
            let temp_err = {
                message: "Product discount ต้องไม่เกิน 100 %",
            };
            error_list.push(temp_err);
        }

        if (flashPoint > productPoint) {
            // flash_sale_data.
            let temp_err = {
                message: "Flash sale point ต้องไม่มากกว่า Product point",
            };
            error_list.push(temp_err);
        } */
    return error_list;
  }

  // ฟังก์ชันสำหรับจัดรูปแบบวันที่
  const formatDate = (date) => {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD");
  };

  async function addProductFlashSale() {
    console.log("flash_sale_data", flash_sale_data);
    const error_list = checkErrorFlashSale();
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
      return;
    }
    const temp = {
      product_start_date: formatDate(flash_sale_data.product_start_date), //moment(flash_sale_data.product_start_date).format("DD-MM-yyyy"),
      product_end_date: formatDate(flash_sale_data.product_end_date), //moment(flash_sale_data.product_end_date).format("DD-MM-yyyy"),
      product_point: product_data.product_point,
      flash_sale: flash_sale_data.flash_sale,
      product_discount: flash_sale_data.product_discount,
    };
    const arrLis = flashSaleTable.data;
    setFlashSaleTable([]);
    if (modeModelFlashSale.mode === "edit") {
      arrLis[modeModelFlashSale.index].product_start_date =
        temp.product_start_date;
      arrLis[modeModelFlashSale.index].product_end_date = temp.product_end_date;
      arrLis[modeModelFlashSale.index].product_point = temp.product_point;
      arrLis[modeModelFlashSale.index].flash_sale = temp.flash_sale;
      arrLis[modeModelFlashSale.index].product_discount = temp.product_discount;
      // arrLis.push(temp);
      setFlashSaleTable({ ...data, data: arrLis });
      flashSaleTable.data = arrLis;
      setTest({ ...test, test: "" });
    } else {
      console.log(
        'moment(flash_sale_data.product_start_date).format("dd-MM-yyyy")',
        moment(flash_sale_data.product_start_date).format("DD-MM-yyyy")
      );
      flash_sale_data.product_start_date = flash_sale_data.product_start_date; //moment(flash_sale_data.product_start_date).format("DD-MM-yyyy");
      flash_sale_data.product_end_date = flash_sale_data.product_end_date; //moment(flash_sale_data.product_end_date).format("DD-MM-yyyy");
      arrLis.push(temp);
      setFlashSaleTable({ ...data, data: arrLis });
      flashSaleTable.data = arrLis;
    }
    setflash_sale_data({
      product_start_date: null,
      product_end_date: null,
      product_point: "",
      flash_sale: "",
      product_discount: "",
    });
    clickCloseModalFlashSale();
  }
  const closeModalFlashSale = useRef();
  const clickCloseModalFlashSale = () => {
    closeModalFlashSale.current.click();
  };

  const row_data = [];
  for (let index = 0; index < flashSaleTable.data.length; index++) {
    const element = {};
    element["no"] = index + 1;
    element["point"] = (
      <div style={{ textAlign: "end" }}>
        {numberWithCommas(flashSaleTable.data[index].product_point)}
      </div>
    );
    element["flash-sale"] = (
      <div style={{ textAlign: "end" }}>
        {numberWithCommas(flashSaleTable.data[index].flash_sale)}
      </div>
    );
    const start_date = moment(
      /* .utc */ new Date(flashSaleTable.data[index].product_start_date)
    ).format("DD-MM-YYYY");
    const end_date = moment(
      /* .utc */ new Date(flashSaleTable.data[index].product_end_date)
    ).format("DD-MM-YYYY");
    // console.log('flashSaleTable.data[index].product_start_date :>> ', flashSaleTable.data[index].product_start_date);
    element["std"] = start_date;
    element["ed"] = end_date;
    element["mgt"] = (
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            // href={"/Human_Resource/incentive_hr_admin/thank_point/edit/" + thank_point[index].tp_id}
            id={"btn_edit" + (index + 1)}
            key={flashSaleTable.data[index].product_start_date}
            className=" btn btn-xs "
            data-toggle="modal"
            data-target="#modal-add-flash-sale"
            hidden={disable}
            data-backdrop="static"
            data-keyboard="false"
            onClick={editFlashSale.bind(this, index)}
          >
            {"   "}
            <i class="fas fa-pencil-alt"></i>
            {"   "}
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={flashSaleTable.data[index].product_start_date}
            id={"btn_delete" + (index + 1)}
            hidden={disable}
            className=" btn btn-xs "
            onClick={deldata.bind(this, index)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    row_data.push(element);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Point",
        field: "point",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Point Flash Sale",
        field: "flash-sale",
        sort: "asc",
        width: 50,
      },
      {
        label: "Start Date",
        field: "std",
        sort: "asc",
        width: 50,
      },
      {
        label: "End Date",
        field: "ed",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 80,
      },
    ],
    rows: row_data,
  };

  function setSupplier_active(supplier, e) {
    let index = e.target.id;
    let tempData = supplier_select_data;
    // console.log('tempData[index].is_active', tempData[index].is_active);
    const activeStatus = tempData[index].is_active;
    // console.log('activeStatus', !activeStatus);
    tempData.forEach((el, indexFor) => {
      if (indexFor !== index) el.is_active = false;
    });
    // console.log('supplier', supplier, supplier.is_active);
    setSupplier_select_data([]);
    setTest({ ...test, test: "" });
    tempData[index].is_active = !activeStatus;
    tempData[index].status = "update";
    tempData.sort((a, b) => b.sort - a.sort);
    tempData.sort((a, b) => b.is_active - a.is_active);
    // console.log('tempData', tempData);
    setSupplier_select_data(tempData);
  }

  function Remove_Supplier(e) {
    const indexToRemove = parseInt(e.target.id);

    const newData = supplier_select_data.filter(
      (_, index) => index !== indexToRemove
    );
    setSupplier_select_data(newData);

    setsupplier_select({
      ...supplier_select,
      id: "",
      supplier: "",
      buy_price: "",
      mode: "",
      index: "",
      minimum_order: "",
      lead_time: "",
      remark: "",
    });

    select_supplier.current.select.clearValue();
  }

  function form_supplier(
    supplier_name,
    buy_price,
    minimum_order,
    lead_time,
    mat_cycle_time,
    remark,
    mode,
    index
  ) {
    console.log(
      "lead",
      supplier_name,
      buy_price,
      minimum_order,
      lead_time,
      mat_cycle_time,
      remark,
      mode,
      index
    );
    console.log(supplier_select);

    let id = supplier.filter((e) => {
      return e.sup_abbr_name === supplier_name;
    });

    setsupplier_select({
      ...supplier_select,
      id: id[0].sup_id,
      supplier: supplier_name,
      buy_price: buy_price,
      mode: mode,
      index: index,
      minimum_order: minimum_order,
      lead_time: lead_time,
      mat_cycle_time: mat_cycle_time,
      remark: remark,
      status: "update",
    });
    // supplier_select.id = id[0].sup_id;
    // supplier_select.supplier = supplier_name;
    // console.log("supplier_select", supplier_select);

    setTest({ ...test, test: "" });
  }

  function numberWithCommas(x) {
    return (
      <NumberFormat
        thousandSeparator={true}
        value={x || 0}
        displayType="text"
      />
    );
    /*  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
  }

  var rowsSupplier = [];

  for (var index = 0; index < supplier_select_data.length; index++) {
    //console.log(customer_product_data)
    const rowItem = {};
    if (supplier_select_data[index].status !== "delete") {
      rowItem["no"] = index + 1;
      let sup_name = supplier.filter((e) => {
        return e.sup_id === supplier_select_data[index].supplier_id;
      });
      var name = "";
      if (sup_name.length > 0) {
        name = sup_name[0].sup_abbr_name;
      }

      rowItem["supplier"] = name;
      rowItem["buy_price"] = (
        <div style={{ textAlign: "end" }}>
          {numberWithCommas(supplier_select_data[index].buy_price)}
        </div>
      );
      rowItem["minimum_order"] = (
        <div style={{ textAlign: "end" }}>
          {numberWithCommas(supplier_select_data[index].minimum_order)}
        </div>
      );
      rowItem["lead_time"] = (
        <div style={{ textAlign: "end" }}>
          {supplier_select_data[index].lead_time}
        </div>
      );
      rowItem["mat_cycle_time"] = supplier_select_data[index].mat_cycle_time;
      rowItem["remark"] = supplier_select_data[index].remark;

      var date = moment(supplier_select_data[index].updated_date).format(
        "DD/MM/yyyy :: HH:mm น."
      );
      //}
      //console.log(supplier_select_data[index].create_date)
      rowItem["create_date"] = date;
      rowItem["status"] = (
        <>
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                id={index}
                disabled={disable}
                onClick={setSupplier_active.bind(
                  this,
                  supplier_select_data[index]
                )}
                class={
                  supplier_select_data[index].is_active === true
                    ? "btn btn-block btn-success "
                    : "btn btn-block btn-danger "
                }
              >
                {supplier_select_data[index].is_active === true
                  ? "ACTIVE"
                  : "NO ACTIVE"}
              </button>
            </div>
          </div>
        </>
      );
      rowItem["mgt"] = (
        <>
          {supplier_select_data[index].is_active === false && !disable ? (
            <>
              <div className="row">
                <div className="col-6">
                  <a
                    data-toggle="modal"
                    data-target="#modal-add-supplier"
                    onClick={form_supplier.bind(
                      this,
                      name,
                      supplier_select_data[index].buy_price,
                      supplier_select_data[index].minimum_order || "",
                      supplier_select_data[index].lead_time || "",
                      supplier_select_data[index].mat_cycle_time || "",
                      supplier_select_data[index].remark || "",
                      "edit",
                      index,
                      supplier_select_data[index]
                    )}
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                </div>

                <div className="col-6">
                  <i
                    id={index}
                    onClick={Remove_Supplier}
                    className="fa fa-trash  icon-sm"
                  ></i>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      );
    }

    rowsSupplier.push(rowItem);
  }

  const looptabel = [];
  looptabel.push(
    {
      label: "No",
      field: "no",
      sort: "asc",
      width: 50,
    },
    {
      label: "Supplier",
      field: "supplier",
      sort: "asc",
      width: 50,
    },

    {
      label: "Buy Price",
      field: "buy_price",
      sort: "asc",
      width: 50,
    },

    {
      label: "Minimum Order",
      field: "minimum_order",
      sort: "asc",
      width: 50,
    },
    {
      label: "Lead Time (Days)",
      field: "lead_time",
      sort: "asc",
      width: 50,
    }
  );

  looptabel.push(
    {
      label: "Remark ",
      field: "remark",
      sort: "asc",
      width: 50,
    },
    {
      label: "Date",
      field: "create_date",
      sort: "asc",
      width: 50,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 50,
    },
    {
      label: "MGT",
      field: "mgt",
      sort: "asc",
      width: 50,
    }
  );
  const dataSupplier = {
    columns: looptabel,
    rows: rowsSupplier,
  };

  const [inputFields, setInputFields] = useState([]);
  let num = 0;

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        fullName: "",
      },
    ]);
    num++;
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
    num--;
  };
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };
  const [documentFile, setdocumentFile] = useState([]);

  function isFileImage(file) {
    const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];

    return file && acceptedImageTypes.includes(file["type"]);
  }
  const [test, setTest] = useState({
    test: "",
  });
  async function encodeImage(file) {
    console.log("file", file);

    if (file[0].name) {
      const regex = /([\u0E00-\u0E7Fก-๙]+)/gmu;
      const str = file[0].name;
      const m = regex.exec(str);

      if (isFileImage(file[0]) === false) {
        Swal.fire({
          title: "ประเภทไฟล์ไม่ถูกต้อง",
          text: "",
          icon: "warning",
          confirmButtonColor: "#032973",
          confirmButtonText: "ตกลง",
        });
      } else {
        const oleTempFileUpload = documentFile;
        setdocumentFile([]);

        const tempFileUpload = [];
        if (file.length > 0) {
          for (let index = 0; index < file.length; index += 1) {
            const dataImg = new FormData();
            dataImg.append("Profile", file[index]);
            dataImg.append("typeFile", product_data.product_no);
            dataImg.append("uniqueKey", getOem());

            const tempDataImg = {
              sbd_document_name: file[index].name,
              sbd_path_document: "",
              file_isOld: false,
            };
            axios({
              method: "post",
              url: Configs.API_URL_incentive + "/api/upload/profile",
              headers: {
                Authorization: getToken(),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
              },
              data: dataImg,
            })
              .then(function (response) {
                if (response.data.status) {
                  console.log("response.data.data", response.data.data);
                  tempDataImg.sbd_path_document = response.data.data.path;
                  tempDataImg.is_checked = false;
                  // tempDataImg.sbd_name_document = response.data.data.orgin_name
                  if (
                    product_data.product_image_path === "" ||
                    product_data.product_image_path === null ||
                    product_data.product_image_path === undefined
                  ) {
                    tempDataImg.is_checked = true;
                    setproduct_data({
                      ...product_data,
                      product_image_name: tempDataImg.sbd_document_name,
                      product_image_path: response.data.data.path,
                      product_image: "",
                    });
                    product_data.product_image_name =
                      tempDataImg.sbd_document_name;
                    product_data.product_image_path = response.data.data.path;
                  }
                }
              })
              .catch(function (error) {
                console.log(error);
              });

            tempFileUpload.push(tempDataImg);
          }
        }

        oleTempFileUpload.forEach((el) => {
          tempFileUpload.push(el);
        });
        // console.log('tempFileUpload', tempFileUpload[0],tempFileUpload[0].sbd_document_name, tempFileUpload[0].sbd_path_document);
        setdocumentFile(tempFileUpload);
        setTest({ ...test, test: "" });
      }
    }
  }

  async function checkedSelectBox(index) {
    console.log("index", index);
    const oleTempFileUpload = documentFile;
    setdocumentFile([]);
    oleTempFileUpload.map((el) => (el.is_checked = false));
    oleTempFileUpload[index].is_checked = true;
    setproduct_data({
      ...product_data,
      product_image_name: oleTempFileUpload[index].sbd_document_name,
      product_image_path: oleTempFileUpload[index].sbd_path_document,
      product_image: oleTempFileUpload[index],
    });
    product_data.product_image_name =
      oleTempFileUpload[index].sbd_document_name;
    product_data.product_image_path =
      oleTempFileUpload[index].sbd_path_document;
    product_data.product_image = oleTempFileUpload[index];
    setdocumentFile(oleTempFileUpload);
    setTest({ ...test, test: "" });
  }

  async function delDocList(index) {
    console.log("index", index);
    const oleTempFileUpload = documentFile;
    setdocumentFile([]);
    const check = oleTempFileUpload.splice(index, 1);
    check.forEach((el) => {
      if (el.sbd_path_document === product_data.product_image_path) {
        setproduct_data({
          ...product_data,
          product_image_name: "",
          product_image_path: "",
          product_image: "",
        });
        product_data.product_image_name = "";
        product_data.product_image_path = "";
        product_data.product_image = "";
      }
    });
    if (oleTempFileUpload.length !== 0) {
      setdocumentFile(oleTempFileUpload);
      setTest({ ...test, test: "" });
    }
  }

  const uploadRef = useRef(null);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Manage Product {pageMode}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Manage Product</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={cancle_add_manage_product}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={save_product_data}
                    class="btn btn-block btn-success"
                    id="btn-save"
                  >
                    Save
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={cancle_add_manage_product}
                    class="btn btn-block btn-danger"
                    id="btn-cancle"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-md-4 col-sm-10 col-9">
                <h4>Product No: {product_data.product_no}</h4>
              </div>
              <div className="col-xl-3 col-md-4 col-sm-10 col-9">
                <h4>Product Name: {product_data.product_name}</h4>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div class="card card-primary card-outline card-outline-tabs">
            <div class="card-header p-0 border-bottom-0">
              <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                <li class="nav-item active">
                  <a
                    class="nav-link active"
                    id="custom-tabs-basic-information-tab"
                    data-toggle="pill"
                    href="#custom-tabs-basic-information"
                    role="tab"
                    aria-controls="custom-tabs-basic-information"
                    aria-selected="true"
                  >
                    Basic Information
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="custom-tabs-advance-information-tab"
                    data-toggle="pill"
                    href="#custom-tabs-advance-information"
                    role="tab"
                    aria-controls="custom-tabs-advance-information"
                    aria-selected="false"
                  >
                    Advance Information
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="custom-tabs-flash-sale-tab"
                    data-toggle="pill"
                    href="#custom-tabs-flash-sale"
                    role="tab"
                    aria-controls="custom-tabs-flash-sale"
                    aria-selected="false"
                  >
                    Flash Sale
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="custom-tabs-supplier-tab"
                    data-toggle="pill"
                    href="#custom-tabs-supplier"
                    role="tab"
                    aria-controls="custom-tabs-supplier"
                    aria-selected="false"
                  >
                    Supplier
                  </a>
                </li>
              </ul>
            </div>
            <div class="card-body">
              <div class="tab-content" id="custom-tabs-four-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="custom-tabs-basic-information"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-basic-information"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <div className="row">
                          <div className="col-xl-6 col-md-7 col-sm-10 col-10">
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control"
                                id="text_product_no"
                                required="false"
                                disabled={disable}
                                value={product_data.product_no}
                                onChange={(e) => {
                                  setproduct_data({
                                    ...product_data,
                                    product_no: e.target.value,
                                  });
                                }}
                              />
                              <label htmlFor="">
                                Product No.
                                {product_data.product_no === "" ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>{" "}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-md-7 col-sm-10 col-10">
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control"
                                id="text_product_name"
                                required="false"
                                disabled={disable}
                                value={product_data.product_name}
                                onChange={(e) => {
                                  setproduct_data({
                                    ...product_data,
                                    product_name: e.target.value,
                                  });
                                }}
                              />
                              <label htmlFor="">
                                Product Name
                                {product_data.product_name === "" ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>{" "}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-md-7 col-sm-10 col-10">
                            <div class="form-group">
                              <select
                                className="form-control custom-select select2"
                                type="text"
                                required
                                id="select_category"
                                disabled={disable}
                                value={product_data.product_category_id}
                                onChange={(e) => {
                                  setproduct_data({
                                    ...product_data,
                                    product_category_id: e.target.value,
                                  });
                                }}
                              >
                                <option value="" disabled selected>
                                  Select...
                                </option>
                                {select_category.map((el) => {
                                  return (
                                    <option value={el.category_id}>
                                      {el.category_name}
                                    </option>
                                  );
                                })}
                              </select>
                              <label htmlFor="">
                                Product Category
                                {product_data.product_category_id === "" ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="custom-tabs-advance-information"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-advance-information"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div
                        className="col-xl-4 col-md-3 col-sm-10 col-12 mr-1"
                        style={{}}
                      >
                        <label htmlFor="exampleInputFile"> </label>
                        <img
                          id="img"
                          alt="..."
                          className="img-fluid rounded shadow border-0"
                          src={
                            product_data.product_image_path !== "" &&
                            product_data.product_image_path !== null &&
                            product_data.product_image_path !== undefined
                              ? Configs.API_URL_IMG_incentive +
                                product_data.product_image_path
                              : userdefault_img.imgs
                          }
                          style={{
                            width: "300px",
                            height: "200px",
                            position: "relative",
                          }}
                        />
                        <br />
                        <span style={{ color: "red", fontSize: "15px" }}>
                          Recommend Size:400x300px{" "}
                        </span>
                      </div>
                      <div className="col-xl-4 col-md-4 col-sm-10 col-12">
                        <div className="row">
                          <label htmlFor="exampleInputFile">
                            Part Image{" "}
                            <span style={{ color: "red" }}>
                              size(400 x 300) px
                            </span>
                            {product_data.product_image_path === "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                style={{ display: "none" }}
                                type="file"
                                id="exampleInputInvoid"
                                accept="*"
                                multiple
                                disabled={disable}
                                ref={uploadRef}
                                name="customFile"
                                onChange={(e) => encodeImage(e.target.files)}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="exampleInputFile"
                                onClick={() => uploadRef.current.click()}
                              >
                                {"Select Image"}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-5">
                          <div className="row">
                            <div
                              className="col-12"
                              style={{
                                display: "flex",
                                position: "relative",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {" "}
                            </div>
                            <div className="col-12">
                              <table
                                className="table-responsive"
                                style={{ width: "100%" }}
                              >
                                <thead>
                                  <th
                                    style={{
                                      border: "2px solid #032973",
                                      textAlign: "center",
                                      padding: "5px",
                                    }}
                                  >
                                    เลือก
                                  </th>
                                  <th
                                    style={{
                                      border: "2px solid #032973",
                                      textAlign: "center",
                                      padding: "5px",
                                    }}
                                  >
                                    ลำดับ
                                  </th>
                                  <th
                                    style={{
                                      border: "2px solid #032973",
                                      textAlign: "center",
                                      padding: "5px",
                                    }}
                                  >
                                    เอกสาร
                                  </th>
                                  <th
                                    style={{
                                      border: "2px solid #032973",
                                      textAlign: "center",
                                      padding: "5px",
                                    }}
                                  >
                                    จัดการ
                                  </th>
                                </thead>
                                <tbody>
                                  {documentFile.map((el, i) => {
                                    // console.log('el', el);
                                    return (
                                      <tr key={el.sbd_document_name}>
                                        <td
                                          style={{
                                            border: "2px solid #032973",
                                            textAlign: "center",
                                            padding: "5px",
                                          }}
                                        >
                                          <input
                                            // className="form-check-input"
                                            type="checkbox"
                                            id={el.sbd_path_document}
                                            name={el.sbd_path_document}
                                            value={el.sbd_path_document}
                                            disabled={disable}
                                            // hidden={disable}
                                            checked={el.is_checked}
                                            onClick={(e) => {
                                              checkedSelectBox(i);
                                            }}
                                          />
                                        </td>
                                        <td
                                          style={{
                                            border: "2px solid #032973",
                                            textAlign: "center",
                                            padding: "5px",
                                          }}
                                        >
                                          {i + 1}
                                        </td>
                                        <td
                                          style={{
                                            border: "2px solid #032973",
                                            padding: "5px",
                                          }}
                                        >
                                          <a
                                            href={`${Configs.API_URL_IMG_incentive}${el.sbd_path_document}`}
                                          >
                                            {el.sbd_document_name}
                                          </a>
                                        </td>
                                        <td
                                          style={{
                                            border: "2px solid #032973",
                                            textAlign: "center",
                                            padding: "5px",
                                          }}
                                        >
                                          <a
                                            key={`${el.sbd_document_name}del`}
                                            className="simple-icon-trash button-color black-color"
                                            style={{ borderRadius: "10px" }}
                                            disabled={disable}
                                            hidden={disable}
                                            onClick={() => {
                                              delDocList(i);
                                            }}
                                          >
                                            <i className="fas fa-trash-alt" />
                                            {/* {' '} */}
                                          </a>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-6 col-md-8 col-sm-10 col-10">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="text_product_name_detail"
                            required="false"
                            disabled={disable}
                            value={product_data.product_detail}
                            onChange={(e) => {
                              setproduct_data({
                                ...product_data,
                                product_detail: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">
                            Product Name Detail
                            {product_data.product_detail === "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>{" "}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="text_product_brand"
                            required="false"
                            disabled={disable}
                            value={product_data.product_brand}
                            onChange={(e) => {
                              setproduct_data({
                                ...product_data,
                                product_brand: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product Brand</label>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-7 col-md-8 col-sm-12 col-12">
                        <div className="form-group ">
                          <h5>Product Description</h5>
                          <ReactQuill
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, false] }],
                                [
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strike",
                                  "blockquote",
                                ],
                                [
                                  { list: "ordered" },
                                  { list: "bullet" },
                                  { indent: "-1" },
                                  { indent: "+1" },
                                ],
                                ["link"],
                                ["clean"],
                              ],
                            }}
                            theme="snow"
                            value={product_data.product_deaciplies}
                            readOnly={disable}
                            onChange={(e) => {
                              /* setproduct_data({
                                                                ...product_data,
                                                                product_deaciplies: e.target.value,
                                                            }); */
                              ChangeReactQuill(e, "description");
                            }}
                          ></ReactQuill>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-6 col-md-8 col-sm-10 col-10">
                        <div className="form-group ">
                          <NumberFormat
                            type="text"
                            className="form-control"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            allowNegative={false}
                            id="text_product_name"
                            required="false"
                            disabled={disable}
                            value={product_data.product_point}
                            onChange={(e) => {
                              setproduct_data({
                                ...product_data,
                                product_point: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">
                            Product Point
                            {product_data.product_point === "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-3 col-md-3 col-sm-6 col-6">
                        <div className="form-group ">
                          <button
                            type="button"
                            id="btn_add"
                            disabled={disable}
                            className="btn btn-block btn-primary"
                            onClick={addInputField}
                          >
                            Add Product Model
                          </button>
                        </div>
                      </div>
                    </div>
                    {inputFields.map((data, index) => {
                      const { fullName, emailAddress, salary } = data;
                      return (
                        <div className="row" key={index}>
                          <div className="col-xl-4 col-md-7 col-sm-10 col-6">
                            <div className="form-group">
                              <input
                                type="text"
                                disabled={disable}
                                onChange={(evnt) => handleChange(index, evnt)}
                                value={fullName}
                                name="fullName"
                                className="form-control"
                              />
                              <label htmlFor="">รายละเอียดสินค้า</label>{" "}
                            </div>
                          </div>

                          <div
                            className="col-6 col-md-4 col-xl-2"
                            style={{ width: "100%" }}
                          >
                            {num !== 1 ? (
                              <div
                                className="col-xl-3 col-md-4 col-sm-10 col-9 form-group"
                                style={{ width: "100%", maxWidth: "none" }}
                              >
                                <button
                                  type="button"
                                  hidden={disable}
                                  onClick={(e) => removeInputFields(index)}
                                  class="btn btn-block btn-danger"
                                  id="btn-cancle"
                                >
                                  Remove
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}

                    {/* <div className="row">
                                            <div className="col-xl-3 col-md-5 col-sm-10 col-12">




                                            </div>

                                        </div> */}
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="custom-tabs-flash-sale"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-flash-sale"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-8 col-md-4 col-xl-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            id="btn_add"
                            className="btn btn-block btn-primary"
                            data-toggle="modal"
                            data-target="#modal-add-flash-sale"
                            data-backdrop="static"
                            data-keyboard="false"
                            disabled={disable}
                            onClick={() => {
                              setflash_sale_data({
                                ...flash_sale_data,
                                product_start_date: null,
                                product_end_date: null,
                                product_point: product_data.product_point || 0,
                                flash_sale: "",
                                product_discount: "",
                              });
                              flash_sale_data.product_start_date = null;
                              flash_sale_data.product_end_date = null;
                              flash_sale_data.product_point =
                                product_data.product_point || 0;
                              flash_sale_data.flash_sale = "";
                              flash_sale_data.product_discount = "";
                              setModeModelFlashSale({
                                mode: "add",
                                index: null,
                              });
                              modeModelFlashSale.mode = "add";
                              modeModelFlashSale.index = null;
                            }}
                          >
                            Add Flash Sale
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section className="content">
                    <div
                      className="table-responsive "
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <MDBDataTable
                        sortable={false}
                        className="table table-head-fixed"
                        striped
                        bordered
                        hover
                        fixedHeader
                        data={data}
                      />
                    </div>
                  </section>
                </div>
                <div
                  class="tab-pane fade"
                  id="custom-tabs-supplier"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-supplier"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-8 col-md-4 col-xl-3">
                        <div className="form-group ">
                          <button
                            type="button"
                            id="btn_add"
                            className="btn btn-block btn-primary"
                            data-toggle="modal"
                            data-target="#modal-add-supplier"
                            data-backdrop="static"
                            data-keyboard="false"
                            disabled={disable}
                            onClick={() => {
                              setsupplier_select({
                                ...supplier_select,
                                id: "",
                                supplier: "",
                                buy_price: "",
                                mode: "add",
                                index: "",
                                minimum_order: "",
                                lead_time: "",
                                remark: "",
                              });
                            }}
                          >
                            Add Relationship Supplier
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section className="content">
                    <div
                      className="table-responsive "
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <MDBDataTable
                        sortable={false}
                        className="table table-head-fixed"
                        striped
                        bordered
                        hover
                        fixedHeader
                        data={dataSupplier}
                      />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer"></div>
        </section>
        <div className="modal fade" id="modal-add-flash-sale">
          <div className="modal-dialog modal-ml">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Flash Sale</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  ref={closeModalFlashSale}
                  disabled={disable}
                  aria-label="Close"
                  onClick={async () => {
                    console.log(
                      "product_data.product_point",
                      product_data.product_point
                    );
                    setflash_sale_data({
                      ...flash_sale_data,
                      product_start_date: null,
                      product_end_date: null,
                      product_point: product_data.product_point || 0,
                    });
                    flash_sale_data.product_start_date = null;
                    flash_sale_data.product_end_date = null;
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body divCenter">
                <div className="container-fluid">
                  <div className="row ml-1 mr-1">
                    <div className="col-12">
                      <div className="ml-1">
                        <div
                          className="row"
                          style={{ "margin-bottom": "1rem" }}
                        >
                          <div className="ml-1" style={{ textAlign: "right" }}>
                            <label className="mt-3" htmlFor="">
                              Start Date
                              {flash_sale_data.product_start_date === null ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )}
                            </label>
                          </div>
                          <div className="mt-2 ml-2">
                            <DatePicker
                              style={{ "z-index": "99" }}
                              selected={flash_sale_data.product_start_date}
                              disabled={disable}
                              locale="th"
                              id="start_date"
                              dateFormat={"dd-MM-yyyy"}
                              onChange={async (date) => {
                                setflash_sale_data({
                                  ...flash_sale_data,
                                  product_start_date: date,
                                });
                                // check_start_date(date);
                              }}
                              minDate={new Date()}
                              maxDate={flash_sale_data.product_end_date}
                              showYearDropdown
                              showMonthDropdown
                              customInput={<CustomInput />}
                            ></DatePicker>
                            <div className="ml-1"></div>
                          </div>
                        </div>

                        <div
                          className="row"
                          style={{ "margin-bottom": "1rem" }}
                        >
                          <div className="ml-1" style={{ textAlign: "right" }}>
                            <label className="mt-3" htmlFor="">
                              End Date
                              {flash_sale_data.product_end_date === null ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )}
                            </label>
                          </div>
                          <div className="mt-2 ml-3">
                            <DatePicker
                              style={{ "z-index": "99" }}
                              selected={flash_sale_data.product_end_date}
                              disabled={
                                !disable
                                  ? flash_sale_data.product_start_date !== null
                                    ? false
                                    : true
                                  : false
                              }
                              id="end_date"
                              dateFormat={"dd-MM-yyyy"}
                              onChange={async (date) => {
                                setflash_sale_data({
                                  ...flash_sale_data,
                                  product_end_date: date,
                                });
                                // check_end_date(date);
                              }}
                              minDate={flash_sale_data.product_start_date}
                              showYearDropdown
                              showMonthDropdown
                              customInput={<CustomInput />}
                            ></DatePicker>
                            <div className="ml-1"></div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          id="text_category_name"
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          allowNegative={false}
                          required="false"
                          disabled={true}
                          value={product_data.product_point}
                          onChange={(e) => {
                            setflash_sale_data({
                              ...flash_sale_data,
                              product_point: product_data.product_point,
                            });
                          }}
                        />
                        <label htmlFor="">
                          Product Point
                          {flash_sale_data.product_point === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>{" "}
                      </div>
                      <div className="form-group ">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          id="text_category_name1"
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          decimalScale={2}
                          allowNegative={false}
                          disabled={disable}
                          required="false"
                          value={flash_sale_data.flash_sale}
                          onChange={(e) => {
                            const p_point = product_data.product_point
                              ? parseInt(
                                  product_data.product_point.replace(",", "")
                                )
                              : 0;
                            let p_discount = parseFloat(
                              e.target.value.replace(",", "")
                            );
                            if (p_discount > p_point) {
                              Swal.fire(
                                "Warning",
                                "Product point flash sale ต้องไม่มากกว่า Product point",
                                "error"
                              );
                              e.target.value = p_point;
                              p_discount = p_point;
                            }
                            setflash_sale_data({
                              ...flash_sale_data,
                              flash_sale: e.target.value,
                              product_discount:
                                ((p_point - p_discount) * 100.0) / p_point,
                            });
                          }}
                        />
                        <label htmlFor="">
                          Product Point Flash Sale
                          {flash_sale_data.flash_sale === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>{" "}
                      </div>
                      <div className="form-group ">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          id="text_category_name2"
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          decimalScale={2}
                          allowNegative={false}
                          disabled={disable}
                          required="false"
                          value={flash_sale_data.product_discount}
                          onChange={(e) => {
                            console.log(
                              "product_data.product_point - (product_data.product_point  * e.target.value)",
                              product_data.product_point -
                                product_data.product_point * e.target.value
                            );
                            const p_point = product_data.product_point
                              ? parseInt(
                                  product_data.product_point.replace(",", "")
                                )
                              : 0;
                            let p_discount = parseFloat(
                              e.target.value.replace(",", "")
                            );
                            if (p_discount > 100) {
                              Swal.fire(
                                "Warning",
                                "Product discount ต้องไม่เกิน 100 %",
                                "error"
                              );
                              e.target.value = 100;
                              p_discount = 100;
                            }
                            setflash_sale_data({
                              ...flash_sale_data,
                              product_discount: e.target.value,
                              flash_sale:
                                p_point - p_point * (p_discount / 100.0),
                            });
                          }}
                        />
                        <label htmlFor="">Product Discount ( % )</label>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <div className="container-fluid">
                  <div className="row">
                    <div className="form-group ">
                      {modeModelFlashSale.mode === "edit" ? (
                        <>
                          <button
                            type="button"
                            id="btn_add"
                            disabled={disable}
                            className="btn btn-block btn-primary"
                            style={{ float: "right" }}
                            onClick={() => {
                              addProductFlashSale();
                            }}
                          >
                            Save change
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            id="btn_add"
                            disabled={disable}
                            className="btn btn-block btn-primary"
                            style={{ float: "right" }}
                            onClick={() => {
                              addProductFlashSale();
                            }}
                          >
                            Add
                          </button>
                        </>
                      )}
                    </div>
                    <div className="col-12 col-md-4 col-xl-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                
        {/* ///////////////////////////////////////////////////////// */}
        <div className="modal fade" id="modal-add-supplier">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
              {supplier_select.mode === "edit" ? (
                  <>
                    <h4 className="modal-label">Edit Supplier Rerationship </h4>
                  </>
                ) : (
                  <>
                    <>
                    <h4 className="modal-label">Add Supplier Rerationship </h4>
                  </>
                  </>
                )}
                
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <div className="form-group">
                      <Select
                        disabled={disable}
                        options={supplier_option}
                        ref={select_supplier}
                        value={supplier_option.filter((e) => {
                          return e.label === supplier_select.supplier;
                        })}
                        onChange={(e) => {
                          //console.log(e);
                          if (e !== null) {
                            setsupplier_select({
                              ...supplier_select,
                              id: e.value || "",
                              supplier: e.label || "",
                            });
                          }
                        }}
                      />

                      <label htmlFor="">Supplier :</label>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <div className="form-group">
                      <NumberFormat
                        allowNegative={false}
                        type="text"
                        className="form-control"
                        thousandSeparator={true}
                        disabled={disable}
                        value={supplier_select.buy_price}
                        onChange={(e) =>
                          setsupplier_select({
                            ...supplier_select,
                            buy_price: e.target.value,
                          })
                        }
                        required
                      />

                      <label htmlFor="">Buy Price :</label>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <div className="form-group">
                      <NumberFormat
                        allowNegative={false}
                        thousandSeparator={true}
                        type="text"
                        className="form-control"
                        disabled={disable}
                        value={supplier_select.minimum_order}
                        onChange={(e) =>
                          setsupplier_select({
                            ...supplier_select,
                            minimum_order: e.target.value,
                          })
                        }
                        required
                      />

                      <label htmlFor="">Minimum Order :</label>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <div className="form-group">
                      <NumberFormat
                        allowNegative={false}
                        type="text"
                        disabled={disable}
                        className="form-control"
                        thousandSeparator={false}
                        value={supplier_select.lead_time}
                        onChange={(e) =>
                          setsupplier_select({
                            ...supplier_select,
                            lead_time: e.target.value,
                          })
                        }
                        required
                      />

                      <label htmlFor="">Lead Time (Days) :</label>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <div className="form-group">
                      <input
                        allowNegative={false}
                        type="text"
                        className="form-control"
                        disabled={disable}
                        value={supplier_select.remark}
                        onChange={(e) =>
                          setsupplier_select({
                            ...supplier_select,
                            remark: e.target.value,
                          })
                        }
                        required
                      />

                      <label htmlFor="">Remark :</label>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row">
                  <div className="col-1"></div>

                  <div className="col-1"></div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                {supplier_select.mode === "edit" ? (
                  <>
                    <button
                      disabled={
                        supplier_select.id === "" ||
                        supplier_select.buy_price == ""
                      }
                      type="button"
                      hidden={disable}
                      onClick={Edit_Suppier}
                      data-dismiss="modal"
                      className="btn btn-primary"
                    >
                      Save Change
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      disabled={
                        supplier_select.id === "" ||
                        supplier_select.buy_price === "" ||
                        disable
                          ? true
                          : false
                      }
                      type="button"
                      hidden={disable}
                      onClick={Add_Suppier}
                      data-dismiss="modal"
                      className="btn btn-primary"
                    >
                      Add
                    </button>
                  </>
                )}

                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageProductAdd;
