import React from 'react'
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { useEffect } from "react";
import { useState } from "react";
import { getToken, getUser, getOem, getAcademy } from "../../Utils/Common";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Configs from "../../config";
import NumberFormat from "react-number-format";
import moment from "moment";
import Swal from 'sweetalert2';
import Select from "react-select";
import { useParams } from 'react-router';


function Tooling_inquiry_add(mode) {
    const { id } = useParams();
    const [page_mode, setPageMode] = useState('Add');
    const [MDBT, setMDBT] = useState(true);
    let indexToolingtAll = 0;
    const [toolingInquiry, setToolingInquiry] = useState([]);
    const [inputToolingInquiry, setInputToolingInquiry] = useState({
        tooling_no: '',
        tooling_remark: '',
    });
    /* {
        tooling_img_name: '',
        tooling_path_name: '',
        tooling_code: '',
        tooling_name: '',
        brand_name: '',
        amount: '',
    } */
    const [supplier, setSupplier] = useState([]);
    const [supplier_option, setSupplier_option] = useState([]);
    const [supplier_select_data, setSupplier_select_data] = useState([]);
    const [supplier_select, setsupplier_select] = useState({
        id: "",
        supplier: "",
        buy_price: "",
        minimum_order: "",
        lead_time: "",
        mat_cycle_time: "",
        remark: "",
        mode: "",
        index: "",

    });
    const [disable, setdisable] = useState(false);
    useEffect(async () => {
        if (mode.mode === "add") {
            GetTooling();
            generateIQToolingApi();
            setdisable(false);
        }
        if (mode.mode === "edit") {
            getToolingInquiryByIdApi(id);
            setPageMode('Edit');
            GetTooling();
            setdisable(false);
        }

        if (mode.mode === "view") {
            getToolingInquiryByIdApi(id);
            setPageMode('View');
            setdisable(true);
        }
        setMDBT(true);
        setTest({ ...test, test: "" });
        test.test = "";
    }, []);

    const getToolingInquiryByIdApi = async (id) => {
        if (mode.mode === "edit" || mode.mode === "view") {
            axios({
                method: "get",
                url:
                    Configs.API_URL + "/tooling/getToolingInquiryByIdApi?id=" + id,
                headers: {
                    Authorization: getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                },
            })
                .then(async (response) => {
                    if (response.data) {
                        console.log(response.data);
                        const toolingsupplier = [];
                        if (response.data.toolingInput.length > 0) {
                            const toolingInput = response.data.toolingInput[0];
                            setInputToolingInquiry({
                                ...inputToolingInquiry,
                                tooling_no: toolingInput.inquiry_no,
                                tooling_remark: toolingInput.remark
                            })
                        }
                        if (response.data.tooling.length > 0) {
                            for (let item of response.data.tooling) {
                                let temp = {};
                                temp.amount = item.amount || '';
                                temp.balance = item.balance || '';
                                temp.brand_id = item.brand_id || '';
                                temp.brand_name = item.brand_name || '';
                                temp.consumption = item.consumption || '';
                                temp.id = item.id || '';
                                temp.inquiry_id = item.inquiry_id || '';
                                temp.is_active = item.is_active || '';
                                temp.is_use = item.is_use || '';
                                temp.max_stock = item.max_stock || '';
                                temp.min_stock = item.min_stock || '';
                                temp.ratio = item.ratio || '';
                                temp.status_edit = item.status_edit || '';
                                temp.status = true;
                                temp.tooling_code = item.tooling_code || '';
                                temp.tooling_detail = item.tooling_detail || '';
                                temp.tooling_id = item.tooling_id || '';
                                temp.tooling_img_name = item.tooling_img_name || '';
                                temp.tooling_name = item.tooling_name || '';
                                temp.tooling_path_name = item.tooling_path_name || '';
                                /// i want to check item.tooling_id that same with array object tooling

                                /// 
                                toolingsupplier.push(temp);
                            }
                            setToolingInquiry(toolingsupplier);

                        }
                        setTest({ ...test, test: '' });
                    }

                })
                .catch(function (error) {
                    console.log('error', error.response.data);
                    Swal.fire("Error", error.response.data.message, "error");
                });
        }
    }


    const [tooling, settooling] = useState([]);
    const [test, setTest] = useState({
        test: ''
    });

    const generateIQToolingApi = async () => {
        axios({
            method: "get",
            url: Configs.API_URL + "/tooling/generateIQToolingApi",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
                console.log('response', response);
                setInputToolingInquiry({
                    ...inputToolingInquiry,
                    tooling_no: response.data,
                });
                setMDBT(true);
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
        // });
    };

    function editAmount_tooling(e) {
        //console.log(e.target.value);
        var tempData = toolingInquiry;
        // toolingInquiry = [];
        setToolingInquiry([]);
        tempData[e.target.name].amount = e.target.value;
        // tempData[e.target.name].status_edit = 'update';
        // tempData[e.target.name].total = e.target.value * tempData[e.target.name].nms;
        // materail_data = tempData;
        setToolingInquiry(tempData);
        setTest({ ...test, test: "" });
        test.test = "";
    }

    const [indexTooling, setIndexTooling] = useState([]);
    const loadSelectedTooling = async () => {
        let arrIndex = [];
        const updateTooling =  tooling.map((tool, index)  => {
            const matchTooling = toolingInquiry.find(tools => tools.tooling_id === tool.tooling_id);
            if (matchTooling) {
                arrIndex.push("" + index);
                return { ...tool, status: true };
            }
            return { ...tool, status: false };
        });
        setIndexTooling(arrIndex);
        settooling(updateTooling);
        /* const matchingToolIds = await toolingInquiry.reduce(async (idsPromise, inquiry) => {
            const ids = await idsPromise;
            const matchingTool = tooling.find(tool => tool.tooling_id === inquiry.tooling_id);
            if (matchingTool) {
                matchingTool.status = true;
                ids.push(matchingTool.tooling_id);
            }
            return ids;
        }, []); */

        // settooling([...tooling]);
        setTest(({ test, ...rest }) => rest);
    };

    const setSelectTooling = async (toolingSelect, e) => {
        setMDBT(true);
        const index = e.target.id;
        const tempData = tooling;
        const tempTooling = toolingInquiry;
        tempData[index].status = !tempData[index].status;
        tempData[index].status_edit = 'add';
        if (tempData[index].status) {
            tempTooling.push(tempData[index]);
            setIndexTooling([...indexTooling, index]);
        } else {
            for (let i = 0; i < tempTooling.length; i++) {
                if (tempTooling[i].tooling_id === tempData[index].tooling_id) {
                    tempTooling.splice(i, 1);
                    indexTooling.splice(i, 1);
                }
            }
        }
        setToolingInquiry(tempTooling);
        settooling(tempData);
        setTest({ ...test, test: '' });
    }
    const [toolingDelete, setToolingDelete] = useState([]);
    const cancelSelectTooling = async (toolingSelected, e) => {

        console.log('first', e === undefined  ? e:e.target.id);
        const index = e === undefined  ? e:e.target.id;
        const tempData = tooling;
        const tempTooling = [...toolingInquiry];
        const indexArr = indexTooling;
        for (let i = 0; i < indexTooling.length; i++) {
            if (tempTooling[index].tooling_id === tempData[indexTooling[i]].tooling_id) {
                tempData[indexTooling[i]].status = false;
                console.log('indexTooling[i]', indexTooling[i]);
                indexArr.splice(i, 1);
            }
        }
        setIndexTooling(indexArr);
        settooling(tempData);
        tempTooling[index].status_edit = 'delete';

        const arr = [...toolingDelete];
        arr.push(...tempTooling.splice(index, 1));
        setToolingDelete(arr);

        setToolingInquiry(tempTooling);
        setTest({ ...test, test: '' });
    };

   /*  const cancelSelectTooling2 = async (e) => {

        console.log('first', e);
        const index = e.tooling_id ;
        const tempData = tooling;
        const tempTooling = [...toolingInquiry];
        const indexArr = indexTooling;
        for (let i = 0; i < indexTooling.length; i++) {
            if (tempTooling[index].tooling_id === tempData[indexTooling[i]].tooling_id) {
                tempData[indexTooling[i]].status = false;
                console.log('indexTooling[i]', indexTooling[i]);
                indexArr.splice(i, 1);
            }
        }
        setIndexTooling(indexArr);
        settooling(tempData);
        tempTooling[index].status_edit = 'delete';

        const arr = [...toolingDelete];
        arr.push(...tempTooling.splice(index, 1));
        setToolingDelete(arr);

        setToolingInquiry(tempTooling);
        setTest({ ...test, test: '' });
    }; */

    const loadSuggestionOfTooling = () => {
        Swal.fire({
            title: "Now loading",
            allowEscapeKey: false,
            allowOutsideClick: false,
            // timer: 2000,
            didOpen: () => {
                Swal.showLoading();
            },
        }).then(() => {
            axios({
                method: "get",
                url: Configs.API_URL + "/tooling/loadSuggestionOfTooling",
                headers: {
                    Authorization: getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                },
            })
                .then(async function (response) {
                    Swal.close();
                    console.log('response', response);
                    if (response.data.length > 0) {
                        setToolingInquiry([]);
                        setIndexTooling([]);
                        const suggestion = response.data;

                        setToolingInquiry(suggestion);
                        let arrIndex = [];
                        setToolingDelete(toolingInquiry);
                        const updatedTooling = tooling.map((tool, index) => {
                           
                            const matchingSuggestion = suggestion.find(sugg => sugg.tooling_id === tool.tooling_id);
                            if (matchingSuggestion) {
                                /* const updatedAmount = Number(String(tool.amount).replace(/,/g, "")) + matchingSuggestion.amount;
                                console.log('updatedAmount', updatedAmount); */
                                arrIndex.push("" + index);
                                return { ...tool, status: true };
                            }
                            return { ...tool, status: false };
                        });
                        setIndexTooling(arrIndex);
                        settooling(updatedTooling);
                    }
                    setTest({ ...test, test: '' });
                    setMDBT(true);

                })
                .catch(function (error) {
                    console.log(error);
                    Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                });
        });
        Swal.close();
    }

    const backPeviousPage = () => {
        Swal.fire({
            title: "คุณต้องการที่จะออกจากหน้านี้ ใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `ใช่`,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: `ไม่`,
        })
            .then((res) => {
                if (res.isConfirmed) {
                    window.location.href = "/Warehouse_Management/Tooling_Inquiry";
                }
            })
    }

    const saveToolingInquiryApi = async () => {

        inputToolingInquiry.tooling_remark = inputToolingInquiry.tooling_remark.trim();
        for (let item of toolingInquiry) {
            item.amount = Number(String(item.amount).replace(/,/g, "")) || null;
        }
        const error_list = [];

        if (toolingInquiry.length === 0) {
            let temp_err = {
                message: "กรุณากดเพิ่มข้อมูล Tooling ก่อนกดบันทึก",
            };
            error_list.push(temp_err);
        }

        /* toolingData.minStock
toolingData.maxStock */

        if (error_list.length > 0) {
            var err_message = "";
            for (var e = 0; e < error_list.length; e++) {
                err_message += "<br/>" + error_list[e].message;
            }
            Swal.fire("Error", err_message, "error");
        } else {
            let toolingData1 = {
                tooling: toolingInquiry,
                emp_oem_id: getOem(),
                emp_fup: getUser().fup,
                emp_company_id: getUser().com,
                toolingInput: inputToolingInquiry
            }
            if (mode.mode === "add") {
                Swal.fire({
                    title: "ต้องการที่จะบันทึกข้อมูล ใช่หรือไม่",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonText: `ใช่`,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: `ไม่`,
                }).then((result) => {
                    if (result.isConfirmed) {

                        axios({
                            method: "post",
                            url:
                                Configs.API_URL + "/tooling/addToolingInquiryApi",
                            headers: {
                                Authorization: getToken(),
                                "X-TTT": Configs.API_TTT,
                                "Content-Type": "application/json",
                            },
                            data: toolingData1,
                        })
                            .then(function (response) {
                                if (response.data) {
                                    // console.log(response.data);
                                    Swal.fire({
                                        icon: "success",
                                        title: "Save",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    }).then((result) => {
                                        window.location.href = "/Warehouse_Management/Tooling_Inquiry";
                                    });
                                }

                            })
                            .catch(function (error) {
                                console.log('error', error.response.data);
                                Swal.fire({
                                    icon: "error", 
                                    title: "บันทึกข้อมูลไม่สำเร็จ",
                                    html: error.response.data.message,
                                });
                            });
                    }
                });
            } else if (mode.mode === "edit") {
                /*  const result = [];
                 for (let item = 0; item < toolingDelete.length; item++) {
                     let found = false; // flag variable
                     for (let item1 = 0; item1 < toolingInquiry.length; item1++) {
                         if (toolingDelete[item].tooling_id === toolingInquiry[item1].tooling_id) {
                             found = true;
                             break; // exit inner loop early if match found
                         }
                     }
                     if (!found) {
                         result.push(toolingDelete[item]);
                     }
                 } */
                if (toolingDelete.length > 0) {
                    for (let i = 0; i < toolingDelete.length; i++) {
                        let deleteId = toolingDelete[i].tooling_id;
                        let inquiryIndex = toolingInquiry.findIndex(item => item.tooling_id === deleteId);
                        if (inquiryIndex !== -1) {
                            // tooling_id is found in toolingInquiry, splice the element from toolingDelete
                            toolingDelete.splice(i, 1);
                            i--; // update index to account for the spliced element
                        }
                    }
                }
                // console.log('toolingDelete', toolingDelete);
                let toolingData = {
                    inquiry_id: id,
                    tooling: toolingInquiry,
                    tooling_delete: toolingDelete,
                    emp_oem_id: getOem(),
                    emp_fup: getUser().fup,
                    emp_company_id: getUser().com,
                    toolingInput: inputToolingInquiry
                }
                Swal.fire({
                    title: "ยืนยันที่จะแก้ไขข้อมูล ใช่หรือไม่",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonText: `ใช่`,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: `ไม่`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios({
                            method: "post",
                            url:
                                Configs.API_URL + "/tooling/updateToolingInquiryApi",
                            headers: {
                                Authorization: getToken(),
                                "X-TTT": Configs.API_TTT,
                                "Content-Type": "application/json",
                            },
                            data: toolingData,
                        })
                            .then(function (response) {
                                if (response.data) {
                                    console.log(response.data);
                                    Swal.fire({
                                        icon: "success",
                                        title: "Save",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    }).then((result) => {
                                        // console.log(response.data);
                                        window.location.href = "/Warehouse_Management/Tooling_Inquiry";
                                    });
                                }

                            })
                            .catch(function (error) {
                                console.log('error', error.response.data);
                                Swal.fire("Error", error.response.data.message, "error");
                            });
                    }
                });
            }
        }
    }

    const GetTooling = async () => {
        const data = {
            tooling_code: "",
            tooling_name: "",
            supplier_id: "",
            brand_id: "",
            oem_id: getOem(),
            company_id: getUser().com,
        };

        await axios({
            method: "post",
            url: Configs.API_URL + "/tooling/filterToolingInquirySelect",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: data,
        })
            .then(async (response) => {
                response.data.sort((a, b) => {
                    if (a.tooling_code === null) {
                        return 1;
                    }

                    if (b.tooling_code === null) {
                        return -1;
                    }

                    if (a.tooling_code === b.tooling_code) {
                        return 0;
                    }

                    return a.tooling_code < b.tooling_code ? -1 : 1;
                });
                const arrList = [];
                for (let item of response.data) {
                    item.status = false;
                    arrList.push(item);
                }
                await settooling(arrList);
                setTest({ ...test, test: '' });
            })
            .catch(async (error) => {
                console.log(error);
            });
    };

    const rowsData = [];
    for (let index = 0; index < toolingInquiry.length; index++) {
        const rowItem = {};
        if (toolingInquiry[index].status_edit !== 'delete') {
            rowItem["no"] = (<div style={{ textAlign: 'center' }}>{index + 1}</div>);

            rowItem["image_path"] = (
                <div style={{ textAlign: "center" }}>
                    <img
                        // alt={tooling[index].tooling_img_name}
                        alt="..."
                        className="img-fluid rounded"
                        src={
                            toolingInquiry[index].tooling_path_name !== null &&
                                toolingInquiry[index].tooling_path_name !== ''
                                ? Configs.API_URL_IMG + toolingInquiry[index].tooling_path_name
                                : userdefault_img.imgs
                        }
                        style={{ width: "50px", height: "50px" }}
                    />
                </div>
            );

            rowItem["tooling_code"] = (
                <div /* style={{ textAlign: 'end' }} */ >
                    {toolingInquiry[index].tooling_code}
                </div>
            );
            rowItem["tooling_code_search"] = toolingInquiry[index].tooling_code;

            rowItem["tooling_name"] = (
                <>
                    <div style={{ whiteSpace: 'normal' }} >
                        {toolingInquiry[index].tooling_name}
                    </div>
                </>
            );
            rowItem["tooling_name"] = toolingInquiry[index].tooling_name;

            rowItem["brand_tooling"] = (
                <>
                    <div /* style={{ textAlign: 'end' }} */ >
                        {toolingInquiry[index].brand_name}
                    </div>
                </>
            );
            rowItem["brand_tooling"] = toolingInquiry[index].brand_name;
            
            rowItem["max"] = (
                <>
                    <NumberFormat
                       disabled={disable}
                       type="text"
                       displayType='text'
                       decimalScale={0}
                       name={index}
                        thousandSeparator={true}
                        value={toolingInquiry[index].max_stock || 0}
                    />
                </>
            );
            rowItem["min"] = (
                <>
                    <NumberFormat
                       disabled={disable}
                       type="text"
                       displayType='text'
                       decimalScale={0}
                       name={index}
                        thousandSeparator={true}
                        value={toolingInquiry[index].min_stock || 0}
                    />
                </>
            );
            rowItem["consumption"] = (
                <>
                    <NumberFormat
                       disabled={disable}
                       type="text"
                       displayType='text'
                       decimalScale={0}
                       name={index}
                        thousandSeparator={true}
                        value={toolingInquiry[index].consumption || 0}
                    />
                </>
            );
            rowItem["balance"] = (
                <>
                    <NumberFormat
                        disabled={disable}
                        type="text"
                        displayType='text'
                        decimalScale={0}
                        name={index}
                        thousandSeparator={true}
                        value={toolingInquiry[index].balance || 0}
                    />
                </>
            );
            rowItem["amount_tooling"] = (
                <>
                    <NumberFormat
                        disabled={disable}
                        type="text"
                        className="form-control"
                        required
                        decimalScale={0}
                        allowNegative={false}
                        name={index}
                        thousandSeparator={true}
                        value={toolingInquiry[index].amount || ''}
                        onChange={editAmount_tooling.bind(this)}
                    />
                    {/* <div className="row mb-2">
                    <div className="col-12 col-md-12 col-xl-12">
                        <div className="form-group ">
                            <input
                                type="text"
                                className="form-control"
                                id="text_empid"
                                // disabled={true}
                                required="false"
                                value={toolingInquiry[index].amount}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    setInputToolingInquiry(prevState => {
                                        const newToolingInquiry = [...prevState];
                                        newToolingInquiry[index].amount = value;
                                        return newToolingInquiry;
                                    });
                                    /* setInputToolingInquiry({
                                        ...inputToolingInquiry,
                                        amount: e.target.value,
                                    }); 
                                }}
                            />
                            {/* <label htmlFor="">Amount</label>{" "} }
                        </div>
                    </div>
                </div> */}
                    {/* <div /* style={{ textAlign: 'end' }}  >
                    {toolingInquiry[index].brand_name}
                </div> */}
                </>
            );

            rowItem["management"] = (
                <div className="row">
                    <div className="col-12">
                        <div className="form-group ">
                            <button
                                type="button"
                                hidden={disable}
                                id={index}
                                className={"btn btn-block btn-danger"}
                                onClick={cancelSelectTooling.bind(
                                    this,
                                    toolingInquiry[index]
                                )}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
        rowsData.push(rowItem);
    }

    const data = {
        columns: [
            {
                label: "No.",
                field: "no",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Picture",
                field: "image_path",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Tooling Code",
                field: "tooling_code",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Tooling Name",
                field: "tooling_name",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Brand",
                field: "brand_tooling",
                // sort: "asc", amount_tooling
                width: 50,
            },
            {
                label: "Min Stock",
                field: "min",
                // sort: "asc", amount_tooling
                width: 50,
            },
            {
                label: "Max Stock",
                field: "max",
                // sort: "asc", amount_tooling
                width: 50,
            },
            {
                label: "Consumption",
                field: "consumption",
                // sort: "asc", amount_tooling
                width: 50,
            },
            {
                label: "Stock",
                field: "balance",
                // sort: "asc", amount_tooling
                width: 50,
            },
            {
                label: "Amount",
                field: "amount_tooling",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Management",
                field: "management",
                // sort: "asc",
                width: 50,
            },
        ],

        rows: rowsData,
    };

    const rowsDataTooling = [];
    for (var index = 0; index < tooling.length; index++) {
        const rowItem = {};

        rowItem["no"] = index + 1;

        rowItem["image"] = (
            <div style={{ textAlign: "center" }}>
                <img
                    // alt={tooling[index].tooling_img_name}
                    alt="..."
                    className="img-fluid rounded"
                    src={
                        tooling[index].tooling_path_name !== null &&
                        tooling[index].tooling_path_name !== ''
                            ? Configs.API_URL_IMG + tooling[index].tooling_path_name
                            : userdefault_img.imgs
                    }
                    style={{ width: "50px", height: "50px" }}
                />
            </div>
        );

        rowItem["tooling_code"] = tooling[index].tooling_code;

        rowItem["tooling_name"] = tooling[index].tooling_name;

        rowItem["brand"] = tooling[index].brand_name;

        rowItem["min_stock"] = tooling[index].min_stock;

        rowItem["max_stock"] = tooling[index].max_stock;

        rowItem["consumption"] = tooling[index].consumption;

        rowItem["ratio"] = tooling[index].ratio;

        rowItem["mgt"] = (
            <div className="row">
                <div className="col-12">
                    <div className="form-group ">
                        <button
                            type="button"
                            id={index}
                            className={tooling[index].status ? "btn btn-block btn-danger" : "btn btn-block btn-primary"}
                            onClick={setSelectTooling.bind(
                                this,
                                tooling[index]
                            )}
                        >
                            {tooling[index].status ? 'Selected' : 'Select'}
                        </button>
                    </div>
                </div>
            </div>
        );
        rowsDataTooling.push(rowItem);
    }

    const dataTooling = {
        columns: [
            {
                label: "No",
                field: "no",
                sort: "asc",
                width: 50,
            },
            {
                label: "Image",
                field: "image",
                sort: "asc",
                width: 50,
            },
            {
                label: "Tooling Code",
                field: "tooling_code",
                sort: "asc",
                width: 50,
            },
            {
                label: "Tooling Name",
                field: "tooling_name",
                sort: "asc",
                width: 50,
            },
            {
                label: "Brand",
                field: "brand",
                sort: "asc",
                width: 50,
            },
            {
                label: "Min Stock",
                field: "min_stock",
                sort: "asc",
                width: 50,
            },

            {
                label: "Max Stock",
                field: "max_stock",
                sort: "asc",
                width: 50,
            },
            {
                label: "consumption",
                field: "consumption",
                sort: "asc",
                width: 50,
            },
            {
                label: "Ratio",
                field: "ratio",
                sort: "asc",
                width: 50,
            },
            {
                label: "Select",
                field: "mgt",
                sort: "asc",
                width: 50,
            },
        ],
        rows: rowsDataTooling,
    };

    return (
        <div className="wrapper">
            {/* <Header />
          <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>
                                    {page_mode} Tooling Inquiry
                                    {"  "}
                                    <a target="_blank" href={getAcademy()}>
                                        <i class="fa fa-info-circle"></i>
                                    </a>
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>

                                    {/* <li className="breadcrumb-item active">Human Resource</li> */}
                                    <li className="breadcrumb-item active">Warehouse</li>
                                    <li className="breadcrumb-item active">Tooling Inquiry</li>
                                    <li className="breadcrumb-item active">{page_mode}</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row mb-2">
                            {disable ? "" :
                                <div className="col-6 col-md-4 col-xl-2">
                                    <div className="form-group ">
                                        <button
                                            type="button"
                                            id="btn_search"
                                            className="btn btn-block btn-success"
                                            // disabled="true"
                                            onClick={() => {
                                                saveToolingInquiryApi();
                                                //  window.location.href = "/Warehouse_Management/Tooling_Inquiry/add";
                                            }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            }
                            <div className="col-6 col-md-4 col-xl-2">
                                <div className="form-group ">
                                    <button
                                        type="button"
                                        // disabled="true"
                                        id="btn_search"
                                        className="btn btn-block btn-danger"
                                        onClick={() => {
                                            backPeviousPage();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content-body">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Tooling Inquiry {page_mode}</h3>
                        </div>

                        <div className="card-body">
                            <div className="row mb-2">
                                <div className="col-12 col-md-4 col-xl-3">
                                    <div className="form-group ">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="text_empid"
                                            disabled={true}
                                            required="false"
                                            value={inputToolingInquiry.tooling_no}
                                            onChange={(e) => {
                                                setInputToolingInquiry({
                                                    ...inputToolingInquiry,
                                                    tooling_no: e.target.value,
                                                });
                                            }}
                                        />
                                        <label htmlFor="">Inquiry No. (Auto Generate)</label>{" "}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 col-md-4 col-xl-3">
                                    <div className="form-group ">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="text_empid"
                                            disabled={disable}
                                            required="false"
                                            value={inputToolingInquiry.tooling_remark}
                                            onChange={(e) => {
                                                setInputToolingInquiry({
                                                    ...inputToolingInquiry,
                                                    tooling_remark: e.target.value,
                                                });
                                            }}
                                        />
                                        <label htmlFor="">Remark</label>{" "}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="">

                                        <div className="row">
                                            <div className="col-6 col-md-3 col-xl-3">
                                                <div class="form-group">
                                                    <button
                                                        type="button"
                                                        data-toggle="modal"
                                                        data-target="#modal-add_supplier_rerationship"
                                                        //onClick={saveOption}
                                                        class="btn btn-block btn-primary "
                                                        hidden={disable}
                                                        onClick={() => {
                                                            setMDBT(false);
                                                            if (mode.mode === 'edit')
                                                                loadSelectedTooling();
                                                            setsupplier_select({
                                                                ...supplier_select, id: "",
                                                                supplier: "",
                                                                buy_price: "",
                                                                mode: "add",
                                                                index: "",
                                                                minimum_order: "",
                                                                lead_time: "",
                                                                remark: "",
                                                            });
                                                        }}
                                                    >
                                                        Add Tooling Items&nbsp;
                                                        <span>
                                                            <i className="fa fa-plus-circle"></i>
                                                        </span>
                                                    </button>

                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 col-xl-2">
                                                <div className="form-group ">
                                                    <button
                                                        type="button"
                                                        id="btn_search"
                                                        className="btn btn-block btn-primary"
                                                        hidden={disable}
                                                        onClick={() => {
                                                            loadSuggestionOfTooling();
                                                        }}
                                                    >
                                                        Load Suggestion
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 col-xl-2">
                                                <div className="form-group " hidden={disable}>
                                                    <ReactHTMLTableToExcel
                                                        id="test1-table-xls-button"
                                                        className="btn btn-block btn-primary"
                                                        table="prduct-table-to-xls"
                                                        filename={
                                                            "Tooling_inquiry_" + moment(new Date()).format("DD-MM-yyyy")
                                                        }
                                                        sheet="tablexls"
                                                        buttonText="Export"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 col-xl-2">
                                                <div className="form-group ">
                                                    <button
                                                        type="button"
                                                        hidden={disable}
                                                        id="btn_search"
                                                        className="btn btn-block btn-primary"
                                                        // disabled="true"
                                                        onClick={() => {
                                                            for (const item of toolingInquiry)
                                                                item.status = 'delete';

                                                            setToolingDelete(toolingInquiry);
                                                            setToolingInquiry([]);
                                                            setIndexTooling([]);
                                                            // toolingInquiry = [];
                                                            for (const item of tooling) {
                                                                item.status = false;
                                                            }
                                                            setTest({ ...test, test: '' });
                                                        }}
                                                    >
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive" style={{ display: "none" }}>
                                            <table
                                                id="prduct-table-to-xls"
                                                className="table table-head-fixed"
                                                border="1"
                                                style={{ display: "none" }}
                                            >
                                                {/* <tr style={{ border: 'none' }}>
                                                    <td colSpan="5" style={{ textAlign: 'right', border: 'none' }}>Inquiry No: {inputToolingInquiry.tooling_no || ''}</td>
                                                </tr>
                                                <tr style={{ border: 'none' }}>
                                                    <td colSpan="5" style={{ textAlign: 'right', border: 'none' }} >Remark: {inputToolingInquiry.tooling_no.trim() || ''}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td style={{ backgroundColor: "LightSkyBlue", textAlign: "center" }} colSpan="5">Tooling Inquiry Reprot</td>
                                                </tr> */}
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Tooling Code</th>
                                                        <th>Tooling Name</th>
                                                        {/* <th>Model Category</th> */}
                                                        <th>Brand Name</th>
                                                        <th>Amount</th>
                                                        {/* <th>Unit</th> */}
                                                        {/* <th>Project</th>
                                                                    <th>Date</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {toolingInquiry.map((el) => {
                                                        ++indexToolingtAll;
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>{indexToolingtAll}</td>
                                                                    <td>{el.tooling_code}</td>
                                                                    <td>{el.tooling_name}</td>
                                                                    <td>{el.brand_name}</td>
                                                                    <td>{el.amount || 0}</td>
                                                                    {/* <td>{el.}</td>
                                                                                <td>{el.}</td> */}
                                                                </tr>
                                                            </>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">

                                            <div className="col-12">
                                                <div className="table-responsive" style={{ whiteSpace: 'nowrap' }} /*  style={{ height: "600px" }} */ >
                                                    <MDBDataTable
                                                        sortable={false}
                                                        className="table table-head-fixed"
                                                        striped
                                                        bordered
                                                        hover
                                                        fixedHeader
                                                        data={data}
                                                    />

                                                </div>
                                            </div>

                                        </div>
                                        <div className="modal fade" id="modal-add_supplier_rerationship">
                                            <div className="modal-dialog modal-xl">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h4 className="modal-label">Select Tooling Items</h4>
                                                        <button
                                                            type="button"
                                                            className="close"
                                                            data-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            {/* <div className='col-2'>
                                                    <div className="form-group ">
                                                        <button
                                                            type="button"
                                                            id="btn_search"
                                                            className="btn btn-block btn-success"
                                                            disabled="true"
                                                        /* onClick={() => {
                                                            window.location.href = "/Warehouse_Management/Tooling_Inquiry/add";
                                                        }} 
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div> */}
                                                        </div>
                                                        <div className="row">

                                                            <div className="col-12">


                                                                <div className="table-responsive" style={{ whiteSpace: 'nowrap' }} /*  style={{ height: "600px" }} */ >
                                                                    <MDBDataTable
                                                                        sortable={false}
                                                                        className="table table-head-fixed"
                                                                        striped
                                                                        bordered
                                                                        hover
                                                                        fixedHeader
                                                                        disableRetreatAfterSorting={MDBT}
                                                                        data={dataTooling}
                                                                    />

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="modal-footer justify-content-end ">
                                                        <button
                                                            // hidden="true"
                                                            hidden="true"
                                                            type="button"
                                                            className="btn btn-default"
                                                            data-dismiss="modal"
                                                        >
                                                            Close
                                                        </button>
                                                        <button
                                                            type="button"
                                                            style={{ width: '5em' }}
                                                            className="btn btn-default"
                                                            data-dismiss="modal"
                                                        >
                                                            Close
                                                        </button>


                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer" />
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Tooling_inquiry_add