import React, { useEffect, useRef, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import "../Dashboard_Log/DashboardLog.css";
import Pie_Chart from "./Pie_Chart";
import Configs from "../../../../config";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";
import {
  PlusOutlined,
  MinusOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
} from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

import Select from "react-select";

const dataTest = [
  {
    emp_id: 1,
    emp_name: "name1",
    emp_log_count: 3,
    emp_log_list: [
      {
        log_name: "log_name1",
        log_type: "log_type1",
        log_details: "log_details1",
      },
      {
        log_name: "log_name2",
        log_type: "log_type2",
        log_details: "log_details2",
      },
      {
        log_name: "log_name3",
        log_type: "log_type3",
        log_details: "log_details3",
      },
    ],
  },
  {
    emp_id: 2,
    emp_name: "name2",
    emp_log_count: 2,
    emp_log_list: [
      {
        log_name: "log_name1",
        log_type: "log_type1",
        log_details: "log_details1",
      },
      {
        log_name: "log_name2",
        log_type: "log_type2",
        log_details: "log_details2",
      },
    ],
  },
];

function DashboardLog() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState({ path: null });

  const [filterPieFollowUp, setFilterPieFollowUp] = useState({
    data: [],
  });

  const [filterYear, setFilterYear] = useState([]);

  const allOption = { value: "", label: "All" };

  const optionsWithAll = [allOption, ...filterYear];

  const handleYearChange = (selectedOption) => {
    setFilterTeam({
      ...filterteam,
      year: selectedOption ? selectedOption.value : "",
    });
    GetTypeDashboardLog(selectedOption ? selectedOption.value : "");
    GetUserTotalDashboardLog(selectedOption ? selectedOption.value : "", false);
  };

  const [filterteam, setFilterTeam] = useState({
    year: "",
  });

  const [userList, setUserList] = useState({
    data: [],
  });

  const [logList, setLogList] = useState({
    data: [],
  });

  const [expandedRows, setExpandedRows] = useState([]);

  const [sortDirection, setSortDirection] = useState("asc");

  const [colorPie, setColorPie] = useState({ data: [] });

  const loading_pie = false;

  const total = logList.data.reduce((sum, e, index) => {
    return filterPieFollowUp.data.includes(index) ? sum : sum + e.count_data;
  }, 0);

  const filteredData = logList.data.reduce((acc, e, index) => {
    if (!filterPieFollowUp.data.includes(index)) {
      const percent = (e.count_data / total) * 100;
      if (percent > 0) {
        acc.push(e);
      }
    }
    return acc;
  }, []);

  const data_chart_performance_by_mode_of_transport = {
    labels: filteredData.map((e) => e.rml_name),
    datasets: [
      {
        data: filteredData.map((e) => e.count_data),
        backgroundColor: colorPie.data.slice(0, filteredData.length),
        hoverBackgroundColor: colorPie.data
          .slice(0, filteredData.length)
          .map((e) => `${e}90`),
        hoverBorderColor: colorPie.data.slice(0, filteredData.length),
        hoverOffset: 1,
        borderWidth: filteredData.length === 1 ? 0 : 1,
      },
    ],
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  function numberWithCommas(x) {
    return x
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleSort = () => {
    const sorted = [...userList.data].sort((a, b) => {
      if (a.log_list.length < b.log_list.length)
        return sortDirection === "asc" ? 1 : -1;
      if (a.log_list.length > b.log_list.length)
        return sortDirection === "asc" ? -1 : 1;
      return 0;
    });

    setUserList({ data: sorted });
    userList.data = sorted;
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const handleRowClick = (emp_id) => {
    const currentIndex = expandedRows.indexOf(emp_id);
    const newExpandedRows = [...expandedRows];

    if (currentIndex >= 0) {
      newExpandedRows.splice(currentIndex, 1);
    } else {
      newExpandedRows.push(emp_id);
    }

    setExpandedRows(newExpandedRows);
  };

  async function GetUserTotalDashboardLog(year, check) {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/companyRegulations/GetUserTotalDashboardLog",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().emp_id,
        company_id: getUser().com,
        oem_id: getOem(),
        year: year,
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          const data = response.data.data.map((el) => {
            el.log_list.sort(
              (a, b) => new Date(b.rl_date_filed) - new Date(a.rl_date_filed)
            );
            return el;
          });
          setUserList({ data: data });
          userList.data = data;

          if (check) {
            let dropdownYear = [];

            for (let item of response.data.data) {
              let tempData = item.log_list.map((el) =>
                moment(el.rl_date_filed).format("YYYY")
              );

              dropdownYear.push(...tempData);
            }

            let uniqueDropdownYear = [...new Set(dropdownYear)]
              .map((year) => ({
                value: year,
                label: Number(year),
              }))
              .sort((a, b) => a.value - b.value);

            setFilterYear(uniqueDropdownYear);
          }
        } else {
          setUserList({ data: [] });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function GetTypeDashboardLog(year) {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/companyRegulations/GetTypeDashboardLog",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().emp_id,
        company_id: getUser().com,
        oem_id: getOem(),
        year: year,
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          const color = ["#56B6FD", "#53FDC2", "#40FD79", "#ADFD55"];
          let color_data = ["", "", "", ""];
          const data_sort = response.data.data.sort(
            (a, b) => b.count_data - a.count_data
          );
          data_sort.forEach((el, el_index) => {
            const index = response.data.data.findIndex(
              (el2) => el2.rml_name === el.rml_name
            );
            color_data[index] = color[el_index];
          });
          setLogList({ data: response.data.data });
          setColorPie({ data: color_data });
        } else {
          setLogList({ data: [] });
          setColorPie({ data: [] });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  const renderSubTable = (emp_log_list) => {
    return (
      <table
        style={{ borderCollapse: "collapse", width: "100%" }}
        className="table-sub-style-dashboard-log-header"
      >
        <thead>
          <tr>
            <th style={{ width: "50px" }}>No.</th>
            <th>ประเภท</th>
            <th>รายละเอียด</th>
            <th>วันที่พบ</th>
            <th>หลักฐาน</th>
          </tr>
        </thead>
        <tbody>
          {emp_log_list.map((log, index) => (
            <tr
              key={index}
              className={
                index % 2 === 0
                  ? " table-style-dashboard-log-tr-hover"
                  : "table-style-dashboard-log-tr-hover"
              }
            >
              <td style={{ textAlign: "center" }}>{index + 1}</td>
              <td style={{ textAlign: "center", maxWidth: "400px" }}>
                <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  {log.rml_name}
                </div>
              </td>
              <td style={{ textAlign: "center", maxWidth: "400px" }}>
                <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  {log.rl_remark}
                </div>
              </td>
              <td style={{ textAlign: "center" }}>
                {log.rl_date_filed
                  ? moment(log.rl_date_filed).format("DD-MM-YYYY")
                  : "-"}
              </td>
              <td style={{ textAlign: "center" }}>
                {/* <a
                  href={
                    "/Human_Resource/company_regulations/Manage_message_types/view/"
                  }
                  id="btn_read"
                  key={"view"}
                  className="btn btn-xs "
                >
                  <i style={{ fontSize: "14px" }} class="fas fa-eye"></i>
                  {"   "}
                </a> */}
                <div
                  style={{
                    padding: "5px",
                    border: "1px solid #d3d3d3",
                    display: "inline-block",
                    cursor:
                      log.rl_path_file !== "" &&
                      log.rl_path_file !== null &&
                      log.rl_path_file !== undefined
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => {
                    if (log.rl_path_file) {
                      setImage({ path: log.rl_path_file });
                      image.path = log.rl_path_file;
                      handleOpenModal();
                    }
                  }}
                >
                  <img
                    id="img"
                    alt="..."
                    className="img-fluid rounded border-0"
                    src={
                      log.rl_path_file !== "" &&
                      log.rl_path_file !== null &&
                      log.rl_path_file !== undefined
                        ? Configs.API_URL_IMG + log.rl_path_file
                        : userdefault_img.imgs
                    }
                    style={{
                      width: "60px",
                      height: "60px",
                      position: "relative",
                      objectFit: "cover",
                      display: "block",
                      "margin-left": "auto",
                      "margin-right": "auto",
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  // const dataUser = [];
  // for (let index = 0; index < userList.data.length; index++) {
  //   const cat = userList.data[index];

  //   // console.log('cat: ', cat);

  //   const element = {};
  //   element["no"] = index + 1;
  //   element["image"] = (
  //     <img
  //       id="img"
  //       alt="..."
  //       className="img-fluid rounded shadow border-0"
  //       src={
  //         cat.rc_path_image !== "" &&
  //         cat.rc_path_image !== null &&
  //         cat.rc_path_image !== undefined
  //           ? Configs.API_URL_IMG + cat.rc_path_image
  //           : userdefault_img.imgs
  //       }
  //       style={{
  //         width: "60px",
  //         height: "60px",
  //         position: "relative",
  //         objectFit: "cover",
  //         display: "block",
  //         "margin-left": "auto",
  //         "margin-right": "auto",
  //       }}
  //     />
  //   );
  //   element["category"] = (
  //     <div style={{ minWidth: "350px" }}>{cat.rc_name || "ไม่พบ"}</div>
  //   );
  //   /* element["status"] = (
  //     <div style={{ }}>
  //       <div class="custom-control custom-switch custom-switch-on-success" style={{textAlign:"center"}}>
  //       <input
  //         type="checkbox"
  //         class="custom-control-input"
  //         id={"customSwitch" + (index + 1)}
  //         key={cat.rc_id}
  //         value={cat.rc_is_active}
  //         onChange={(e) => {
  //           ChangeStatus(cat.rc_id, e.target.checked)
  //           // getdataToggle(this)
  //         }}
  //         defaultChecked={cat.rc_is_active}
  //       ></input>
  //       <label
  //         class="custom-control-label"
  //         for={"customSwitch" + (index + 1)}
  //       ></label>
  //     </div>
  //     </div>
  //   );
  //   element["mgt"] = (
  //     <div className="row" style={{ flexWrap: "nowrap" }}>
  //       <div className="col-xl-3 col-md-3 col-xs-3 ">
  //         <a
  //           href={"/Human_Resource/company_regulations/Manage_message_types/view/" + cat.rc_id}
  //           id="btn_read"
  //           key={cat.rc_id + "view"}
  //           className="btn btn-xs "
  //         >
  //           <i class="fas fa-eye"></i>
  //           {"   "}
  //         </a>
  //       </div>
  //       <div className="col-xl-3 col-md-3 col-xs-3 ">
  //         <a
  //           href={"/Human_Resource/company_regulations/Manage_message_types/edit/" + cat.rc_id}
  //           id="btn_edit"
  //           key={cat.rc_id + "edit"}
  //           className=" btn btn-xs "
  //         >
  //           {"   "}
  //           <i class="fas fa-pencil-alt"></i>
  //           {"   "}
  //         </a>
  //       </div>
  //       <div className="col-xl-3 col-md-3 col-xs-3 ">
  //         <a
  //           key={cat.rc_id + "del"}
  //           id="btn_delete"
  //           className=" btn btn-xs "
  //           onClick={deldata.bind(this, cat.rc_id)}
  //         >
  //           <i class="fas fa-trash-alt"></i>
  //         </a>
  //       </div>
  //     </div>
  //   ); */
  //   dataUser.push(element);
  // }

  // const data = {
  //   columns: [
  //     {
  //       label: <div style={{ textAlign: "center" }}>No.</div>,
  //       field: "no",
  //       // sort: "asc",
  //       width: 50,
  //     },
  //     {
  //       label: <div style={{ textAlign: "center" }}>Name</div>,
  //       field: "image",
  //       // sort: "asc",
  //       width: 50,
  //     },
  //     {
  //       label: <div style={{ textAlign: "center" }}>Log</div>,
  //       field: "category",
  //       // sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: <div style={{ textAlign: "center" }}>MGT</div>,
  //       field: "mgt",
  //       // sort: "asc",
  //       width: 50,
  //     },
  //   ],
  //   rows: dataUser,
  // };

  useEffect(() => {
    GetTypeDashboardLog("");
    GetUserTotalDashboardLog("", true);
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Dashboard Log
                  {/*          Welcome {user.sub} */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">กฏระเบียบบริษัท</li>
                  <li className="breadcrumb-item active">Dashboard Log</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="row">
            <div className="col-12 col-xl-3 col-md-12">
              <div className="card">
                <div className="card-body">
                  <div
                    className="row"
                    style={{
                      margin: "0px 0px 15px 0px",
                      alignItems: "flex-end",
                    }}
                  >
                    <div
                      className="col-12"
                      style={{ "z-index": "99", alignContent: "center" }}
                    >
                      <label htmlFor="" style={{ marginBottom: "2px" }}>
                        Year
                      </label>
                      <div
                        className="form-group"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <Select
                          // className="form-control"
                          options={optionsWithAll}
                          value={optionsWithAll.find(
                            (el) => el.value === filterteam.year
                          )}
                          onChange={handleYearChange}
                          isSearchable
                          placeholder="เลือกปี..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div
                    className="row"
                    style={{
                      margin: "0px 0px 15px 0px",
                      alignItems: "flex-end",
                    }}
                  >
                    <div className="text-header-dashboard-log">
                      <h2 className="p-0 m-0" style={{ fontWeight: "900" }}>
                        ประเภทความไม่เรียบร้อย
                      </h2>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "90%" /* , minWidth: '200px' */,
                          maxWidth: "200px",
                          margin: "15px 0px",
                          // maxHeight: '500px',
                        }}
                      >
                        <Pie_Chart
                          legendPos={"bottom"}
                          dataChart={
                            data_chart_performance_by_mode_of_transport
                          }
                          title={""}
                          loading={loading_pie}
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ width: "100%", justifyContent: "center" }}
                    >
                      <div className="col-12">
                        <h3 style={{ textAlign: "center" }}>
                          รวมทั้งหมดของทุกคน
                        </h3>
                      </div>
                      {data_chart_performance_by_mode_of_transport?.labels?.map(
                        (el, index) => {
                          return (
                            <div
                              className="col-auto"
                              /* style={{
                                    display: "flex",
                                    gap: "5px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "default",
                                  }} */
                              onClick={() => {
                                const _data = [...filterPieFollowUp.data];
                                const index_data = _data.indexOf(index);
                                if (index_data != -1) {
                                  _data.splice(index_data, 1);
                                } else {
                                  _data.push(index);
                                }
                                setFilterPieFollowUp({
                                  data: _data,
                                });
                                filterPieFollowUp.data = _data;
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  // justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "default",
                                }}
                              >
                                <div
                                  style={{
                                    width: "15px",
                                    minWidth: "15px",
                                    height: "15px",
                                    borderRadius: "50px",
                                    backgroundColor:
                                      data_chart_performance_by_mode_of_transport
                                        .datasets[0]?.backgroundColor[index],
                                    display: "inline-block",
                                  }}
                                ></div>
                                <span
                                  style={{
                                    textDecorationLine:
                                      filterPieFollowUp.data.includes(index)
                                        ? "line-through"
                                        : "none",
                                  }}
                                >
                                  {el}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-9 col-md-12">
              <div className="card">
                <div className="card-body">
                  <div
                    className="row"
                    style={{
                      margin: "0px 0px 15px 0px",
                      alignItems: "flex-end",
                    }}
                  >
                    <div className="text-header-dashboard-log mb-3">
                      <h2 className="p-0 m-0" style={{ fontWeight: "900" }}>
                        User Total Log
                      </h2>
                    </div>
                    <div
                      className="table-responsive "
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <table
                        className="table-style-dashboard-log "
                        style={{ borderCollapse: "collapse", width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "80px" }}>No.</th>
                            <th>Name</th>
                            <th>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div style={{ width: "90%" }}>Log</div>
                                <div style={{ width: "10%" }}>
                                  {sortDirection === "asc" ? (
                                    <SortAscendingOutlined
                                      onClick={handleSort}
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "14px",
                                      }}
                                    />
                                  ) : (
                                    <SortDescendingOutlined
                                      onClick={handleSort}
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "14px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </th>
                            <th>MGT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userList.data.map((row, row_index) => (
                            <React.Fragment key={row.emp_id}>
                              <tr
                                className={
                                  row_index % 2 === 0
                                    ? "table-style-dashboard-log-tr table-style-dashboard-log-tr-hover"
                                    : "table-style-dashboard-log-tr-hover"
                                }
                              >
                                <td style={{ textAlign: "center" }}>
                                  {row_index + 1}
                                </td>
                                <td>{row.emp_name_th}</td>
                                <td style={{ textAlign: "center" }}>
                                  {row.log_list.length || 0}
                                </td>
                                <td
                                  onClick={() => handleRowClick(row.emp_id)}
                                  style={{
                                    cursor: "pointer",
                                    color: "#3579F6",
                                    textAlign: "center",
                                  }}
                                >
                                  {expandedRows.includes(row.emp_id) ? (
                                    <MinusSquareOutlined />
                                  ) : (
                                    <PlusSquareOutlined />
                                  )}
                                </td>
                              </tr>
                              {expandedRows.includes(row.emp_id) && (
                                <tr>
                                  <td
                                    colSpan="5"
                                    style={{ backgroundColor: "#fafafa" }}
                                  >
                                    <div
                                      className="card"
                                      style={{
                                        // border: '1px solid #F2F2F2',
                                        padding: "5px",
                                        boxShadow:
                                          "0 0 1px rgba(0,0,0,.125),0 1px 10px rgba(0,0,0,.2)",
                                      }}
                                    >
                                      {renderSubTable(row.log_list)}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                      {/* <MDBDataTable
                        sortable={false}
                        className="table table-head-fixed"
                        striped
                        bordered
                        hover
                        fixedHeader
                        data={data}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal */}
          <Modal
            centered
            isOpen={showModal}
            toggle={handleCloseModal}
            // style={{ width: "50%" }}
          >
            <ModalHeader
              style={{ border: "none" }}
              toggle={handleCloseModal}
            ></ModalHeader>
            <ModalBody>
              <div>
                <img
                  id="img"
                  alt="..."
                  className="img-fluid rounded shadow border-0"
                  src={
                    image.path !== "" &&
                    image.path !== null &&
                    image.path !== undefined
                      ? Configs.API_URL_IMG + image.path
                      : userdefault_img.imgs
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    objectFit: "cover",
                    display: "block",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  }}
                />
              </div>
            </ModalBody>
            {/* <ModalFooter>
                            <Button color="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </ModalFooter> */}
          </Modal>
        </section>
      </div>
    </div>
  );
}

export default DashboardLog;
