import React, { Component, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
  getFeature,
} from "../../../Utils/Common";
import Select from "react-select";
import { useParams } from "react-router";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";

function Employee_Help() {
  const { week, year } = useParams();

  const [autoScroll, setAutoScroll] = useState(false);

  const [checkDRAS, setCheckDRAS] = useState({
    bool: true,
  });

  const [initData, setInitData] = useState({
    currentWeek: 0,
    currentYear: 0,
    weekOfYear: 0,
  });

  const [searchData, setSearchData] = useState({
    emp_id: "",
    emp_type: "",
    year: "",
  });

  const [evaluateData, setEvaluateData] = useState({
    ori: [],
  });

  const [employeeHelpData, setEmployeeHelpData] = useState({
    ori: [],
  });

  const [employeeWorkTypeData, setEmployeeWorkTypeData] = useState({
    ori: [],
  });

  const [employeeData, setEmployeeData] = useState({
    ori: [],
    dropdown: [],
  });

  const [employeeType, setEmployeeType] = useState({
    dropdown: [],
  });

  const [formatDataTable, setFormatDataTable] = useState({
    ori: [],
  });

  const [totalColumnWidth, setTotalColumnWidth] = useState(0);

  async function autoScrollTable() {
    if (
      document.querySelectorAll(".MDBDataTable-responsive .row")?.[1]
        ?.scrollWidth
    ) {
      document.querySelectorAll(".MDBDataTable-responsive .row")[1].scrollLeft =
        document.querySelectorAll(".MDBDataTable-responsive .row")[1]
          .scrollWidth -
        (totalColumnWidth  + (initData.weekOfYear - (initData.currentWeek - 2)) * 55);
    }
  }
  
  const [isLabtop, setIsLabtop] = useState(window.innerWidth <= 1400);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsLabtop(window.innerWidth <= 1400);
      setIsTablet(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const rowsEmployeeHelp = [];

  function formatEmployeeHelp(index, data) {
    let rowItem = {};

    rowItem["no"] = index + 1;

    let text_emp_name = "";
    if (isTablet) {
      text_emp_name = `${data.emp_nickname} (${data.count_fail}) ${
        false &&
        data.evaluate.some((item) => item.year !== initData.currentYear)
          ? data.count_fail !== 0
            ? "(ส่งแล้ว)"
            : ""
          : data.sent_mail
          ? "(ส่งแล้ว)"
          : ""
      }`;
    } else {
      text_emp_name = `${data.emp_name_th} (${data.emp_nickname}) (${
        data.count_fail
      }) ${
        false &&
        data.evaluate.some((item) => item.year !== initData.currentYear)
          ? data.count_fail !== 0
            ? "(ส่งแล้ว)"
            : ""
          : data.sent_mail
          ? "(ส่งแล้ว)"
          : ""
      }`;
    }

    // let text_emp_name = `${data.emp_name_th} (${data.emp_nickname}) (${
    //   data.count_fail
    // }) ${
    //   data.evaluate.some((item) => item.year !== initData.currentYear)
    //     ? data.count_fail != 0
    //       ? "(ส่งแล้ว)"
    //       : ""
    //     : data.sent_mail
    //     ? "(ส่งแล้ว)"
    //     : ""
    // }`;

    rowItem["emp_name"] = (
      <div style={{ textAlign: "left" }}>{text_emp_name}</div>
    );
    rowItem["emp_name_search"] = text_emp_name;

    rowItem["emp_no"] = data.emp_no || "-";

    rowItem["emp_eva_point"] = data.count_point;

    for (const item_evaluate of data.evaluate) {
      if (
        item_evaluate.year !== initData.currentYear ||
        item_evaluate.week !== initData.currentWeek
      ) {
        if (
          item_evaluate.week_status_id ===
          "7a232969-76c8-4133-bc0a-62079d01d10b"
        ) {
          rowItem[`W${item_evaluate.week}`] = (
            <div className="EmployeeHelpColorBar">
              <div className="EmployeeHelpWeekGreen" />
            </div>
          );
        } else if (
          item_evaluate.week_status_id ===
          "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa"
        ) {
          rowItem[`W${item_evaluate.week}`] = (
            <div className="EmployeeHelpColorBar">
              <div className="EmployeeHelpWeekYellow" />
            </div>
          );
        } else if (
          item_evaluate.week_status_id ===
          "d4edf04e-94c3-4b26-aad8-62b0906bfc55"
        ) {
          if (data.count_fail == 0) {
            rowItem[`W${item_evaluate.week}`] = (
              <div className="EmployeeHelpColorBar">
                <div className="EmployeeHelpWeekRed" />
              </div>
            );
          } else {
            rowItem[`W${item_evaluate.week}`] = (
              <div className="EmployeeHelpColorBar">
                <div className="EmployeeHelpWeekRed EmployeeHelpRowRed" />
              </div>
            );
          }
        }
      }
    }

    const date_search = moment().format(
      `DD/MM/${searchData.year || initData.currentYear}`
    );
    if (
      moment(date_search, "DD/MM/YYYY")
        .startOf("year")
        .diff(moment(data.emp_start_work_day), "milliseconds") < 0
    ) {
      const week_loop =
        searchData.year !== moment().year() ? 52 : initData.currentWeek;
      for (let index2 = 1; index2 <= week_loop; index2++) {
        const weekStartDate = moment(date_search, "DD/MM/YYYY")
          .week(index2)
          .startOf("week");

        if (
          weekStartDate.diff(moment(data.emp_start_work_day), "milliseconds") <
          0
        ) {
          rowItem[`W${index2}`] = (
            <div
              id={`week-${index2}`}
              style={{ width: "100%", height: "28px" }}
            >
              <div className="test" style={{ height: "0px" }}></div>
            </div>
          );
        }
      }
    }

    return rowItem;
  }

  for (let index = 0; index < formatDataTable.ori.length; index += 1) {
    rowsEmployeeHelp.push(
      formatEmployeeHelp(index, formatDataTable.ori[index])
    );

    if (index == formatDataTable.ori.length) {
      setAutoScroll(true);
    }
  }

  const tableEmployeeHelp = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>ชื่อพนักงาน</div>,
        field: "emp_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน",
        field: "emp_no",
        // sort: "asc",
        width: 10,
      },
      {
        label: "คะแนน",
        field: "emp_eva_point",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsEmployeeHelp,
  };

  for (
    let index = 0;
    index < moment().year(moment(new Date()).year()).weeksInYear();
    index++
  ) {
    tableEmployeeHelp.columns.push({
      label: (
        <div id={`week-${index}`} style={{ width: "30px" }}>{`W${
          index + 1
        }`}</div>
      ),
      field: `W${index + 1}`,
      // sort: "asc",
      width: 50,
    });
  }

  const FilterEvaluate = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      employee_id: searchData.emp_id,
      year: searchData.year,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEvaluate",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        setEvaluateData({
          ori: response.data.data,
        });
        evaluateData.ori = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const FilterEmployeeHealth = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      employee_id: searchData.emp_id,
      week: initData.currentWeek - 1,
      year: searchData.year,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterEmployeeHealth",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        setEmployeeHelpData({
          ori: response.data.data,
        });
        employeeHelpData.ori = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const GetEmployeeWorkType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      getAll: true,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmpWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(async function (response) {
        const arrList = [];

        for (let item of response.data.data) {
          let temp = {
            id: item.id,
            contract_type_name: item.contract_type_name,
            sort: item.sort,
          };

          arrList.push(temp);
        }

        setEmployeeWorkTypeData({ ori: arrList });
        employeeWorkTypeData.ori = arrList;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetTTTemployeeTyer = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getEmployeeContractType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        const options = [{ value: "", label: "Select All" }];

        response.data.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.contract_type_name;
          options.push(element);
        });

        setEmployeeType({
          dropdown: options,
        });

        employeeType.dropdown = options;
      })
      .catch(function (error) {});
  };

  const FilterEmployee = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      resign_status: true,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        const empAll = [];

        for (let item of employeeWorkTypeData.ori) {
          let empEqualType = response.data.data.filter((empItem) => {
            return (
              empItem.emp_contract_type_id === item.id &&
              empItem.emp_status_type_id ===
                "6c4a34ff-f9d4-4f7e-91ae-5a90697b10cb"
            );
          });

          empEqualType.sort((a, b) => {
            if (a.emp_no === null) {
              return 1;
            }
            if (b.emp_no === null) {
              return -1;
            }
            if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
              return 0;
            }
            return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
          });

          empAll.push(...empEqualType);
        }

        const options = [{ value: "", label: "Select All" }];

        empAll.forEach((el) => {
          var element = {};
          element["value"] = el.emp_id;
          element["label"] = `${el.emp_name_th || ""} ${
            el.emp_nickname ? "( " + el.emp_nickname + " )" : ""
          } `;
          options.push(element);
        });

        setEmployeeData({
          ori: empAll,
          dropdown: options,
        });

        employeeData.ori = empAll;
        employeeData.dropdown = options;
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  async function formatSearchData() {
    setCheckDRAS({
      bool: false,
    });
    checkDRAS.bool = false;

    await FilterEvaluate();
    await FilterEmployeeHealth();

    let temp_emp = JSON.parse(JSON.stringify(employeeData.ori));

    if (searchData.emp_id) {
      temp_emp = employeeData.ori.filter((item) => {
        return item.emp_id === searchData.emp_id;
      });
    } else if (searchData.emp_type) {
      temp_emp = employeeData.ori.filter((item) => {
        return item.emp_contract_type_id === searchData.emp_type;
      });
    }

    for (let index = 0; index < temp_emp.length; index++) {
      let temp_employeeHelp = employeeHelpData.ori.filter((item) => {
        return item.sm_emp_id === temp_emp[index].emp_id;
      });

      temp_emp[index]["sent_mail"] = temp_employeeHelp[0]?.sm_is_sent;

      let countFail = 0;
      let countPoint = 0;
      let temp_evaluate = evaluateData.ori.filter((item) => {
        return item.employee_id === temp_emp[index].emp_id;
      });

      for (const item_evaluate of temp_evaluate) {
        if (
          (item_evaluate.year !== initData.currentYear ||
            item_evaluate.week !== initData.currentWeek) &&
          item_evaluate.week > 0
        ) {
          if (
            item_evaluate.week_status_id ===
            "d4edf04e-94c3-4b26-aad8-62b0906bfc55"
          ) {
            countFail += 1;
          } else if (
            item_evaluate.week_status_id ===
              "7a232969-76c8-4133-bc0a-62079d01d10b" ||
            item_evaluate.week_status_id ===
              "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa"
          ) {
            countFail = 0;
          }

          if (
            item_evaluate.week_status_id ===
            "7a232969-76c8-4133-bc0a-62079d01d10b"
          ) {
            countPoint += 3;
          } else if (
            item_evaluate.week_status_id ===
            "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa"
          ) {
            countPoint += 1;
          } else if (
            item_evaluate.week_status_id ===
            "d4edf04e-94c3-4b26-aad8-62b0906bfc55"
          ) {
            countPoint -= 1;
          }
        }
      }

      temp_emp[index]["evaluate"] = temp_evaluate;
      temp_emp[index]["count_fail"] = countFail;
      temp_emp[index]["count_point"] = countPoint;
    }

    const temp_emp_fail = temp_emp.filter((item) => {
      return item.count_fail !== 0;
    });

    const temp_emp_no_fail = temp_emp.filter((item) => {
      return item.count_fail == 0;
    });

    temp_emp_fail.sort((a, b) => b.count_fail - a.count_fail);
    temp_emp_no_fail.sort((a, b) => a.count_point - b.count_point);

    setFormatDataTable({
      ori: [...temp_emp_fail, ...temp_emp_no_fail],
    });
    formatDataTable.ori = [...temp_emp_fail, ...temp_emp_no_fail];

    setCheckDRAS({
      bool: true,
    });
    checkDRAS.bool = true;
  }

  async function clearSearchData() {
    setSearchData({
      emp_id: "",
      emp_type: "",
      year: moment(new Date()).year(),
    });
    searchData.emp_id = "";
    searchData.emp_type = "";
    searchData.year = moment(new Date()).year();

    await formatSearchData();
  }

  useEffect(async () => {
    setInitData({
      // ...initData,
      currentWeek: moment(new Date()).isoWeekday(1).week(),
      currentYear: moment(new Date()).year(),
      weekOfYear: moment().year(moment(new Date()).year()).weeksInYear(),
    });
    initData.currentWeek = moment(new Date()).isoWeekday(1).week();
    initData.currentYear = moment(new Date()).year();
    initData.weekOfYear = moment()
      .year(moment(new Date()).year())
      .weeksInYear();

    setSearchData({
      ...searchData,
      year: moment(new Date()).year(),
    });
    searchData.year = moment(new Date()).year();

    // await FilterEvaluate();
    // await FilterEmployeeHealth();
    await GetEmployeeWorkType();
    await FilterEmployee();
    await formatSearchData();
    await GetTTTemployeeTyer();

    setAutoScroll(true);
    // const testElement = document.querySelectorAll(".test");
    // for(let item of testElement) {
    //   if (item && item.parentElement) {
    //     console.log('item', item)
    //     item.parentElement.style.backgroundColor = "grey";
    //   }
    // }
  }, []);

  useEffect(async () => {
    await autoScrollTable();
  }, [autoScroll]);

  const setColumnStyles = () => {
    const firstColumnHeaders = document.querySelectorAll(
      ".EmployeeHelpTableRowRed th:nth-child(1), .EmployeeHelpTableRowRed td:nth-child(1)"
    );
    const secondColumnHeaders = document.querySelectorAll(
      ".EmployeeHelpTableRowRed th:nth-child(2), .EmployeeHelpTableRowRed td:nth-child(2)"
    );
    const thirdColumnHeaders = document.querySelectorAll(
      ".EmployeeHelpTableRowRed th:nth-child(3), .EmployeeHelpTableRowRed td:nth-child(3)"
    );
    const fourthColumnHeaders = document.querySelectorAll(
      ".EmployeeHelpTableRowRed th:nth-child(4), .EmployeeHelpTableRowRed td:nth-child(4)"
    );

    const firstColumnWidth = document.querySelector(
      ".EmployeeHelpTableRowRed th:nth-child(1)"
    ).offsetWidth;
    const secondColumnWidth = document.querySelector(
      ".EmployeeHelpTableRowRed th:nth-child(2)"
    ).offsetWidth;
    const thirdColumnWidth = document.querySelector(
      ".EmployeeHelpTableRowRed th:nth-child(3)"
    ).offsetWidth;
    const fourthColumnWidth = document.querySelector(
      ".EmployeeHelpTableRowRed th:nth-child(4)"
    ).offsetWidth;

    const totalWidth = firstColumnWidth + secondColumnWidth + thirdColumnWidth + fourthColumnWidth;

    setTotalColumnWidth(totalWidth);

    firstColumnHeaders.forEach((element) => {
      element.style.position = isTablet ? "sticky" : "sticky";
      element.style.left = "-2px";
      element.style.width = `${firstColumnWidth}px`;
      element.style.minWidth = `${firstColumnWidth}px`;
      // element.style.border = '1px solid red'
      // element.style.boxShadow = 'inset 1px 0 0 0 #f0f0f0';
    });

    secondColumnHeaders.forEach((element) => {
      element.style.position = isTablet ? "sticky" : "sticky";
      element.style.left = `${firstColumnWidth - 2}px`;
      element.style.width = `${secondColumnWidth}px`;
      element.style.minWidth = `${secondColumnWidth}px`;
    });

    thirdColumnHeaders.forEach((element) => {
      element.style.position = isTablet ? "" : "sticky";
      element.style.left = `${firstColumnWidth + secondColumnWidth - 2}px`;
      element.style.width = `${thirdColumnWidth}px`;
      element.style.minWidth = `${thirdColumnWidth}px`;
    });

    fourthColumnHeaders.forEach((element) => {
      element.style.position = isTablet ? "" : "sticky";
      element.style.left = `${
        firstColumnWidth + secondColumnWidth + thirdColumnWidth - 2
      }px`;
      // element.style.boxShadow = "-1px 0 0 0 blue";
    });
  };

  function repageTable(event) {
    const newValue = event.target.value;
    setTimeout(() => {
      // console.log('newValue :>> ', newValue);
      setCheckDRAS({
        bool: false,
      });
      checkDRAS.bool = false;
      setTimeout(() => {
        setCheckDRAS({
          bool: true,
        });
        checkDRAS.bool = true;
      }, 3);
    }, 3);
  }

  useEffect(() => {
    const selectElement = document.querySelector(
      ".Employee_Help .dataTables_length select"
    );
    if (selectElement) {
      selectElement.addEventListener("change", repageTable);
    }

    return () => {
      if (selectElement) {
        selectElement.removeEventListener("change", repageTable);
      }
    };
  }, []);

  let mount_page_first = useRef(false);
  useEffect(() => {
    if (mount_page_first.current) {
      setColumnStyles();
    }
  }, [isTablet]);

  useEffect(() => {
    const tableElement = document.querySelector(
      ".EmployeeHelpTableRowRed tbody"
    );

    const observer = new MutationObserver(() => {
      setColumnStyles();

      const element = document.querySelector(".content-wrapper");
      if (element) {
        element.style.minHeight = "";
      }
    });

    if (tableElement) {
      observer.observe(tableElement, {
        childList: true, // Detect changes in rows
        subtree: true, // Include nested children
      });
    }

    mount_page_first.current = true;

    return () => {
      observer.disconnect();
    };
  }, []);

  // useEffect(() => {
  //   const paginationButtons = document.querySelectorAll(
  //     ".pagination li a, .pagination li span"
  //   );

  //   paginationButtons.forEach((button) => {
  //     button.addEventListener("click", handlePageChange);
  //   });

  //   return () => {
  //     paginationButtons.forEach((button) => {
  //       button.removeEventListener("click", handlePageChange);
  //     });
  //   };
  // }, []);

  // const handlePageChange = () => {
  //   setTimeout(() => {
  //     setColumnStyles();
  //   }, 20);
  // };

  // useEffect(() => {
  //   const selectElement = document.querySelector(".dataTables_length select");
  //   if (selectElement) {
  //     selectElement.addEventListener("change", handleShowEntriesChange);
  //   }

  //   return () => {
  //     if (selectElement) {
  //       selectElement.removeEventListener("change", handleShowEntriesChange);
  //     }
  //   };
  // }, []);

  // const handleShowEntriesChange = () => {
  //   setTimeout(() => {
  //     formatSearchData();
  //   }, 50);
  // };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Employee Health
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Task</li>
                  <li className="breadcrumb-item active">Employee Health</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-3">
                <div className="form-group" style={{ zIndex: "10" }}>
                  <Select
                    options={employeeData.dropdown}
                    filterOption={(option, searchText) => {
                      if (
                        option.data.label
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }}
                    value={employeeData.dropdown.filter((e) => {
                      return e.value === searchData.emp_id;
                    })}
                    onChange={(e) => {
                      setSearchData({
                        ...searchData,
                        emp_id: e.value,
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อพนักงาน</label>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group" style={{ zIndex: "9" }}>
                  <Select
                    options={employeeType.dropdown}
                    filterOption={(option, searchText) => {
                      if (
                        option.data.label
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }}
                    value={employeeType.dropdown.filter((e) => {
                      return e.value === searchData.emp_type;
                    })}
                    onChange={(e) => {
                      setSearchData({
                        ...searchData,
                        emp_type: e.value,
                      });
                    }}
                  />
                  <label htmlFor="">ประเภทพนักงานพนักงาน</label>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-3">
                <div className="row">
                  <div className="col-2">
                    <div className="form-group">
                      <button
                        type="button"
                        id="btn_clear"
                        className="btn btn-block btn-primary"
                        style={{ width: "auto" }}
                        onClick={async () => {
                          setSearchData({
                            ...searchData,
                            year: Number(searchData.year) - 1,
                          });
                          searchData.year = Number(searchData.year) - 1;

                          await formatSearchData();
                        }}
                      >
                        <span
                          className="fa fa-chevron-left"
                          style={{ verticalAlign: "middle" }}
                        />
                      </button>
                    </div>
                  </div>

                  <div className="col-8">
                    <div className="row">
                      <div className="col">
                        <div
                          style={{
                            position: "relative",
                            padding: "10px 0px",
                            marginBottom: "1rem",
                            textAlign: "center",
                          }}
                        >
                          <label
                            style={{
                              fontSize: "1.4rem",
                              margin: "4px 0px 0px 0px",
                            }}
                          >
                            Year
                          </label>
                        </div>
                      </div>

                      <div className="col">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            style={{
                              textAlign: "center",
                            }}
                            onChange={(e) => {
                              setSearchData({
                                ...searchData,
                                year: e.target.value,
                              });
                              searchData.year = e.target.value;
                            }}
                            value={searchData.year}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-2"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div className="form-group">
                      <button
                        type="button"
                        id="btn_clear"
                        className="btn btn-block btn-primary"
                        style={{ width: "auto" }}
                        onClick={async () => {
                          setSearchData({
                            ...searchData,
                            year: Number(searchData.year) + 1,
                          });
                          searchData.year = Number(searchData.year) + 1;

                          await formatSearchData();
                        }}
                      >
                        <span
                          className="fa fa-chevron-right"
                          style={{ verticalAlign: "middle" }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-primary"
                    onClick={formatSearchData}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-primary"
                    onClick={clearSearchData}
                  >
                    Clear
                  </button>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-danger"
                    onClick={() => {
                      window.location.href = `/Human_Resource/to_do_list/task_work/${week}/${year}`;
                    }}
                  >
                    ย้อนกลับ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title"></h3> */}
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12 Employee_Help">
                  <div style={{ whiteSpace: "nowrap" }}>
                    <MDBDataTable
                      className="MDBDataTable-responsive MDBDataTable-center EmployeeHelpTableRowRed"
                      sortable={false}
                      // striped
                      bordered
                      hover
                      fixedHeader
                      disableRetreatAfterSorting={checkDRAS.bool}
                      data={tableEmployeeHelp}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer" />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Employee_Help;
