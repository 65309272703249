import React, { Component, useEffect, useState } from "react";
// import { getOem, getToken, getUser, getAcademy, getComConfig } from "../../Utils/Common";
import { getOem, getToken, getUser, getAcademy, getComConfig, getnamecompanee } from "../../../../Utils/Common";
import DatePicker from "react-datepicker";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
// import Configs from "../../config";
import Configs from "../../../../config";
import NumberFormat from "react-number-format";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import Tooling_report from '../../../../../src/component/Report/Tooling_po_report'
import Perchase_report from '../../../../../src/component/Report/Perchase_po_report'
import Perchase_po_address from "../../../../component/Report/Perchase_po_address";

function Perchase_order() {
    const [MDBT, setMDBT] = useState(true);
    const [test, setTest] = useState({
        test: ''
    })
    const [perchasePOFilter, setPerchasePOFilter] = useState({
        t_po_no: '',
        t_supplier_id: '',
        t_start_date: '',
        t_end_date: '',
    });
    const [perchase_po, setPerchase_po] = useState([]);
    const [supplier_option, setsupplier_option] = useState([]);
    const [receiveStatus_option, setReceiveStatus_option] = useState([]);


    var config_receive = {
        method: "get",
        url: Configs.API_URL + "/tooling/getToolingReceiveStatus",
        headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
        },
    };

    useEffect(async () => {
        // console.log('first', getComConfig());
        const tempData = {
            user_id: getUser().emp_id,
            company_id: getUser().com,
            oem_id: getOem(),
        }
        axios({
            method: "POST",
            url: Configs.API_URL_incentive + "/api/incentivePo/getSupplierIncentive/" /* + getUser().com */,
            headers: {
                Authorization: 'Bearer ' + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: tempData,
        })
            .then(function (response) {
                console.log('response', response.data.data);
                const temp = response.data.data;
                const options = [{ value: "", label: "Select All" }];
                for (let el of temp) {
                    var element = {};
                    element["value"] = el.sup_id;
                    element["label"] = el.sup_abbr_name;
                    options.push(element);
                };
                setsupplier_option(options);
                setTest({ ...test, test: '' });
                test.test = "";
                console.log('supplier_option', supplier_option);
            })
            .catch(function (error) {
                console.log(error);
            });

        /* axios(config_receive)
            .then(function (response) {

                const options = [{ value: "", label: "Select All" }];
                response.data.forEach((el) => {
                    var element = {};
                    element["value"] = el.id;
                    element["label"] = el.name;
                    options.push(element);
                });
                setReceiveStatus_option(options);
            })
            .catch(function (error) {
                console.log(error);
            }); */
        FilterToolingPO();
    }, []);

    async function SetDate_start(date) {
        console.log('data', date);
        setPerchasePOFilter({ ...perchasePOFilter, t_start_date: date });
    }
    async function SetDate_end(date) {
        setPerchasePOFilter({ ...perchasePOFilter, t_end_date: date });
    }

    const Clearfilter = async () => {
        setPerchasePOFilter({
            t_po_no: '',
            t_supplier_id: '',
            t_start_date: '',
            t_end_date: ''
        });
        perchasePOFilter.t_po_no = '';
        perchasePOFilter.t_supplier_id = '';
        perchasePOFilter.t_start_date = '';
        perchasePOFilter.t_end_date = '';
        setTest({ ...test, test: '' });
        test.test = '';
        setTest({ ...test, test: '' });
        FilterToolingPO();
    }

    const FilterToolingPO = async () => {

        let start = null;//new Date(Date.UTC(new Date().getFullYear(), 0, 1));
        let end = null;//new Date(Date.UTC(new Date().getFullYear(), 11, 31));
        if (perchasePOFilter.t_start_date !== '' && perchasePOFilter.t_end_date !== '') {
            start = new Date(moment(perchasePOFilter.t_start_date).add(1, 'days')); //.add(1, 'days')
            start.setUTCHours(0, 0, 0, 0);
            end = new Date(moment(perchasePOFilter.t_end_date).add(1, 'days'));
            end.setUTCHours(23, 59, 59, 999);
        } else if (perchasePOFilter.t_start_date !== '') {
            start = new Date(moment(perchasePOFilter.t_start_date).add(1, 'days')); //.add(1, 'days')
            start.setUTCHours(0, 0, 0, 0);
            end = new Date(Date.UTC(new Date().getFullYear(), 11, 31));
            end.setUTCHours(23, 59, 59, 999);
        } else if (perchasePOFilter.t_end_date !== '') {
            end = new Date(moment(perchasePOFilter.t_end_date).add(1, 'days'));
            end.setUTCHours(23, 59, 59, 999);
            start = new Date(Date.UTC(new Date().getFullYear(), 0, 1));
            start.setUTCHours(0, 0, 0, 0);
        }
        /* if (perchasePOFilter.t_end_date !== '') {
        } */
        console.log('ter');
        let pushData = {
            user_id: getUser().emp_id,
            company_id: getUser().com,
            oem_id: getOem(),
            t_po_no: perchasePOFilter.t_po_no.trim() || null,
            t_supplier: perchasePOFilter.t_supplier_id || null,
            t_start_date: perchasePOFilter.t_start_date || null,// start,
            t_end_date: perchasePOFilter.t_end_date || null,// end,
        };
        console.log('pushData', pushData);
        axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/incentivePo/getPerchasePO",
            headers: {
                Authorization: 'Bearer ' + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: pushData,
        })
            .then(async function (response) {
                console.log('response', response.data.AlldataList);
                const arrToolingPO = [];
                const arrSupplierTooling = [];
                const res = response.data.AlldataList;
                for (let item of res) {
                    arrToolingPO.push(item);
                    arrSupplierTooling.push(item);
                }
                setPerchase_po(arrToolingPO);

            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
        // });
    };

    function numberWithCommas(x) {
        return (
            <NumberFormat
                thousandSeparator={true}
                decimalScale={2}
                value={x || 0}
                displayType="text"
            />
        );
        /*  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
    }

    const Custom_Input_date = ({ value, onClick }) => (
        <div className="input-group">
            <input
                type="text"
                className="form-control float-left"
                onClick={onClick}
                value={value}
            />{" "}
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <i className="far fa-calendar-alt" />
                </span>
            </div>
        </div>
    );

    const [company, setcompany] = useState();
    var config_com = {
        method: "get",
        url: Configs.API_URL + "/company/findById?id=" + getUser().com,
        headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
        },
    };

    useEffect(() => {
        axios(config_com)
            .then(function (response) {
                /*  console.log(response.data); */
                setcompany(response.data);
            }).catch(function (error) {
                console.log(error);
            });
        // Getmaterial_order();
    }, []);
    const [toolingReport, setToolingReport] = useState([]);
    function generateReport(e, status, idx, event) {
        console.log('e', e);
        console.log('idx', idx);
        console.log('event', event);
        console.log('status', status);
        const data = {
            poList: e.poList,
            fup: getUser().emp_id,
            company_id: getUser().com,
             oem_id: getOem(),
        }
        axios({
            method: "post",
            url:
                Configs.API_URL_incentive + "/api/incentivePo/getPerchaseDataForReport",
            headers: {
                Authorization: "Bearer " + getToken(),
                "Content-Type": "application/json",
                "X-TTT": Configs.API_TTT,
            },
            data: data,
        })
            .then(function (response) {
                console.log('response.data', response.data);
                setMDBT(true);
                /* setToolingReport(response.data);
                // for (let item of response.data.tooling_receive) 
                //     console.log('item.buy_price', item.buy_price);
                setTest({ ...test, test: '' });

                Perchase_report(company, response.data, getComConfig()); */
                if (status === true) {
                    SendEmailAndFilePDF(response.data, idx);
                } else {
                    Perchase_report(company, response.data, getComConfig());
                    Perchase_po_address(response.data, company);
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });

    }

    const testReport = async (mode) => {
        const item2 = [
            {
                "id": "a1521cbe-699b-4fae-a38b-ddf180fa2bd9",
                "no": "05515-5080-0",
                "product_model_id": "07266859-77ea-40a6-9500-52a88e68a039",
                "name": "PIN  SNAP",
                "surface": "Zn-C",
                "qty_unit": 1,
                "user_id": "304535a2-b927-45f1-ba00-f20c546ac26a",
                "oem_id": "6befefef-5f0c-4c10-8b92-00a2d7fb2e8c",
                "wip_amount": null,
                "fg_amount": 700,
                "min_product": 500,
                "end_user_amount": null,
                "logistic_amount": null,
                "image": "{\"orgin_name\":\"Screenshot_2.png\",\"name\":\"38c37aab-9184-40e8-9cb7-b22ce299a933_Screenshot_2.png\",\"path\":\"Li91cGxvYWRzLzA1NTE1LTUwODAtMC8zOGMzN2FhYi05MTg0LTQwZTgtOWNiNy1iMjJjZTI5OWE5MzNfU2NyZWVuc2hvdF8yLnBuZw==\",\"mimetype\":\"image/png\",\"size\":8251}",
                "description": "Kubota (SKCa)",
                "sell_price": 1.19,
                "unit": null,
                "circle_time": null,
                "sku_user": null,
                "product_route_id": null,
                "product_size_id": null,
                "product_color_id": null,
                "image_name": "Screenshot_2.png",
                "imaget_path": "Li91cGxvYWRzLzA1NTE1LTUwODAtMC8zOGMzN2FhYi05MTg0LTQwZTgtOWNiNy1iMjJjZTI5OWE5MzNfU2NyZWVuc2hvdF8yLnBuZw==",
                "product_material_id": null,
                "cut_amount": null,
                "is_assy": false,
                "product_plants_item": null,
                "is_trade": false,
                "stp_type": null,
                "width": null,
                "height": null,
                "blade_head": null,
                "img_qr": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAB0CAYAAABUmhYnAAAAAXNSR0IArs4c6QAABNBJREFUeF7tnduO4zAMQ5v//+gOsG9xgRwQlJTEy3lVbEmkKF+ado7v9/v95G8bBI4Qug2X/xIJoXvxGUI34zOEhtDdENgsn6yhIXQzBDZLJwoNoZshsFk6UWgI3QyBzdKJQkPoZghslk4UGkI3Q2CzdGyFHscxCon78e0aL833uvzcz0Nfl/BSgCF00WMIrW1QVGDkLS0X3sB5XcFWt1y3wtYKpDWPAHfjIf+kGLJXz1+uUBfAEOq9sxdCQULVClILlhT+M19a7jVkIXTZZNAat8K5tmwXUBpfbSdFVec3rtAQeoY8hIoXAeoaFYXCxQJV4HRLqibsaflt33KJwG4FTxdsCDU7TBQqfhpDgNHFRRRKCJkH7+ldbgh9OaFE4LQ9a+iCgNpypwmj+EJoCD0hYDZM/+uEqkKqK1j1Twpz7dX50XzjxxY1IPWcSPN3E07+yU7x0fgQCh8eqApVAa8u2BAaQq9rsLplVFcwxefat1eomyCNV1uiS5h6MULxk/1xu1wK2LWHUOiY1a+guITR+BAaQi8P7mpLpoJz7be3XDeB6vHumucCWp2POp/9GqfqsPv5EPr2klwqJISG0Ms1trujVM9vt1x1U1G9S6WLCALMrWfKn+Jz/Y9f/VHCrp0AC6GEAKxZ1QokwkPoGYG0XPMX2t2Ce1zLFQX9UXehpHjVvwugG78ar/q8rVDZYfFrnar/EKoiBs+7Fa6OX8MJoSH0hIBaUG4BqfDbLZc2BS4ANN4FjOKnXfTj1vjqj8/cBNXxIfTmYwu1kBD68B/NoJZJmxYaH4UWK5QU565BrmLdNZLyo4LqLsjyu1xKOIRe/7glFYSMr7spkh3CbyaQotSKp/mo4Cg/IkSNl/yR3T62kAMCjFoq2dU12CWA/FG+6ngZX1ehrgK6Aab4yK4CSoRSgRIeFI+tUBUQ93lKSAVMjYf8h9DinzcNoabG1Qp3nyeFhFCTUAK42067SPLvFoC6yVELmuK//RyqBkjPh9AzQvamiADvtofQEHpCIC13kZyrEFXB7pKvrmFqfm6B2PlVXyyoBKnP2wmLP9caQlWGxOdD6DVg9qZIrWCRv5/HQ+gwoS7g6tUZPX93AanxufiVK9QNiACg+as7BvlTC4bic/2FUGDEBZgKVL1pogIKoSF0uZkQ30CgCnPPcTS/qgj13KoqUo2H8mtXKK0ZlBABqs5P/ogQtQWr8anz/8RbfbFACqMKo/Fkp/lDKCBUrSAijOwh1NT43YQSgRSfOp4UTv7ITvGQ/fVrKCYo3t3SGhpCi7/gqzYUVxG0qaElQLVTgZI9ChX3CFFos0JJga4dFVHc0tUOtP2xRW1xRHgIHf4Pv0SIaw+hIZRq4GSngpMm+3z6/xGPGhAdG9Q1xgWMxtMuWM1fzW98DVUTCqEP/0p+CNUQiELFc6QK2H/fcrV61J+mYwkd/NWWrq6RFJ9qVxEqvylSA1CfJ0BCqNqDFsTUClYJJILIP6VX3VKp4FS7ilcU+rBXaKgAiWCbUHIQ+ywCIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73Zvf81zYw7mrrQcAAAAAElFTkSuQmCC"
            },
            {
                "id": "a1521cbe-699b-4fae-a38b-ddf180fa2bd9",
                "no": "05515-5080-0",
                "product_model_id": "07266859-77ea-40a6-9500-52a88e68a039",
                "name": "PIN  SNAP",
                "surface": "Zn-C",
                "qty_unit": 1,
                "user_id": "304535a2-b927-45f1-ba00-f20c546ac26a",
                "oem_id": "6befefef-5f0c-4c10-8b92-00a2d7fb2e8c",
                "wip_amount": null,
                "fg_amount": 700,
                "min_product": 500,
                "end_user_amount": null,
                "logistic_amount": null,
                "image": "{\"orgin_name\":\"Screenshot_2.png\",\"name\":\"38c37aab-9184-40e8-9cb7-b22ce299a933_Screenshot_2.png\",\"path\":\"Li91cGxvYWRzLzA1NTE1LTUwODAtMC8zOGMzN2FhYi05MTg0LTQwZTgtOWNiNy1iMjJjZTI5OWE5MzNfU2NyZWVuc2hvdF8yLnBuZw==\",\"mimetype\":\"image/png\",\"size\":8251}",
                "description": "Kubota (SKCa)",
                "sell_price": 1.19,
                "unit": null,
                "circle_time": null,
                "sku_user": null,
                "product_route_id": null,
                "product_size_id": null,
                "product_color_id": null,
                "image_name": "Screenshot_2.png",
                "imaget_path": "Li91cGxvYWRzLzA1NTE1LTUwODAtMC8zOGMzN2FhYi05MTg0LTQwZTgtOWNiNy1iMjJjZTI5OWE5MzNfU2NyZWVuc2hvdF8yLnBuZw==",
                "product_material_id": null,
                "cut_amount": null,
                "is_assy": false,
                "product_plants_item": null,
                "is_trade": false,
                "stp_type": null,
                "width": null,
                "height": null,
                "blade_head": null,
                "img_qr": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAB0CAYAAABUmhYnAAAAAXNSR0IArs4c6QAABNBJREFUeF7tnduO4zAMQ5v//+gOsG9xgRwQlJTEy3lVbEmkKF+ado7v9/v95G8bBI4Qug2X/xIJoXvxGUI34zOEhtDdENgsn6yhIXQzBDZLJwoNoZshsFk6UWgI3QyBzdKJQkPoZghslk4UGkI3Q2CzdGyFHscxCon78e0aL833uvzcz0Nfl/BSgCF00WMIrW1QVGDkLS0X3sB5XcFWt1y3wtYKpDWPAHfjIf+kGLJXz1+uUBfAEOq9sxdCQULVClILlhT+M19a7jVkIXTZZNAat8K5tmwXUBpfbSdFVec3rtAQeoY8hIoXAeoaFYXCxQJV4HRLqibsaflt33KJwG4FTxdsCDU7TBQqfhpDgNHFRRRKCJkH7+ldbgh9OaFE4LQ9a+iCgNpypwmj+EJoCD0hYDZM/+uEqkKqK1j1Twpz7dX50XzjxxY1IPWcSPN3E07+yU7x0fgQCh8eqApVAa8u2BAaQq9rsLplVFcwxefat1eomyCNV1uiS5h6MULxk/1xu1wK2LWHUOiY1a+guITR+BAaQi8P7mpLpoJz7be3XDeB6vHumucCWp2POp/9GqfqsPv5EPr2klwqJISG0Ms1trujVM9vt1x1U1G9S6WLCALMrWfKn+Jz/Y9f/VHCrp0AC6GEAKxZ1QokwkPoGYG0XPMX2t2Ce1zLFQX9UXehpHjVvwugG78ar/q8rVDZYfFrnar/EKoiBs+7Fa6OX8MJoSH0hIBaUG4BqfDbLZc2BS4ANN4FjOKnXfTj1vjqj8/cBNXxIfTmYwu1kBD68B/NoJZJmxYaH4UWK5QU565BrmLdNZLyo4LqLsjyu1xKOIRe/7glFYSMr7spkh3CbyaQotSKp/mo4Cg/IkSNl/yR3T62kAMCjFoq2dU12CWA/FG+6ngZX1ehrgK6Aab4yK4CSoRSgRIeFI+tUBUQ93lKSAVMjYf8h9DinzcNoabG1Qp3nyeFhFCTUAK42067SPLvFoC6yVELmuK//RyqBkjPh9AzQvamiADvtofQEHpCIC13kZyrEFXB7pKvrmFqfm6B2PlVXyyoBKnP2wmLP9caQlWGxOdD6DVg9qZIrWCRv5/HQ+gwoS7g6tUZPX93AanxufiVK9QNiACg+as7BvlTC4bic/2FUGDEBZgKVL1pogIKoSF0uZkQ30CgCnPPcTS/qgj13KoqUo2H8mtXKK0ZlBABqs5P/ogQtQWr8anz/8RbfbFACqMKo/Fkp/lDKCBUrSAijOwh1NT43YQSgRSfOp4UTv7ITvGQ/fVrKCYo3t3SGhpCi7/gqzYUVxG0qaElQLVTgZI9ChX3CFFos0JJga4dFVHc0tUOtP2xRW1xRHgIHf4Pv0SIaw+hIZRq4GSngpMm+3z6/xGPGhAdG9Q1xgWMxtMuWM1fzW98DVUTCqEP/0p+CNUQiELFc6QK2H/fcrV61J+mYwkd/NWWrq6RFJ9qVxEqvylSA1CfJ0BCqNqDFsTUClYJJILIP6VX3VKp4FS7ilcU+rBXaKgAiWCbUHIQ+ywCIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73Zvf81zYw7mrrQcAAAAAElFTkSuQmCC"
            },
            {
                "id": "a1521cbe-699b-4fae-a38b-ddf180fa2bd9",
                "no": "05515-5080-0",
                "product_model_id": "07266859-77ea-40a6-9500-52a88e68a039",
                "name": "PIN  SNAP",
                "surface": "Zn-C",
                "qty_unit": 1,
                "user_id": "304535a2-b927-45f1-ba00-f20c546ac26a",
                "oem_id": "6befefef-5f0c-4c10-8b92-00a2d7fb2e8c",
                "wip_amount": null,
                "fg_amount": 700,
                "min_product": 500,
                "end_user_amount": null,
                "logistic_amount": null,
                "image": "{\"orgin_name\":\"Screenshot_2.png\",\"name\":\"38c37aab-9184-40e8-9cb7-b22ce299a933_Screenshot_2.png\",\"path\":\"Li91cGxvYWRzLzA1NTE1LTUwODAtMC8zOGMzN2FhYi05MTg0LTQwZTgtOWNiNy1iMjJjZTI5OWE5MzNfU2NyZWVuc2hvdF8yLnBuZw==\",\"mimetype\":\"image/png\",\"size\":8251}",
                "description": "Kubota (SKCa)",
                "sell_price": 1.19,
                "unit": null,
                "circle_time": null,
                "sku_user": null,
                "product_route_id": null,
                "product_size_id": null,
                "product_color_id": null,
                "image_name": "Screenshot_2.png",
                "imaget_path": "Li91cGxvYWRzLzA1NTE1LTUwODAtMC8zOGMzN2FhYi05MTg0LTQwZTgtOWNiNy1iMjJjZTI5OWE5MzNfU2NyZWVuc2hvdF8yLnBuZw==",
                "product_material_id": null,
                "cut_amount": null,
                "is_assy": false,
                "product_plants_item": null,
                "is_trade": false,
                "stp_type": null,
                "width": null,
                "height": null,
                "blade_head": null,
                "img_qr": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAB0CAYAAABUmhYnAAAAAXNSR0IArs4c6QAABNBJREFUeF7tnduO4zAMQ5v//+gOsG9xgRwQlJTEy3lVbEmkKF+ado7v9/v95G8bBI4Qug2X/xIJoXvxGUI34zOEhtDdENgsn6yhIXQzBDZLJwoNoZshsFk6UWgI3QyBzdKJQkPoZghslk4UGkI3Q2CzdGyFHscxCon78e0aL833uvzcz0Nfl/BSgCF00WMIrW1QVGDkLS0X3sB5XcFWt1y3wtYKpDWPAHfjIf+kGLJXz1+uUBfAEOq9sxdCQULVClILlhT+M19a7jVkIXTZZNAat8K5tmwXUBpfbSdFVec3rtAQeoY8hIoXAeoaFYXCxQJV4HRLqibsaflt33KJwG4FTxdsCDU7TBQqfhpDgNHFRRRKCJkH7+ldbgh9OaFE4LQ9a+iCgNpypwmj+EJoCD0hYDZM/+uEqkKqK1j1Twpz7dX50XzjxxY1IPWcSPN3E07+yU7x0fgQCh8eqApVAa8u2BAaQq9rsLplVFcwxefat1eomyCNV1uiS5h6MULxk/1xu1wK2LWHUOiY1a+guITR+BAaQi8P7mpLpoJz7be3XDeB6vHumucCWp2POp/9GqfqsPv5EPr2klwqJISG0Ms1trujVM9vt1x1U1G9S6WLCALMrWfKn+Jz/Y9f/VHCrp0AC6GEAKxZ1QokwkPoGYG0XPMX2t2Ce1zLFQX9UXehpHjVvwugG78ar/q8rVDZYfFrnar/EKoiBs+7Fa6OX8MJoSH0hIBaUG4BqfDbLZc2BS4ANN4FjOKnXfTj1vjqj8/cBNXxIfTmYwu1kBD68B/NoJZJmxYaH4UWK5QU565BrmLdNZLyo4LqLsjyu1xKOIRe/7glFYSMr7spkh3CbyaQotSKp/mo4Cg/IkSNl/yR3T62kAMCjFoq2dU12CWA/FG+6ngZX1ehrgK6Aab4yK4CSoRSgRIeFI+tUBUQ93lKSAVMjYf8h9DinzcNoabG1Qp3nyeFhFCTUAK42067SPLvFoC6yVELmuK//RyqBkjPh9AzQvamiADvtofQEHpCIC13kZyrEFXB7pKvrmFqfm6B2PlVXyyoBKnP2wmLP9caQlWGxOdD6DVg9qZIrWCRv5/HQ+gwoS7g6tUZPX93AanxufiVK9QNiACg+as7BvlTC4bic/2FUGDEBZgKVL1pogIKoSF0uZkQ30CgCnPPcTS/qgj13KoqUo2H8mtXKK0ZlBABqs5P/ogQtQWr8anz/8RbfbFACqMKo/Fkp/lDKCBUrSAijOwh1NT43YQSgRSfOp4UTv7ITvGQ/fVrKCYo3t3SGhpCi7/gqzYUVxG0qaElQLVTgZI9ChX3CFFos0JJga4dFVHc0tUOtP2xRW1xRHgIHf4Pv0SIaw+hIZRq4GSngpMm+3z6/xGPGhAdG9Q1xgWMxtMuWM1fzW98DVUTCqEP/0p+CNUQiELFc6QK2H/fcrV61J+mYwkd/NWWrq6RFJ9qVxEqvylSA1CfJ0BCqNqDFsTUClYJJILIP6VX3VKp4FS7ilcU+rBXaKgAiWCbUHIQ+ywCIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73Zvf81zYw7mrrQcAAAAAElFTkSuQmCC"
            },
            {
                "id": "a1521cbe-699b-4fae-a38b-ddf180fa2bd9",
                "no": "05515-5080-0",
                "product_model_id": "07266859-77ea-40a6-9500-52a88e68a039",
                "name": "PIN  SNAP",
                "surface": "Zn-C",
                "qty_unit": 1,
                "user_id": "304535a2-b927-45f1-ba00-f20c546ac26a",
                "oem_id": "6befefef-5f0c-4c10-8b92-00a2d7fb2e8c",
                "wip_amount": null,
                "fg_amount": 700,
                "min_product": 500,
                "end_user_amount": null,
                "logistic_amount": null,
                "image": "{\"orgin_name\":\"Screenshot_2.png\",\"name\":\"38c37aab-9184-40e8-9cb7-b22ce299a933_Screenshot_2.png\",\"path\":\"Li91cGxvYWRzLzA1NTE1LTUwODAtMC8zOGMzN2FhYi05MTg0LTQwZTgtOWNiNy1iMjJjZTI5OWE5MzNfU2NyZWVuc2hvdF8yLnBuZw==\",\"mimetype\":\"image/png\",\"size\":8251}",
                "description": "Kubota (SKCa)",
                "sell_price": 1.19,
                "unit": null,
                "circle_time": null,
                "sku_user": null,
                "product_route_id": null,
                "product_size_id": null,
                "product_color_id": null,
                "image_name": "Screenshot_2.png",
                "imaget_path": "Li91cGxvYWRzLzA1NTE1LTUwODAtMC8zOGMzN2FhYi05MTg0LTQwZTgtOWNiNy1iMjJjZTI5OWE5MzNfU2NyZWVuc2hvdF8yLnBuZw==",
                "product_material_id": null,
                "cut_amount": null,
                "is_assy": false,
                "product_plants_item": null,
                "is_trade": false,
                "stp_type": null,
                "width": null,
                "height": null,
                "blade_head": null,
                "img_qr": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAB0CAYAAABUmhYnAAAAAXNSR0IArs4c6QAABNBJREFUeF7tnduO4zAMQ5v//+gOsG9xgRwQlJTEy3lVbEmkKF+ado7v9/v95G8bBI4Qug2X/xIJoXvxGUI34zOEhtDdENgsn6yhIXQzBDZLJwoNoZshsFk6UWgI3QyBzdKJQkPoZghslk4UGkI3Q2CzdGyFHscxCon78e0aL833uvzcz0Nfl/BSgCF00WMIrW1QVGDkLS0X3sB5XcFWt1y3wtYKpDWPAHfjIf+kGLJXz1+uUBfAEOq9sxdCQULVClILlhT+M19a7jVkIXTZZNAat8K5tmwXUBpfbSdFVec3rtAQeoY8hIoXAeoaFYXCxQJV4HRLqibsaflt33KJwG4FTxdsCDU7TBQqfhpDgNHFRRRKCJkH7+ldbgh9OaFE4LQ9a+iCgNpypwmj+EJoCD0hYDZM/+uEqkKqK1j1Twpz7dX50XzjxxY1IPWcSPN3E07+yU7x0fgQCh8eqApVAa8u2BAaQq9rsLplVFcwxefat1eomyCNV1uiS5h6MULxk/1xu1wK2LWHUOiY1a+guITR+BAaQi8P7mpLpoJz7be3XDeB6vHumucCWp2POp/9GqfqsPv5EPr2klwqJISG0Ms1trujVM9vt1x1U1G9S6WLCALMrWfKn+Jz/Y9f/VHCrp0AC6GEAKxZ1QokwkPoGYG0XPMX2t2Ce1zLFQX9UXehpHjVvwugG78ar/q8rVDZYfFrnar/EKoiBs+7Fa6OX8MJoSH0hIBaUG4BqfDbLZc2BS4ANN4FjOKnXfTj1vjqj8/cBNXxIfTmYwu1kBD68B/NoJZJmxYaH4UWK5QU565BrmLdNZLyo4LqLsjyu1xKOIRe/7glFYSMr7spkh3CbyaQotSKp/mo4Cg/IkSNl/yR3T62kAMCjFoq2dU12CWA/FG+6ngZX1ehrgK6Aab4yK4CSoRSgRIeFI+tUBUQ93lKSAVMjYf8h9DinzcNoabG1Qp3nyeFhFCTUAK42067SPLvFoC6yVELmuK//RyqBkjPh9AzQvamiADvtofQEHpCIC13kZyrEFXB7pKvrmFqfm6B2PlVXyyoBKnP2wmLP9caQlWGxOdD6DVg9qZIrWCRv5/HQ+gwoS7g6tUZPX93AanxufiVK9QNiACg+as7BvlTC4bic/2FUGDEBZgKVL1pogIKoSF0uZkQ30CgCnPPcTS/qgj13KoqUo2H8mtXKK0ZlBABqs5P/ogQtQWr8anz/8RbfbFACqMKo/Fkp/lDKCBUrSAijOwh1NT43YQSgRSfOp4UTv7ITvGQ/fVrKCYo3t3SGhpCi7/gqzYUVxG0qaElQLVTgZI9ChX3CFFos0JJga4dFVHc0tUOtP2xRW1xRHgIHf4Pv0SIaw+hIZRq4GSngpMm+3z6/xGPGhAdG9Q1xgWMxtMuWM1fzW98DVUTCqEP/0p+CNUQiELFc6QK2H/fcrV61J+mYwkd/NWWrq6RFJ9qVxEqvylSA1CfJ0BCqNqDFsTUClYJJILIP6VX3VKp4FS7ilcU+rBXaKgAiWCbUHIQ+ywCIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73ZvIbQd4lkHIXQW73Zvf81zYw7mrrQcAAAAAElFTkSuQmCC"
            }
        ]
        const item = {
            "tooling_receive": [
                {
                    "tooling_id": "c4071808-0388-4bf1-a59b-663f0892e6f8",
                    "receive_amount": 0,
                    "amount": "304",
                    "is_receive": false,
                    "po_id": "e56863d6-2a4b-46cf-8fce-cf7b5ad1ddad",
                    "tooling_name": "ไม่มี min order แต่มี min stock และมี supplier",
                    "tooling_supplier_id": "b42b13f3-a30c-4d40-85b7-b4f735c17352",
                    "price": "5005",
                    "receive_date": null,
                    "po_no": "PO660500007",
                    "lead_time": "2",
                    "buy_price": 50,
                    "name": "SUP06",
                    "contact_name": null,
                    "contact_tel": null,
                    "contact_fax": null
                }
            ],
            "po_no": "PO660500007",
            "po_id": "e56863d6-2a4b-46cf-8fce-cf7b5ad1ddad",
            "contact_name": null,
            "contact_tel": null,
            "contact_fax": null
        }
        if (mode === true) {
            Perchase_report(company, item, getComConfig());
        } else {
            Perchase_po_address(item2, company);
        }
    }

    const sliceTextToSendMail = (Content) => {
        let total = JSON.stringify(Content);
        const arrList = [];
        console.log('total.length', total.length);
        while (total.length > 0) {
            if (total.length >= 800000) {
                const cutStr = total.substring(0, 800000);
                arrList.push(cutStr);
                total = total.substring(800000); // Update the remaining portion of the string

            } else {
                arrList.push(total);
                total = ""; // The entire string has been added to arrList, so clear it
            }
        }
        return arrList;
    }

    const SendEmailAndFilePDF = async (dataReport, idx) => {
        Swal.fire({
            title: "คุณต้องการส่งเมลใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `ใช่`,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: `ไม่`,
        }).then((result) => {
            if (result.isConfirmed) {
                // Swal.fire("Sorry", "function นี้ยังไม่พร้อมใช้งาน", "warning");
                let pdfContentPO = null;
                let pdfContentAddress = null;
                let arrList1 = [];
                let arrList2 = [];
                const item = {
                    "tooling_receive": [
                        {
                            "tooling_id": "c4071808-0388-4bf1-a59b-663f0892e6f8",
                            "receive_amount": 0,
                            "amount": "304",
                            "is_receive": false,
                            "po_id": "e56863d6-2a4b-46cf-8fce-cf7b5ad1ddad",
                            "tooling_name": "ไม่มี min order แต่มี min stock และมี supplier",
                            "tooling_supplier_id": "b42b13f3-a30c-4d40-85b7-b4f735c17352",
                            "price": "5005",
                            "receive_date": null,
                            "po_no": "PO660500007",
                            "lead_time": "2",
                            "buy_price": 50,
                            "name": "SUP06",
                            "contact_name": null,
                            "contact_tel": null,
                            "contact_fax": null
                        }
                    ],
                    "po_no": "PO660500007",
                    "po_id": "e56863d6-2a4b-46cf-8fce-cf7b5ad1ddad",
                    "contact_name": null,
                    "contact_tel": null,
                    "contact_fax": null
                }
                const promiseA = new Promise(async (resolve, reject) => {
                    try {
                        pdfContentPO = await Perchase_report(company, dataReport, getComConfig(), true);
                        pdfContentAddress = await Perchase_po_address(dataReport, company, true);
                        arrList1 = sliceTextToSendMail(pdfContentPO);
                        arrList2 = sliceTextToSendMail(pdfContentAddress);
                        /* for (let item of dataGenarateReport.data) {
                            const checkInquiry = inquiryList.data_forsave.findIndex(el => item.tooling_receive[0].tooling_supplier_id === el.supplier_id);
                            if (checkInquiry >= 0) {
                                // console.log('pdfContent', pdfContent);
                                inquiryList.data_forsave[checkInquiry].pdfContent = pdfContent;
                            }
                        } */
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                });

                promiseA.then(() => {

                    // console.log('arrList', arrList);
                    let mailOptions = {
                        company_id: getUser().com,
                        oem_id: getOem(),
                        allDataInquiry: perchase_po[idx].perchaseTable.po_supplier_id,
                        perchase_id: perchase_po[idx].perchaseTable.po_id,
                        dataPDFList1: arrList1,
                        dataPDFList2: arrList2,
                    };
                    console.log('mailOptions', mailOptions);

                    const formData = new FormData();
                    // formData.append('dataPDFList', mailOptions.dataPDFList);
                    formData.append('allDataInquiry', mailOptions.allDataInquiry);
                    formData.append('perchase_id', mailOptions.perchase_id);
                    formData.append('oem_id', mailOptions.oem_id);
                    formData.append('company_id', mailOptions.company_id);
                    formData.append('company_name', getComConfig().com_name);
                    formData.append('user_id', getUser().emp_id);
                    for (let item = 0; item < arrList1.length; item++) {
                        formData.append('pdfContent' + item, JSON.stringify(arrList1[item]));
                    }
                    formData.append('dataPDFLength1', arrList1.length);
                    for (let item = 0; item < arrList2.length; item++) {
                        formData.append('pdfContentAds' + item, JSON.stringify(arrList2[item]));
                    }
                    formData.append('dataPDFLength2', arrList2.length);

                    // Send the request with FormData
                    axios({
                        method: 'post',
                        url: Configs.API_URL_incentive + "/api/incentivePo/sendEmailAndFilePDF",
                        headers: {
                            'Authorization': 'Bearer ' + getToken(),
                            'X-TTT': Configs.API_TTT,
                            'Content-Type': 'multipart/form-data',
                        },
                        data: formData,
                    })
                        .then(function (response) {
                            if (response) {
                                Swal.fire({
                                    icon: "success",
                                    text: "ส่งเมลสำเร็จ",
                                    showConfirmButton: false,
                                    timer: 1000
                                }).then(() => {
                                    FilterToolingPO();
                                    window.location.reload(); //.href = "/Human_Resource/intensive_TTT/manage_product_system";
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            Swal.fire('Error', 'เกิดข้อผิดพลาด: ' + error, 'error');
                        });
                })
                    .catch(function (error) {
                        console.log(error);
                        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                    });

            }
        })

    }

    function delDataToolingPO(e) {
        console.log("id", e.perchaseTable.po_id);
        Swal.fire({
            title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
            text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ตกลง",
            cancelButtonText: `ไม่`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios({
                    method: "get",
                    url:
                        Configs.API_URL_incentive + "/api/incentivePo/delPerchasePO/" +
                        e.perchaseTable.po_id,
                    headers: {
                        Authorization: "Bearer " + getToken(),
                        "Content-Type": "application/json",
                        "X-TTT": Configs.API_TTT,
                    },
                })
                    .then(function (response) {
                        Swal.fire({
                            icon: "success",
                            text: "เสร็จสิ้น",
                            showConfirmButton: false,
                            timer: 1000,
                        }).then(() => {
                            let arrList = [];
                            arrList = perchase_po.filter((po) => po.perchaseTable.po_id !== e.perchaseTable.po_id);
                            setPerchase_po(arrList);
                            setTest({ ...test, test: '' });
                            // window.location.href = "/Human_Resource/HRD/External_dev_train";
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                    });
            }
        });
    }


    const rowsData = [];
    for (let index = 0; index < perchase_po.length; index += 1) {
        const rowItem = {};

        rowItem["no"] = (<div style={{ textAlign: 'center' }}>{index + 1}</div>);

        rowItem["t_po_no"] = perchase_po[index].perchaseTable.po_no || "";

        rowItem["t_supplier_name"] = supplier_option.map((item) => { if (item.value === perchase_po[index].perchaseTable.po_supplier_id) { return item.label } });

        rowItem["t_price"] = (
            <>
                <div style={{ textAlign: 'end' }} >
                    {numberWithCommas(perchase_po[index].price || 0)}
                </div>
            </>
        )

        rowItem["t_amount"] = (
            <>
                <div style={{ textAlign: 'end' }} >
                    {numberWithCommas(perchase_po[index].amount || 0)}
                </div>
            </>
        )

        rowItem["t_report"] = (
            <>
                <div className="row">
                    <div className="col-12">
                        <button
                            type="button"
                            id={index}
                            disabled={perchase_po[index].statusAll.reportStatus}
                            onClick={generateReport.bind(
                                this,
                                perchase_po[index],
                                false,
                                index,
                            )}
                            class={"btn btn-block btn-primary "}
                        >
                            Report
                        </button>
                    </div>
                </div>
            </>
        );

        rowItem["t_email"] = (
            <>
                <div className="row">
                    <div className="col-12">
                        <button
                            type="button"
                            id={index}
                            disabled={perchase_po[index].statusAll.emailStatus}
                            onClick={generateReport.bind(
                                this,
                                perchase_po[index],
                                true,
                                index
                            )}
                            class={"btn btn-block btn-primary "}
                        >
                            Sent Email
                        </button>
                    </div>
                </div>
            </>
        );

        rowItem["t_email_date"] = perchase_po[index].perchaseTable.po_sent_email_date ? moment(perchase_po[index].perchaseTable.po_sent_email_date).format('DD/MM/yyyy :: HH:mm น.') : '-'

        /* rowItem["toolingAmount"] = (
            <div style={{ textAlign: 'end' }} >
                {numberWithCommas(perchase_po[index].amount || 0)}
            </div>
        );

        rowItem["sendEmail"] = (
            <>
                <div className="row">
                    <div className="col-12">
                        <button
                            type="button"
                            id={index}
                            /* disabled={disable}*/
        /* onClick={sendEmailService.bind(
            this,
            perchase_po[index]
        )} 
        class={"btn btn-block btn-primary "}
    >
        Send Email
    </button>
</div>
</div>
</>
); */

        rowItem["management"] = (
            <div className="row" style={{ flexWrap: "nowrap" }}>
                <div className="col-xl-3 col-md-3 col-xs-3 ">
                    <a
                        key={perchase_po[index].perchaseTable.po_id}
                        id="btn_delete"
                        className=" btn btn-xs "
                        onClick={() => {
                            window.location.href = "/Human_Resource/intensive_TTT/perchase_order/generate_po/read/" + perchase_po[index].perchaseTable.po_id;
                        }}
                    >
                        <i class="fas fa-eye"></i>
                    </a>
                </div>
                <div className="col-xl-3 col-md-3 col-xs-3 " style={{ visibility: perchase_po[index].statusAll.editBtn ? "hidden" : "visible" }}>
                    <a
                        key={perchase_po[index].perchaseTable.po_id}
                        id="btn_delete"
                        className=" btn btn-xs "
                        onClick={() => {
                            window.location.href = "/Human_Resource/intensive_TTT/perchase_order/generate_po/edit/" + perchase_po[index].perchaseTable.po_id;
                        }}
                    >
                        <i class="fas fa-pencil-alt"></i>
                    </a>
                </div>
                <div className="col-xl-3 col-md-3 col-xs-3 " style={{ visibility: perchase_po[index].statusAll.deleteBtn ? "hidden" : "visible" }}>
                    <a
                        key={perchase_po[index].perchaseTable.po_id}
                        id={perchase_po[index].perchaseTable.po_id}
                        className=" btn btn-xs "
                        onClick={delDataToolingPO.bind(
                            this,
                            perchase_po[index]
                        )}
                    >
                        <i class="fas fa-trash-alt"></i>
                    </a>
                </div>
            </div>
        );

        rowsData.push(rowItem);
    }

    const data = {
        columns: [
            {
                label: "No.",
                field: "no",
                // sort: "asc",
                width: 50,
            },
            {
                label: "PO No.",
                field: "t_po_no",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Supplier Name",
                field: "t_supplier_name",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Price",
                field: "t_price",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Amount",
                field: "t_amount",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Report",
                field: "t_report",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Email",
                field: "t_email",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Sent Email Date",
                field: "t_email_date",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Management",
                field: "management",
                // sort: "asc",
                width: 50,
            },
        ],

        rows: rowsData,
    };

    const generatePO = () => {
        window.location.href =
            "/Human_Resource/intensive_TTT/perchase_order/generate_po";
    };

    return (
        <div className="wrapper">
            {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>
                                    Purchase Order{"  "}
                                    <a target="_blank" href={getAcademy()} rel="noreferrer">
                                        <i class="fa fa-info-circle"></i>
                                    </a>
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Warehouse Management
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Purchase Order
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row" style={{ alignItems: 'center' }}>
                            <div className="col-12 col-md-3 col-xl-2">
                                <div className="form-group ">
                                    <input
                                        type="text"
                                        className="form-control"
                                        required="false"
                                        value={perchasePOFilter.t_po_no}
                                        onChange={(e) => {
                                            setPerchasePOFilter({
                                                ...perchasePOFilter,
                                                t_po_no: e.target.value,
                                            });
                                        }}
                                    />
                                    <label htmlFor="">PO No.</label>{" "}
                                </div>
                            </div>

                            <div
                                className="col-6 col-md-3 col-xl-2"
                                style={{ zIndex: "989" }}
                            >
                                <div className="form-group">
                                    <Select
                                        options={supplier_option}
                                        // ref={select_supplier}
                                        value={supplier_option.find(
                                            (item) => item.value === perchasePOFilter.t_supplier_id
                                        )}
                                        onChange={(e) => {
                                            //console.log(e);
                                            if (e !== null) {
                                                setPerchasePOFilter({
                                                    ...perchasePOFilter,
                                                    t_supplier_id: e.value || "",
                                                });
                                            }
                                        }}
                                    />
                                    <label htmlFor="">Supplier</label>
                                </div>
                            </div>
                            {/* <div
                                className="col-6 col-md-3 col-xl-2"
                                style={{ zIndex: "989" }}
                            >
                                <div className="form-group">
                                    <Select
                                        options={receiveStatus_option}
                                        // ref={select_supplier}
                                        value={receiveStatus_option.find(
                                            (item) => item.value === perchasePOFilter.t_recieve_status
                                        )}
                                        onChange={(e) => {
                                            //console.log(e);
                                            if (e !== null) {
                                                setPerchasePOFilter({
                                                    ...perchasePOFilter,
                                                    t_recieve_status: e.value || "",
                                                });
                                            }
                                        }}
                                    />
                                    <label htmlFor="">Receive Status</label>
                                </div>
                            </div> */}


                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div
                                className="col-12 col-md-4 col-xl-3"
                                style={{ "z-index": "987", display: 'flex', flexDirection: 'row', columnGap: '5px', flexDirection: 'row', alignItems: 'center' }}
                            >
                                <label style={{ whiteSpace: 'nowrap', fontSize: 'x-large' }} htmlFor="">Start Date</label>
                                <div className="form-group ">
                                    <DatePicker
                                        selected={perchasePOFilter.t_start_date}
                                        dateFormat={"dd-MM-yyyy"}
                                        popperPlacement="bottom"
                                        popperModifiers={{
                                            flip: {
                                                behavior: ["bottom"] // don't allow it to flip to be above
                                            },
                                            preventOverflow: {
                                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                            },
                                            hide: {
                                                enabled: false // turn off since needs preventOverflow to be enabled
                                            }
                                        }}
                                        onChange={async (date) => {
                                            setPerchasePOFilter({
                                                ...perchasePOFilter,
                                                t_start_date: date,
                                            })
                                        }}
                                        // selectsStart
                                        startDate={perchasePOFilter.t_start_date}
                                        maxDate={perchasePOFilter.t_end_date}
                                        customInput={<Custom_Input_date />}
                                    />
                                </div>
                            </div>

                            <div
                                className="col-12 col-md-4 col-xl-3"
                                style={{ "z-index": "99", display: 'flex', flexDirection: 'row', columnGap: '5px', flexDirection: 'row', alignItems: 'center' }}
                            >
                                <label style={{ whiteSpace: 'nowrap', fontSize: 'x-large' }} htmlFor="">Date End</label>
                                <div className="form-group ">
                                    <DatePicker
                                        selected={perchasePOFilter.t_end_date}
                                        dateFormat={"dd-MM-yyyy"}
                                        popperPlacement="bottom"
                                        popperModifiers={{
                                            flip: {
                                                behavior: ["bottom"] // don't allow it to flip to be above
                                            },
                                            preventOverflow: {
                                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                            },
                                            hide: {
                                                enabled: false // turn off since needs preventOverflow to be enabled
                                            }
                                        }}
                                        onChange={async (date) => {
                                            setPerchasePOFilter({
                                                ...perchasePOFilter,
                                                t_end_date: date,
                                            })
                                        }}
                                        // selectsEnd
                                        startDate={perchasePOFilter.t_start_date}

                                        minDate={perchasePOFilter.t_start_date}
                                        customInput={<Custom_Input_date />}
                                    ></DatePicker>

                                </div>
                            </div>

                            {/* <div className="col-4 col-md-3 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                       onClick={() => QcTagGenerate()} 
                    >
                       Report Tag
                    </button>
                  </div>
                </div> */}
                        </div>
                        <div className='row' >
                            <div className="col-6 col-md-3 col-xl-2">
                                <div className="form-group ">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-info  "
                                        onClick={() => {
                                            // SendEmailAndFilePDF();
                                            FilterToolingPO();
                                        }}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>

                            <div className="col-6 col-md-3 col-xl-2">
                                <div className="form-group ">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-info"
                                        onClick={() => Clearfilter()}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-xl-2">
                                <div className="form-group ">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-primary"
                                        onClick={() => generatePO()}
                                    >
                                        Generate PO
                                    </button>
                                </div>
                            </div>
                            {/* <div className="col-12 col-md-3 col-xl-2">
                                <div className="form-group ">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-primary"
                                        onClick={() => testReport(false)}
                                    >
                                        testReportAddress
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-xl-2">
                                <div className="form-group ">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-primary"
                                        /* onClick={() => testReport(true)} *
                                        onClick={generateReport.bind(
                                            this,
                                            perchase_po[0],
                                            true
                                        )}
                                    >
                                        testReport
                                    </button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Tooling PO</h3>
                            <div className="card-tools"></div>
                        </div>

                        <div
                            className="card-body table-responsive "
                            style={{ whiteSpace: "nowrap" }}
                        >
                            <MDBDataTable
                                sortable={false}
                                className="table table-head-fixed"
                                striped
                                bordered
                                hover
                                fixedHeader
                                data={data}
                                disableRetreatAfterSorting={MDBT}
                            />
                        </div>
                        {/* /.card-body */}
                        <div className="card-footer"></div>
                        {/* /.card-footer*/}
                    </div>
                    {/* /.card */}
                </section>
                {/* /.content */}
            </div>
            {/*   <Footter/> */}
        </div>
    )
}

export default Perchase_order