import React, { useState, useEffect } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../config";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Tooltip } from "antd";
import moment from "moment";

function HrLog() {
  const [filterteam, setFilterTeam] = useState({
    file_name: "",
    cat_name: "",
    rc_id: "",
    year: "",
    company_id: getUser().com,
    oem_id: getOem(),
  });
  const [filCategory, setFilterCategory] = useState([]);
  const [filterYear, setFilterYear] = useState([]);
  const [filterLog, setFilterLog] = useState([]);
  const uniqueCategories = Array.from(
    new Set(filterLog.map((el) => el.rml_id))
  ).map((id) => {
    return filterLog.find((el) => el.rml_id === id);
  });

  const GetAllCatgory = async () => {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterHrLog",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filterteam, // Sending filterteam as the request body
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          setFilterCategory(response.data.data);
        } else {
          setFilterCategory([]);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const FilterLog = async () => {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterHrLog",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filterteam, // Sending filterteam as the request body
    })
      .then(async function (response) {
        let dropdownYear = [
          ...new Set(
            response.data.data.map((el) =>
              moment(el.rl_date_filed).format("YYYY")
            )
          ),
        ]
          .map((year) => ({ value: year, label: Number(year) + 543 }))
          .sort((a, b) => a.value - b.value);
        setFilterYear(dropdownYear);
        setFilterLog(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState({ path: null });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  function deldata(id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios({
          method: "get",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/companyRegulations/delHrLogById?id=" +
            id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data.status === "success") {
              Swal.fire({
                icon: "success",
                text: "เสร็จสิ้น",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                GetAllCatgory();
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "บางอย่างผิดพลาด: " + error, "error");
          });
      }
    });
  }

  async function exportExcel(data) {
    let temp = { data: data };
    try {
      const response = await axios({
        method: "post",
        url:
          Configs.API_URL_hrMagenatement +
          "/api/companyRegulations/exportExcelLog",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: temp,
      });

      const _data = response.data.data;
      Swal.close();
      const excelDataUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${_data}`;
      const link = document.createElement("a");
      link.href = excelDataUrl;
      link.download = `log Employee Incomplete ${moment().format(
        "DD-MM-YYYY"
      )}.xlsx`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("Error:", error);
      Swal.fire("Error", "บางอย่างผิดพลาด: " + error, "error");
    }
  }

  function clearFilter() {
    setFilterTeam({
      file_name: "",
      cat_name: "",
      rc_id: "",
      year: "",
      company_id: getUser().com,
      oem_id: getOem(),
    });
    filterteam.file_name = "";
    filterteam.cat_name = "";
    filterteam.rc_id = "";
    filterteam.year = "";
    filterteam.company_id = getUser().com;
    filterteam.oem_id = getOem();
    // Swal.fire({
    //   title: "กำลังดึงข้อมูล",
    //   text: "Loading",
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });
    GetAllCatgory();
    FilterLog();
  }

  function addpage(params) {
    window.location.href =
      "/Human_Resource/company_regulations/Manage_message_types/add";
  }

  function generateDataTable(cat, index) {
    // console.log('cat: ', cat);

    const element = {};
    element["no"] = (
      <div style={{ textAlign: "center", minWidth: "30px", maxWidth: "30px" }}>
        {index + 1}
      </div>
    );

    element["name"] = (
      <div style={{ minWidth: "200px" }}>
        {`${cat.emp_name1 || "ไม่ทราบชื่อ"}${
          cat.nickname ? ` (${cat.nickname})` : ""
        }`}
      </div>
    );

    element["name2"] = `${cat.emp_name1 || "ไม่ทราบชื่อ"}${
      cat.nickname ? ` (${cat.nickname})` : ""
    }`;

    element["image"] = (
      <img
        id="img"
        alt="..."
        className="img-fluid rounded shadow border-0"
        src={
          cat.rl_path_file !== "" &&
          cat.rl_path_file !== null &&
          cat.rl_path_file !== undefined
            ? Configs.API_URL_IMG + cat.rl_path_file
            : userdefault_img.imgs
        }
        style={{
          width: "60px",
          height: "60px",
          position: "relative",
          objectFit: "cover",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          cursor: "pointer",
        }}
        onClick={() => {
          if (cat.rl_path_file) {
            setImage({ path: cat.rl_path_file });
            image.path = cat.rl_path_file;
            handleOpenModal();
          }
        }}
      />
    );

    element["category1"] = (
      <div style={{ minWidth: "200px", display: "flex" }}>
        {cat.rml_name || "ไม่พบ"}
      </div>
    );
    element["category1_1"] = cat.rml_name;
    element["category2"] = (
      <div
        style={{
          textOverflow: "ellipsis",
          minWidth: "200px",
          maxWidth: "200px",
          overflow: "hidden",
          display: "flex",
          justifyContent: "start",
        }}
        // title={cat.rl_remark || "ไม่พบ"}
      >
        <Tooltip title={`${cat.rl_remark || "ไม่พบ"}`} placement="top">
          <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {cat.rl_remark || "ไม่พบ"}
          </span>
        </Tooltip>
      </div>
    );
    element["category2_2"] = cat.rl_remark;
    element["category3"] = (
      <div style={{ minWidth: "150px", display: "flex" }}>
        {cat.emp_name2 || "ไม่พบ"}
      </div>
    );
    element["category3_3"] = cat.emp_name2;

    const formattedDate = cat.rl_date_filed
      ? new Date(cat.rl_date_filed)
          .toLocaleDateString("th-TH", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-")
      : "ไม่พบ";

    element["date_find"] = (
      <div
        style={{
          minWidth: "100px",
          maxWidth: "100px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {formattedDate}
      </div>
    );

    element["date_find2"] = formattedDate;

    const formatTime = (dateTimeString) => {
      const date = new Date(dateTimeString);
      return date.toLocaleTimeString("th-TH", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    };

    element["start_time"] = (
      <div
        style={{
          minWidth: "70px",
          maxWidth: "70px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {cat.rl_start_filed ? formatTime(cat.rl_start_filed) : "ไม่พบ"}
      </div>
    );

    element["start_time2"] = cat.rl_start_filed
      ? formatTime(cat.rl_start_filed)
      : "ไม่พบ";

    element["end_time"] = (
      <div
        style={{
          minWidth: "70px",
          maxWidth: "70px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {cat.rl_end_filed ? formatTime(cat.rl_end_filed) : "ไม่พบ"}
      </div>
    );

    element["end_time2"] = cat.rl_end_filed
      ? formatTime(cat.rl_end_filed)
      : "ไม่พบ";

    element["status"] = (
      <div style={{ textAlign: "center" }}>
        <div
          className={`hr-log-status ${
            cat.rl_is_close
              ? "closed"
              : cat.rl_is_conside
              ? "pending"
              : cat.rl_is_giving
              ? "approved"
              : "pending"
          }`}
        >
          {cat.rl_is_close
            ? "ปิด Case"
            : cat.rl_is_conside
            ? "รอพิจารณา"
            : cat.rl_is_giving
            ? "รอมอบ Point"
            : "รอพิจารณา"}
        </div>
      </div>
    );

    element["status2"] = cat.rl_is_close
      ? "ปิด Case"
      : cat.rl_is_conside
      ? "รอพิจารณา"
      : cat.rl_is_giving
      ? "รอมอบ Point"
      : "รอพิจารณา";

    element["mgt"] = (
      <div
        className="row"
        style={{ flexWrap: "nowrap", justifyContent: "center" }}
      >
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            href={
              "/Human_Resource/company_regulations/hr_Log/view/" + cat.rl_id
            }
            id="btn_read"
            key={cat.rl_id + "view"}
            className="btn btn-xs "
          >
            <i class="fas fa-eye"></i>
            {"   "}
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            href={
              "/Human_Resource/company_regulations/hr_Log/edit/" + cat.rl_id
            }
            id="btn_edit"
            key={cat.rl_id + "edit"}
            className=" btn btn-xs "
          >
            {"   "}
            <i class="fas fa-pencil-alt"></i>
            {"   "}
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={cat.rl_id + "del"}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={deldata.bind(this, cat.rl_id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    return element;
  }

  const dataCat = [];
  for (let index = 0; index < filCategory.length; index++) {
    const cat = filCategory[index];
    dataCat.push(generateDataTable(cat, index));
  }

  const data = {
    columns: [
      {
        label: <div style={{ textAlign: "center" }}>No</div>,
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: <div style={{ textAlign: "center" }}>Name</div>,
        field: "name",
        // sort: "asc",
        // width: 200
      },
      {
        label: <div style={{ textAlign: "center" }}>หลักฐาน</div>,
        field: "image",
        // sort: "asc",
        // width: 100,
      },
      {
        label: <div style={{ textAlign: "center" }}>ประเภท</div>,
        field: "category1",
        // sort: "asc",
        // width: 100,
      },
      {
        label: <div style={{ textAlign: "center" }}>รายละเอียด</div>,
        field: "category2",
        // sort: "asc",
        // width: 200,
      },
      {
        label: <div style={{ textAlign: "center" }}>วันที่พบ</div>,
        field: "date_find",
        // sort: "asc",
        // width: 100,
      },
      {
        label: <div style={{ textAlign: "center" }}>ตั้งแต่เวลา</div>,
        field: "start_time",
        // sort: "asc",
        // width: 100,
      },
      {
        label: <div style={{ textAlign: "center" }}>ถึงเวลา</div>,
        field: "end_time",
        // sort: "asc",
        // width: 100,
      },
      {
        label: <div style={{ textAlign: "center" }}>ผู้ให้ข้อมูล</div>,
        field: "category3",
        // sort: "asc",
        // width: 150,
      },
      {
        label: <div style={{ textAlign: "center" }}>สถานะ</div>,
        field: "status",
        // sort: "asc",
        // width: 100,
      },
      {
        label: <div style={{ textAlign: "center" }}>MGT</div>,
        field: "mgt",
        // sort: "asc",
        // width: 100,
      },
    ],
    rows: dataCat,
  };
  useEffect(() => {
    GetAllCatgory();
    FilterLog();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Log ความไม่เรียบร้อย{"  "}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Task</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={filterteam.cat_name}
                    onChange={(e) => {
                      setFilterTeam({
                        ...filterteam,
                        cat_name: e.target.value, // Update cat_name when typing
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อพนักงาน</label>
                </div>
              </div>

              <div
                className="col-md-2 col-sm-12 col-lg-2"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <select
                    className="form-control custom-select select2"
                    type="text"
                    required
                    id="drop_emptype"
                    value={filterteam.rc_id}
                    onChange={(e) => {
                      setFilterTeam({
                        ...filterteam,
                        rc_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected></option>
                    {uniqueCategories.map((el) => {
                      return <option value={el.rml_id}>{el.rml_name}</option>;
                    })}
                  </select>
                  <label htmlFor="" style={{ marginTop: "-1px" }}>
                    ประเภทความไม่เรียบร้อย
                  </label>
                </div>
              </div>

              <div
                className="col-md-2 col-sm-12 col-lg-2"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  {/* <label htmlFor="" style={{ marginBottom: "2px" }}>
                    Year
                  </label> */}
                  <select
                    className="form-control custom-select select2"
                    type="text"
                    required
                    id="drop_emptype"
                    value={filterteam.year}
                    onChange={(e) => {
                      setFilterTeam({
                        ...filterteam,
                        year: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    {filterYear.map((el) => {
                      return <option value={el.value}>{el.label}</option>;
                    })}
                  </select>
                  <label htmlFor="" style={{ marginTop: "-1px" }}>
                    Year
                  </label>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      // name_search();
                      GetAllCatgory();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/Human_Resource/company_regulations/hr_Log/add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      exportExcel(filCategory);
                    }}
                  >
                    Export Excel Log
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Log ความไม่เรียบร้อย</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <table
                className="card-body table-responsive"
                style={{ padding: 0 }}
              >
                <MDBDataTable
                  disableRetreatAfterSorting={true}
                  sortable={false}
                  // className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </table>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* Modal */}
        <Modal
          centered
          isOpen={showModal}
          toggle={handleCloseModal}
          // style={{ maxWidth: "50%", minWidth: "50%", width: "50%" }}
        >
          <ModalHeader
            style={{ border: "none" }}
            toggle={handleCloseModal}
          ></ModalHeader>
          <ModalBody>
            <div>
              <img
                id="img"
                alt="..."
                className="img-fluid rounded shadow border-0"
                src={
                  image.path !== "" &&
                  image.path !== null &&
                  image.path !== undefined
                    ? Configs.API_URL_IMG + image.path
                    : userdefault_img.imgs
                }
                style={{
                  width: "500px",
                  height: "500px",
                  position: "relative",
                  objectFit: "cover",
                  display: "block",
                  "margin-left": "auto",
                  "margin-right": "auto",
                }}
              />
            </div>
          </ModalBody>
          {/* <ModalFooter>
                            <Button color="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </ModalFooter> */}
        </Modal>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default HrLog;
