import React, { Component, useEffect, useState, useRef } from "react";
/* import "../assets/css/Oem.css"; */
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
/* import Configs from "../config"; */

function TableLEK(params) {
  const headTable = params.headTable || [];
  const dataTable = params.dataTable || [];
/*   console.log(params.dataTable) */
  let allPage = params.countPage || 1;
  const [allPage1,setAll_page1] = useState(params.countPage || 1);
  let countData = params.countData || 0;

  const rowsData = [];
  const columns = [];

  const [show_entries, setshow_entries] = useState({
    entries:10,
  });

  const [test, settest] = useState({
    test:'',
  });

  
  const [this_page, setthis_page] = useState({
    page: params.currentPage || 1,
  });

  const [BN, setBN] = useState(0);
  const [FN, setFN] = useState(0);

  const [countPage, setCountPage] = useState(true);
  const [all_page, setall_page] = useState([]);

  useEffect(() => {
    if (dataTable.length > 0) {
   /*    console.log("HERERERERE",params.countPage) */
      setAll_page1(params.countPage);
     if (params.currentPage) {
      setthis_page({ ...this_page, page: params.currentPage });
      this_page.page = params.currentPage;
     }
    
      const page_arr = [];
      let page = 1;
      let FN_ = (this_page.page * show_entries.entries) > parseInt(countData) ? countData : (this_page.page * show_entries.entries);
      let BN_ = (this_page.page * show_entries.entries) - show_entries.entries+1;
      if(params.isSearch){
        setthis_page({...this_page,page:1});
        FN_ = (1 * show_entries.entries) > parseInt(countData) ? countData : (1 * show_entries.entries);
       BN_ = (1 * show_entries.entries) - show_entries.entries+1;
      }
 /*      if(params.countPage * show_entries.entries < this_page.page * show_entries.entries){
        setthis_page({...this_page,page:1});
        FN_ = (1 * show_entries.entries) > parseInt(countData) ? countData : (this_page.page * show_entries.entries);
       BN_ = (1 * show_entries.entries) - show_entries.entries+1;
      } */
      for(let i = 0; i < allPage;i++){
      
          let temp ={
            page:page,
          }
          page_arr.push(temp);
          page++;
        
      } 
      setshow_entries({...show_entries,entries:params.entries || 10});
           setall_page(page_arr);
           setBN(BN_);
           setFN(FN_);
          
      setCountPage(false);
    /*   console.log("allpagedata",countPage);
      console.log("countpage",params.pageControl); */
     }


}, [params.dataTable, params?.currentPage]);

 

/*  console.log(all_page); */
  function setPage(page) {
   /*  console.log("this page : ", page); */
    setthis_page({ ...this_page, page: page });
  }


    

 

 
  async function setEntries(entries) {
  
     setshow_entries({ ...show_entries, entries: entries });
     setthis_page({...this_page,page:1});
     params.callFnc(1,entries);
    
  /*   const page_arr = [];
     let page = 1;

     for(let i = 0; i < allPage;i++){
     
         let temp ={
           page:page,
         }
         page_arr.push(temp);
         page++;
       
     } 
          
 
     let BN_ = 1;
     let FN_ = (BN_ + parseInt(entries)-1) > parseInt(countData) ? countData : (BN_ + parseInt(entries)-1);
     setall_page(page_arr);
     setBN(BN_);
     setFN(FN_);
     settest({...test,test:''});
     test.test = ''; */
  }




  function previous_page() {
    let page_ = this_page.page - 1;
    if (page_ >= 1) {
      setthis_page({ ...this_page, page: page_ });
      params.callFnc(page_,show_entries.entries);
      let FN_ = (page_ * show_entries.entries) > parseInt(countData) ? countData : (page_ * show_entries.entries);
      let BN_ = (page_ * show_entries.entries) - show_entries.entries +1;
      setBN(BN_);
      setFN(FN_);
    }
  }

  function next_page() {
    let page_ = this_page.page + 1;
    if (page_ <= all_page.length) {
      setthis_page({ ...this_page, page: page_ });
      params.callFnc(page_,show_entries.entries);

      let FN_ = (page_ * show_entries.entries) > parseInt(countData) ? countData : (page_ * show_entries.entries);
      let BN_ = (page_ * show_entries.entries) - show_entries.entries +1;
      setBN(BN_);
      setFN(FN_);
    }
  }

   
  return (
    <div class="mt-2">
      <div class="col-12">
        <div class="form-group">
          <select
            style = {{ width: 'fit-content'}}
            className="form-control custom-select select2"
            type="text"
            required
            value={show_entries.entries || 10}
            onChange={(e) => setEntries(e.target.value)}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={250}>250</option>
          </select>
          <label htmlFor="">Show entries</label>
        </div>
      </div>

      <div className="table-responsive"  style = {{whiteSpace: "nowrap", /* maxHeight:'80vh' */}}>
      <MDBTable
          className="table"
         
          striped
          bordered
          hover
          fixedHeader
        >
          <MDBTableHead>
            <tr>
            <th>{params.uni == true ? 'ลำดับ' : 'No'}</th>
            {headTable.map((head,index)=>{
                return (<>
                  <th>{head.label}</th>
                </>);
              })}

          
            </tr>
          </MDBTableHead>
          <MDBTableBody>
          {dataTable.map((data_,index_)=>{

return(
  
  <>
  <tr>
  <td>{BN+index_}</td>
  {headTable.map((head,index)=>{
  

    return (<>
      <td>{data_[head.field]}</td>
    </>);
    
  })}
  </tr>
</>
);


})}

            {/* {rowsData.map((el, index) => {
              //console.log(rowsData);
              if (
                (this_page.page - 1) * show_entries.entries <= index && index < this_page.page * show_entries.entries
              ) {
                if (index < this_page.page * show_entries.entries) {
                  FN = index + 1;
                  if (FN == rowsData.length) {
                    BN = (this_page.page - 1) * show_entries.entries + 1;
                  } else {
                    BN = FN - show_entries.entries + 1;
                  }
                }

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{el.Part_No}</td>
                    <td>{el.Part_Name}</td>
                    <td>{el.WIP}</td>
                    <td>{el.FG}</td>
                    <td>{el.In_Hand}</td>
                    <td>{el.N1}</td>
                    <td>{el.N2}</td>
                    <td>{el.N3}</td>
                    <td>{el.N4}</td>
                    <td>{el.steel_bar}</td>
                    <td>{el.nms}</td>
              
                  </tr>
                );
              }
            })} */}
          </MDBTableBody>
        </MDBTable>
       {/*    <table className="table table-striped">
          <thead>
            <tr>
              {headTable.map((head,index)=>{
                return (<>
                  <th>{head}</th>
                </>);
              })}
             
            </tr>
          </thead>
          <tbody>
            {dataTable.map((data_,index_)=>{

              return(
                
                <>
                <tr>
                {headTable.map((head,index)=>{
                
             
                  return (<>
                    <td>{data_[head]}</td>
                  </>);
                  
                })}
                </tr>
              </>
              );


            })}
         
           
          </tbody>
          
            
        </table> */}
      </div>
     {/*  <h1>{all_page.length}</h1> */}
     <br/>
      <div className="row">
        <div className="col-12  col-xl-5">
          Showing {dataTable.length > 0 ? BN : 0} to {dataTable.length > 0 ? BN + dataTable.length - 1 : dataTable.length /* + FN */} of {countData} entries
        </div>
        <div className="col-0.5">
          <button
            type="button"
            className="btn btn-block btn-outline-primary  border"
            style = {{borderRadius:'25px',minWidth:'65px'}}
            onClick={previous_page}
          >
            Previous
          </button>
        </div>


        {all_page.length > 0 ? (<>
          {all_page.map((e, index) => {
          if (
            e.page + 1 == this_page.page ||
            e.page + 2 == this_page.page ||
            this_page.page == e.page - 1 ||
            this_page.page == e.page - 2 ||
            e.page == (all_page.length+1) - all_page.length ||
            e.page == all_page.length ||
            this_page.page == e.page
          ) {
            if (this_page.page == e.page) {
              return (
                <>
                  <div className="col-0.5">
                    <button
                      type="button"
                      className="btn btn-block btn-primary border"
                      style = {{borderRadius:'25px',minWidth:'40px'}}
                      key={index}
                      onClick={setPage.bind(this, e.page)}
                    >
                      {index + 1}
                    </button>
                  </div>
                </>
              );
            } else {
              return (
                <>
               
                 {e.page  == all_page.length && all_page.length - this_page.page >2  /* && all_page.length - this_page.page < 3 */   ? (<>
                    <div className="col-0.5">
                    <button
                      type="button"
                      disabled
                      className="btn btn-block btn-outline-primary border"
                      //key={index}
                      style = {{borderRadius:'25px',minWidth:'40px'}} 
                    >
                      ...
                    </button>
                  </div>
                  </>) : (<></>)}
                  <div className="col-0.5">
                    <button
                      type="button"
                      className="btn btn-block btn-outline-primary border"
                      //key={index}
                      style = {{borderRadius:'25px',minWidth:'40px'}}
                      onClick={()=>{
                        setthis_page({...this_page,page:e.page});
                        params.callFnc(e.page,show_entries.entries);
                        let FN_ = (e.page * show_entries.entries) > parseInt(countData) ? countData : (e.page * show_entries.entries);
                        let BN_ = (e.page * show_entries.entries) - show_entries.entries +1;
                        setBN(BN_);
                        setFN(FN_);
                      }}
                      
                    >
                      {index + 1}
                    </button>
                  </div>

                  {e.page - e.page +1 == (all_page.length+1) - all_page.length && this_page.page > 3 && this_page.page - (e.page) == this_page.page-1 ? (<>
                    <div className="col-0.5">
                    <button
                      type="button"
                      disabled
                      className="btn btn-block btn-outline-primary border"
                      //key={index}
                      style = {{borderRadius:'25px',minWidth:'40px'}} 
                    >
                      ...
                    </button>
                  </div>
                  </>) : (<></>)}
                
                </>
              );
            }
          }
        }) }
        
        </>):(<></>)}
    
        <div className="col-0.5">
          <button
            type="button"
            className="btn btn-block btn-outline-primary  border"
            // key={index}
            style = {{borderRadius:'25px',minWidth:'65px'}}
            onClick={next_page}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default TableLEK;
